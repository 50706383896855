import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import utils from "utils";

// img
import IconArrowPrevDouble from "resources/image/icon/icon_arrow_prev_double.svg";
import IconArrowPrev from "resources/image/icon/icon_arrow_prev.svg";
import IconArrowNextDouble from "resources/image/icon/icon_arrow_next_double.svg";
import IconArrowNext from "resources/image/icon/icon_arrow_next.svg";

function Pagination({ list, page, maxPage, disableScrollTop }) {
  const navigate = useNavigate();
  
  const onClickPage = value => {
    navigate(utils.etc.getQueryParamUrl({
      page: value,
      disableScrollTop: disableScrollTop,
    }));
  }

  const onClickPrevDubble = () => {
    if (list[0] - 1 < 1) return;
    navigate(utils.etc.getQueryParamUrl({
      page: list[0] - 1,
      disableScrollTop: disableScrollTop,
    }));
  };
  const onClickPrev = () => {
    if (page == 1) return;
    navigate(utils.etc.getQueryParamUrl({
      page: page - 1,
      disableScrollTop: disableScrollTop,
    }));
  };
  const onClickNext = () => {
    if (page == maxPage) return;
    navigate(utils.etc.getQueryParamUrl({
      page: page + 1,
      disableScrollTop: disableScrollTop,
    }));
  };
  const onClickNextDubble = () => {
    if (list[list.length - 1] + 1 > maxPage) return;
    navigate(utils.etc.getQueryParamUrl({
      page: list[list.length - 1] + 1,
      disableScrollTop: disableScrollTop,
    }));
  };

  return (
    <Styles.Container>
      <button
        type="button"
        className="iconArrow"
        onClick={e => onClickPrevDubble(e)}
      >
        <img src={IconArrowPrevDouble} alt="IconArrowPrevDouble" />
      </button>

      <button
        type="button"
        className="iconArrow"
        onClick={e => onClickPrev(e)}
      >
        <img src={IconArrowPrev} alt="IconArrowPrev" />
      </button>

      <div className="paginationList">
        {(list ? list : []).map((item, index) => {
          return (
            <button
              type="button"
              value={item}
              className={page == item ? "on" : ""}
              onClick={() => onClickPage(item)}
              key={index}
            >
              {item}
            </button>
          );
        })}
      </div>

      <button
        type="button"
        className="iconArrow"
        onClick={e => onClickNext(e)}
      >
        <img src={IconArrowNext} alt="IconArrowNext" />
      </button>

      <button
        type="button"
        className="iconArrow"
        onClick={e => onClickNextDubble(e)}
      >
        <img src={IconArrowNextDouble} alt="IconArrowNextDouble" />
      </button>
    </Styles.Container>
  );
}

export { Pagination };
export default Pagination;