import styled from "styled-components";

const Container = styled.main`
  min-height: calc(100vh - 393px);
  min-height: calc(100dvh - 393px);
  padding: 50px 0 130px;

  & .titleWrap {
    padding-bottom: 20px;
    border-bottom: 3px solid var(--black);

    & .type {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 102px;
      height: 41px;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      background-color: var(--blue01);
      border-radius: 5px;
    }

    & .title {
      margin-bottom: 20px;
      font-size: 42px;
    }

    & .date {
      font-weight: 400;
      color: var(--gray02);
    }
  }

  & .fileListWrap {
    margin-top: 15px;

    & .fileList {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      a {
        display: flex;
        align-items: flex-start;
        gap: 10px;
      }

      & .icon {
        width: 24px;
        height: 24px;
      }

      & .fileName {
        max-width: calc(100% - 34px);
        font-size: 16px;
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }

  & .editorWrap {
    margin-bottom: 50px;
    padding: 50px 150px;
    border-bottom: 1px solid var(--gray02);
  }

  & .listButton {
    width: fit-content;
    margin: 0 auto;
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (393 / 1600)));
    min-height: calc(100dvh - calc(100vw * (393 / 1600)));
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (130 / 1600));

    & .titleWrap {
      padding-bottom: calc(100vw * (20 / 1600));
      border-bottom: calc(100vw * (3 / 1600)) solid var(--black);

      & .type {
        width: calc(100vw * (102 / 1600));
        height: calc(100vw * (41 / 1600));
        margin-bottom: calc(100vw * (20 / 1600));
        font-size: calc(100vw * (18 / 1600));
        border-radius: calc(100vw * (5 / 1600));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 1600));
        font-size: calc(100vw * (42 / 1600));
      }
    }

    & .fileListWrap {
      margin-top: calc(100vw * (15 / 1600));

      & .fileList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 1600));
        }

        a {
          gap: calc(100vw * (10 / 1600));
        }

        & .icon {
          width: calc(100vw * (24 / 1600));
          height: calc(100vw * (24 / 1600));
        }

        & .fileName {
          max-width: calc(100% - calc(100vw * (34 / 1600)));
          font-size: calc(100vw * (16 / 1600));
          text-underline-offset: calc(100vw * (3 / 1600));
        }
      }
    }

    & .editorWrap {
      margin-bottom: calc(100vw * (50 / 1600));
      padding: calc(100vw * (50 / 1600)) calc(100vw * (150 / 1600));
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (394 / 375)));
    min-height: calc(100dvh - calc(100vw * (394 / 375)));
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (80 / 375));

    & .titleWrap {
      padding-bottom: calc(100vw * (15 / 375));
      border-bottom: calc(100vw * (3 / 375)) solid var(--black);

      & .type {
        width: calc(100vw * (95 / 375));
        height: calc(100vw * (39 / 375));
        margin-bottom: calc(100vw * (15 / 375));
        font-size: calc(100vw * (16 / 375));
        border-radius: calc(100vw * (5 / 375));
      }

      & .title {
        margin-bottom: calc(100vw * (15 / 375));
        font-size: calc(100vw * (24 / 375));
        line-height: 1.25;
      }
    }

    & .fileListWrap {
      margin-top: calc(100vw * (15 / 375));

      & .fileList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 375));
        }

        a {
          gap: calc(100vw * (10 / 375));
        }

        & .icon {
          width: calc(100vw * (24 / 375));
          height: calc(100vw * (24 / 375));
        }

        & .fileName {
          max-width: calc(100% - calc(100vw * (34 / 375)));
          font-size: calc(100vw * (16 / 375));
          text-underline-offset: calc(100vw * (3 / 375));
        }
      }
    }

    & .editorWrap {
      margin-bottom: calc(100vw * (30 / 375));
      padding: calc(100vw * (30 / 375)) calc(100vw * (20 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
