import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";

// img
import ImgTissue from "resources/image/service/img_validity_tissue01.jpg";
import ImgTissueMb from "resources/image/service/img_validity_tissue01_mb.jpg";

function Tissue() {
  return (
    <Styles.Container>
      <div className="inner">
        <p className="title">질환별 분자영상 서비스</p>

        <ScrollContainer>
          <div className="img">
            <img className="pcOnly" src={ImgTissue} alt="ImgTissue" />
            <img className="mbOnly" src={ImgTissueMb} alt="ImgTissue" />
          </div>
        </ScrollContainer>
      </div>
    </Styles.Container>
  );
}

export default Tissue;
