import Styles from "./styles";

function BasicCheckbox({ id, labelText, checked, onChange }) {
  return (
    <>
      <Styles.Input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />

      <Styles.Label htmlFor={id}>
        <span className="checkbox"></span>

        <span className="labelText">{labelText && labelText}</span>
      </Styles.Label>
    </>
  );
}

export default BasicCheckbox;
