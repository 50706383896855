import { atom } from "jotai";

const defaultModalAtom = atom({ show: false, zIndex: 20 });
const mainModalAtom = atom({ show: false, zIndex: 20 });

export { defaultModalAtom, mainModalAtom };

export default {
  defaultModalAtom,
  mainModalAtom,
};
