import styled from "styled-components";

const Container = styled.section`
  & .title {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: bold;
  }

  & .img {
    height: 540px;
  }

  @media (max-width: 1600px) {
    & .title {
      margin-bottom: calc(100vw * (30 / 1600));
      font-size: calc(100vw * (26 / 1600));
    }

    & .img {
      height: calc(100vw * (540 / 1600));
    }
  }

  @media (max-width: 768px) {
    & .title {
      margin-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (20 / 375));
    }

    & .img {
      width: calc(100vw * (870 / 375));
      height: calc(100vw * (450 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
