import connector from "service/connector";
import utils from "utils";


const getList = async (data) => await
  connector.connectFetchController("promotion" + (data ? "?" + utils.etc.getFilterStr(data) : ""), "GET");

const getDetail = async (data) => await
  connector.connectFetchController("promotion/detail?id=" + data, "GET");


export { getList, getDetail };
export default { getList, getDetail };