import styled from "styled-components";

// img
import BgLogo from "resources/image/bg_logo.svg";

const Container = styled.main`
  padding: 50px 0 270px;
  background: url(${BgLogo}) no-repeat center calc(100% - 130px) / 96px 91px;

  & .bannerSection {
    margin-bottom: 50px;
  }

  & .greetingSection {
    margin-bottom: 100px;

    & .inner {
      display: flex;
      align-items: flex-end;
      gap: 96px;

      & .textWrap {
        & h3 {
          margin-bottom: 80px;
        }

        & .colorText {
          margin-bottom: 30px;
          font-weight: 700;
          color: var(--gray02);
        }

        & .description {
          margin-bottom: 30px;
          word-break: keep-all;
          line-height: 1.4;
        }

        & .writer {
          font-weight: 700;
        }
      }

      & .img {
        flex-shrink: 0;
        width: 858px;
        height: 620px;
      }
    }
  }

  & .goalSection {
    margin-bottom: 100px;
    padding: 80px 0;
    background-color: var(--gray04);

    & h3 {
      margin-bottom: 30px;
    }

    & .textContainer {
      display: flex;
      align-items: center;
      gap: 86px;
      margin-bottom: 80px;
      padding: 34px;
      font-weight: 600;
      color: #fff;
      line-height: 1.5;
      background-color: var(--main);
      border-radius: 30px;

      & .img {
        width: 770px;
        height: 242px;
        border-radius: 20px;
        overflow: hidden;
      }
    }

    & .goalImg {
      height: 518px;
    }
  }

  & .overcomeSection {
    & h3 {
      margin-bottom: 30px;
    }

    & .img {
      height: 830px;
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (270 / 1600));
    background: url(${BgLogo}) no-repeat center
      calc(100% - calc(100vw * (130 / 1600))) / calc(100vw * (96 / 1600))
      calc(100vw * (91 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .greetingSection {
      margin-bottom: calc(100vw * (100 / 1600));

      & .inner {
        gap: calc(100vw * (96 / 1600));

        & .textWrap {
          & h3 {
            margin-bottom: calc(100vw * (80 / 1600));
          }

          & .colorText {
            margin-bottom: calc(100vw * (30 / 1600));
          }

          & .description {
            margin-bottom: calc(100vw * (30 / 1600));
          }
        }

        & .img {
          width: calc(100vw * (858 / 1600));
          height: calc(100vw * (620 / 1600));
        }
      }
    }

    & .goalSection {
      margin-bottom: calc(100vw * (100 / 1600));
      padding: calc(100vw * (80 / 1600)) 0;

      & h3 {
        margin-bottom: calc(100vw * (30 / 1600));
      }

      & .textContainer {
        gap: calc(100vw * (86 / 1600));
        margin-bottom: calc(100vw * (80 / 1600));
        padding: calc(100vw * (34 / 1600));
        border-radius: calc(100vw * (30 / 1600));

        & .img {
          width: calc(100vw * (770 / 1600));
          height: calc(100vw * (242 / 1600));
          border-radius: calc(100vw * (20 / 1600));
        }
      }

      & .goalImg {
        height: calc(100vw * (518 / 1600));
      }
    }

    & .overcomeSection {
      & h3 {
        margin-bottom: calc(100vw * (30 / 1600));
      }

      & .img {
        height: calc(100vw * (830 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (204 / 375));
    background: url(${BgLogo}) no-repeat center
      calc(100% - calc(100vw * (80 / 375))) / calc(100vw * (78 / 375))
      calc(100vw * (74 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .greetingSection {
      margin-bottom: calc(100vw * (80 / 375));

      & .inner {
        flex-direction: column;
        gap: calc(100vw * (30 / 375));

        & .textWrap {
          & h3 {
            margin-bottom: calc(100vw * (30 / 375));
            word-break: keep-all;
          }

          & .colorText {
            margin-bottom: calc(100vw * (20 / 375));
          }

          & .description {
            margin-bottom: calc(100vw * (20 / 375));
          }
        }

        & .img {
          width: 100%;
          height: calc(100vw * (248 / 375));
        }
      }
    }

    & .goalSection {
      margin-bottom: calc(100vw * (80 / 375));
      padding: calc(100vw * (80 / 375)) 0;

      & h3 {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .textContainer {
        flex-direction: column;
        gap: calc(100vw * (30 / 375));
        margin-bottom: calc(100vw * (50 / 375));
        padding: calc(100vw * (20 / 375));
        text-align: center;
        border-radius: calc(100vw * (20 / 375));

        & .img {
          width: calc(100vw * (303 / 375));
          height: calc(100vw * (95 / 375));
          border-radius: calc(100vw * (10 / 375));
        }
      }

      & .goalImg {
        width: calc(100vw * (310 / 375));
        height: calc(100vw * (963 / 375));
        margin: 0 auto;
      }
    }

    & .overcomeSection {
      & h3 {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .img {
        width: calc(100vw * (1257 / 375));
        height: calc(100vw * (643 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
