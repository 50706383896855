import styled from "styled-components";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 60px;
  font-weight: 500;
  color: var(--blue01);
  border: 2px solid var(--blue01);
  border-radius: 500px;
  transition: 0.3s;

  &:hover {
    color: #fff;
    background-color: var(--blue01);
  }

  @media (max-width: 1600px) {
    width: calc(100vw * (250 / 1600));
    height: calc(100vw * (60 / 1600));
    border-width: calc(100vw * (2 / 1600));
    border-radius: calc(100vw * (500 / 1600));
  }

  @media (max-width: 768px) {
    width: calc(100vw * (220 / 375));
    height: calc(100vw * (50 / 375));
    border-width: calc(100vw * (2 / 375));
    border-radius: calc(100vw * (500 / 375));
  }
`;

export { Button };

export default {
  Button,
};
