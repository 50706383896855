import styled from "styled-components";

const Container = styled.section`
  & .title {
    font-size: 26px;
    font-weight: bold;
  }

  & .imgContainer {
    display: flex;
    gap: 60px;
    margin-bottom: 100px;

    & .imgWrap {
      width: 720px;
    }

    & .title {
      margin-bottom: 30px;
    }

    & .img {
      height: 520px;
    }
  }

  & h3 {
    margin-bottom: 50px;
    text-align: center;
  }

  & .analyticalWrap {
    margin-bottom: 30px;
  }

  & .analyticalList {
    &:not(:last-child) {
      margin-bottom: 50px;
      padding-bottom: 50px;
      border-bottom: 1px solid var(--gray02);
    }

    & .img {
      width: 1030px;
      height: 231px;
      margin: 0 auto 30px;
    }

    & .title {
      text-align: center;
    }
  }

  & .bottomText {
    font-size: 18px;
    color: var(--gray01);
    text-align: center;
  }

  @media (max-width: 1600px) {
    & .title {
      font-size: calc(100vw * (26 / 1600));
    }

    & .imgContainer {
      gap: calc(100vw * (60 / 1600));
      margin-bottom: calc(100vw * (100 / 1600));

      & .imgWrap {
        width: calc(100vw * (720 / 1600));
      }

      & .title {
        margin-bottom: calc(100vw * (30 / 1600));
      }

      & .img {
        height: calc(100vw * (520 / 1600));
      }
    }

    & h3 {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .analyticalWrap {
      margin-bottom: calc(100vw * (30 / 1600));
    }

    & .analyticalList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (50 / 1600));
        padding-bottom: calc(100vw * (50 / 1600));
      }

      & .img {
        width: calc(100vw * (1030 / 1600));
        height: calc(100vw * (231 / 1600));
        margin: 0 auto calc(100vw * (30 / 1600));
      }
    }

    & .bottomText {
      font-size: calc(100vw * (18 / 1600));
    }
  }

  @media (max-width: 768px) {
    & .title {
      font-size: calc(100vw * (20 / 375));
    }

    & .imgContainer {
      flex-direction: column;
      gap: calc(100vw * (30 / 375));
      margin-bottom: calc(100vw * (80 / 375));

      & .imgWrap {
        width: 100%;
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        height: calc(100vw * (290 / 375));
      }
    }

    & h3 {
      margin-bottom: calc(100vw * (20 / 375));
    }

    & .analyticalWrap {
      margin-bottom: calc(100vw * (30 / 375));
    }

    & .analyticalList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (30 / 375));
        padding-bottom: calc(100vw * (30 / 375));
      }

      & .img {
        width: 100%;
        height: calc(100vw * (77 / 375));
        margin: 0 auto calc(100vw * (20 / 375));
      }

      & .title {
        text-align: left;
      }
    }

    & .bottomText {
      font-size: calc(100vw * (18 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
