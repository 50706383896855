import Styles from "./styles";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

// img
import Logo from "resources/image/icon/logo.svg";
import IconMenu from "resources/image/icon/icon_menu.svg";
import IconClose from "resources/image/icon/icon_close.svg";

function Header() {
  const [isHover, setIsHover] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menu = [
    {
      text: "CLEVERcns",
      url: "/introduction",
      twoDepth: [
        {
          text: "소개",
          url: "/introduction",
        },
        {
          text: "연혁",
          url: "/history",
        },
        {
          text: "조직도",
          url: "/organization-chart",
        },
      ],
    },
    {
      text: "신경-정신질환",
      url: "/disease/neurodevelopmental",
      twoDepth: [
        {
          text: "신경발달질환",
          url: "/disease/neurodevelopmental",
        },
        {
          text: "정신질환",
          url: "/disease/psychiatric",
        },
        {
          text: "퇴행성질환",
          url: "/disease/neurodegenerative",
        },
        {
          text: "급성 세포 손상 뇌질환",
          url: "/disease/acute-brain-injury",
        },
        {
          text: "기타",
          url: "/disease/others",
        },
      ],
    },
    {
      text: "서비스",
      url: "/overview",
      twoDepth: [
        {
          text: "개요",
          url: "/overview",
        },
        {
          text: "유효성평가 서비스",
          url: "/validity",
          threeDepth: [
            {
              text: (
                <>
                  <i>In vitro</i> 평가
                </>
              ),
              url: "in-vitro",
            },
            {
              text: "행동평가",
              url: "behavior",
            },
            {
              text: "영상평가",
              url: "video",
            },
            {
              text: "조직평가",
              url: "tissue",
            },
            {
              text: "바이오뱅크",
              url: "biobank",
            },
            {
              text: "BBB 투과도 평가",
              url: "BBB",
            },
          ],
        },
        {
          text: "임상시험 자문 서비스",
          url: "/advice",
        },
        {
          text: "서비스 절차",
          url: "/step",
        },
      ],
    },
    {
      text: "센터소식",
      url: "/notice",
      twoDepth: [
        {
          text: "공지사항",
          url: "/notice",
        },
        {
          text: "홍보자료",
          url: "/promotion",
        },
      ],
    },
    {
      text: "문의하기",
      url: "/request",
      twoDepth: [
        {
          text: "의뢰서 작성",
          url: "/request",
        },
        {
          text: "Contact us",
          url: "/contact",
        },
      ],
    },
  ];

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (isMenuOpen) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [isMenuOpen]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <>
      <Styles.Container
        className={isMenuOpen ? "on" : ""}
        onMouseLeave={(e) => {
          setIsHover(null);
        }}
      >
        <div className={"top" + (isHover !== null ? " on" : "")}>
          <div className="inner">
            <Link to={"/"} className="logo">
              <img src={Logo} alt="logo" />
            </Link>

            <ul className="menuListWrap">
              {menu.map((item, index) => {
                return (
                  <li
                    className={"menuList" + (isHover === index ? " on" : "")}
                    key={"menuList" + index}
                  >
                    <Link
                      to={item.url}
                      onMouseOver={(e) => {
                        setIsHover(index);
                      }}
                    >
                      {item.text}
                    </Link>
                  </li>
                );
              })}
            </ul>

            <button
              type="button"
              className="menuButton"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              <img src={isMenuOpen ? IconClose : IconMenu} alt="IconMenu" />
            </button>
          </div>
        </div>

        {isHover !== null && (
          <nav>
            <ul className="twoDepthListWrap">
              {menu[isHover]?.twoDepth.map((item, index) => {
                return (
                  <li className="twoDepthList" key={"twoDepthList" + index}>
                    <Link
                      to={
                        item.threeDepth
                          ? `${item.url}/${item.threeDepth[0].url}`
                          : item.url
                      }
                      className="twoDepth"
                    >
                      {item.text}
                    </Link>

                    {item.threeDepth && (
                      <ul className="threeDepthListWrap">
                        {item.threeDepth.map((current, idx) => {
                          return (
                            <li
                              className="threeDepthList"
                              key={"threeDepthList" + idx}
                            >
                              <Link
                                to={`${item.url}/${current.url}`}
                                className="threeDepth"
                              >
                                {current.text}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
        )}
      </Styles.Container>

      {isMenuOpen && (
        <Styles.Menu>
          <div className="inner">
            <ul className="menuListWrap">
              {menu.map((item, index) => {
                return (
                  <li className="menuList" key={"menuList" + index}>
                    <Link
                      className="depth01"
                      to={item.url}
                      onClick={() => {
                        setIsMenuOpen(false);
                      }}
                    >
                      {item.text}
                    </Link>

                    <ul className="depth02ListWrap">
                      {item.twoDepth.map((current, idx) => {
                        return (
                          <li className="depth02List" key={"depth02List" + idx}>
                            <Link
                              className="depth02"
                              to={
                                current.threeDepth
                                  ? `${current.url}/${current.threeDepth[0].url}`
                                  : current.url
                              }
                              onClick={() => {
                                setIsMenuOpen(false);
                              }}
                            >
                              {current.text}
                            </Link>

                            {current.threeDepth && (
                              <ul className="depth03ListWrap">
                                {current.threeDepth.map((v, i) => {
                                  return (
                                    <li
                                      className="depth03List"
                                      key={"depth03" + i}
                                    >
                                      <Link
                                        className="depth03"
                                        to={`${current.url}/${v.url}`}
                                        onClick={() => {
                                          setIsMenuOpen(false);
                                        }}
                                      >
                                        {v.text}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </Styles.Menu>
      )}
    </>
  );
}

export default Header;
