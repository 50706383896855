import styled from "styled-components";

const Container = styled.main`
  padding: 50px 0 130px;

  & h3 {
    margin-bottom: 30px;
  }

  & .bannerSection {
    margin-bottom: 50px;
  }

  & .organizationChartSection {
    margin-bottom: 100px;

    & .img {
      height: 892px;
    }
  }

  & .operationChartSection {
    margin-bottom: 100px;

    & .img {
      width: 1240px;
      height: 1014px;
    }
  }

  & .companySection {
    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 50px 33px;
    }

    & .companyList {
      width: 350px;

      & .img {
        margin-bottom: 20px;
        height: 180px;
      }

      & .companyName {
        font-weight: 600;
        color: var(--gray01);
        text-align: center;
      }
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (130 / 1600));

    & h3 {
      margin-bottom: calc(100vw * (30 / 1600));
    }

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .organizationChartSection {
      margin-bottom: calc(100vw * (100 / 1600));

      & .img {
        height: calc(100vw * (892 / 1600));
      }
    }

    & .operationChartSection {
      margin-bottom: calc(100vw * (100 / 1600));

      & .img {
        width: calc(100vw * (1240 / 1600));
        height: calc(100vw * (1014 / 1600));
      }
    }

    & .companySection {
      & ul {
        gap: calc(100vw * (50 / 1600)) calc(100vw * (33 / 1600));
      }

      & .companyList {
        width: calc(100vw * (350 / 1600));

        & .img {
          margin-bottom: calc(100vw * (20 / 1600));
          height: calc(100vw * (180 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (80 / 375));

    & h3 {
      margin-bottom: calc(100vw * (30 / 375));
    }

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .organizationChartSection {
      margin-bottom: calc(100vw * (80 / 375));

      & .img {
        width: calc(100vw * (1320 / 375));
        height: calc(100vw * (745 / 375));
      }
    }

    & .operationChartSection {
      margin-bottom: calc(100vw * (80 / 375));

      & .img {
        width: calc(100vw * (1020 / 375));
        height: calc(100vw * (806 / 375));
      }
    }

    & .companySection {
      & ul {
        gap: calc(100vw * (45 / 375)) calc(100vw * (15 / 375));
      }

      & .companyList {
        width: calc(100vw * (164 / 375));

        & .img {
          margin-bottom: calc(100vw * (10 / 375));
          height: calc(100vw * (84 / 375));
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
