import Styles from "./styles";
import utils from "utils";
import { useNotice } from "hooks";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";

// img
import ImgBanner from "resources/image/news/img_notice_banner.jpg";
import ImgBannerMb from "resources/image/news/img_notice_banner_mb.jpg";

function NoticePage() {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [filter, setFilter] = useState({});

  const supportInfo = useNotice({
    noticeList: filter
  });
  const data = supportInfo.getNoticeList?.data || []

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page || 1,
      pageSize: 5,
      recordSize: 10,
    };
    setFilter({ ...f });
  }, [location])

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation
          category={"센터소식"}
          ImgBanner={isMobile ? ImgBannerMb : ImgBanner}
        />

        <section className="noticeSection">
          <div className="inner">
            <ul className="thead">
              <li className="th number">번호</li>
              <li className="th title">공지 제목</li>
              <li className="th date">등록일자</li>
            </ul>
            {data.list?.length > 0 ? (
              <>
                <ul className="listWrap">
                  {data.list?.map((item, index) => {
                    return (
                      <li className="list" key={"list" + index}>
                        <Link to={`/notice/detail/${item.id}`}>
                          <div className="number">{item.index}</div>

                          <div className="title">
                            <p>{item.title}</p>
                          </div>

                          <div className="date">{item.created_format}</div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>

                {data.pagination?.totalRecordCountAll > 10 && (
                  <div className="pagination">
                    <ui.Pagination
                      page={data.pagination?.page}
                      list={data.pagination?.page_list}
                      maxPage={data.pagination?.totalPageCount}
                    />
                  </div>
                )}
              </>
            ) : (
              <p className="emptyText">등록된 게시물이 없습니다.</p>
            )}
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default NoticePage;
