import styled from "styled-components";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right.svg";

const Container = styled.div`
  margin-bottom: 30px;

  & h2 {
    margin-bottom: 20px;
  }

  & .navigation {
    display: flex;
    align-items: center;
    gap: 54px;
    font-size: 18px;

    & > li {
      position: relative;

      &:not(:last-child)::after {
        position: absolute;
        top: 50%;
        left: calc(100% + 20px);
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        background: url(${IconArrowRight}) no-repeat center / cover;
        content: "";
      }
    }

    & .pageName {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      & .icon {
        width: 21px;
        height: 21px;

        &.on {
          transform: rotate(180deg);
        }
      }
    }

    & .selectWrap {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      width: 195px;
      color: var(--gray02);
      background-color: var(--gray04);
      border-radius: 10px;

      & .menuList a {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 20px;

        &.on {
          color: var(--black);
        }
      }
    }
  }

  @media (max-width: 1600px) {
    margin-bottom: calc(100vw * (30 / 1600));

    & h2 {
      margin-bottom: calc(100vw * (20 / 1600));
    }

    & .navigation {
      gap: calc(100vw * (54 / 1600));
      font-size: calc(100vw * (18 / 1600));

      & > li {
        &:not(:last-child)::after {
          left: calc(100% + calc(100vw * (20 / 1600)));
          width: calc(100vw * (14 / 1600));
          height: calc(100vw * (14 / 1600));
        }
      }

      & .pageName {
        gap: calc(100vw * (6 / 1600));

        & .icon {
          width: calc(100vw * (21 / 1600));
          height: calc(100vw * (21 / 1600));
        }
      }

      & .selectWrap {
        top: calc(100% + calc(100vw * (5 / 1600)));
        width: calc(100vw * (195 / 1600));
        border-radius: calc(100vw * (10 / 1600));

        & .menuList a {
          height: calc(100vw * (40 / 1600));
          padding: 0 calc(100vw * (20 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: calc(100vw * (20 / 375));

    & h2 {
      margin-bottom: calc(100vw * (10 / 375));
    }

    & .navigation {
      gap: calc(100vw * (34 / 375));
      font-size: calc(100vw * (16 / 375));

      & > li {
        &:not(:last-child)::after {
          left: calc(100% + calc(100vw * (10 / 375)));
          width: calc(100vw * (14 / 375));
          height: calc(100vw * (14 / 375));
        }
      }

      & .pageName {
        gap: calc(100vw * (6 / 375));

        & p {
          max-width: calc(100vw * (115 / 375));
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        & .icon {
          width: calc(100vw * (21 / 375));
          height: calc(100vw * (21 / 375));
        }
      }

      & .selectWrap {
        top: calc(100% + calc(100vw * (5 / 375)));
        width: calc(100vw * (160 / 375));
        border-radius: calc(100vw * (10 / 375));

        & .menuList a {
          height: calc(100vw * (34 / 375));
          padding: 0 calc(100vw * (14 / 375));
        }
      }
    }
  }
`;

const BannerSection = styled.section`
  & .banner {
    height: 370px;
  }

  @media (max-width: 1600px) {
    & .banner {
      height: calc(100vw * (370 / 1600));
    }
  }

  @media (max-width: 768px) {
    & .inner {
      width: 100%;
    }
    & .banner {
      height: calc(100vw * (224 / 375));
    }
  }
`;

export { Container, BannerSection };

export default {
  Container,
  BannerSection,
};
