import Styles from "./styles";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { common, layout, ui } from "components";
import { useInquiry, usePolicy } from "hooks";
import { UploadFileList } from "./components";

// img
import ImgBanner from "resources/image/ask/img_request_banner.jpg";
import ImgBannerMb from "resources/image/ask/img_request_banner_mb.jpg";

function RequestPage() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const policyInfo = usePolicy();
  const inquiryInfo = useInquiry();
  const policyData = policyInfo.getPolicy.data?.description || [];

  const [errorText, setErrorText] = useState({});
  const [inputs, setInputs] = useState({
    company_title: "",
    company_address: "",
    head_name: "",
    manager_name: "",
    manager_email: "",
    manager_phone: "",
    11: "",
    12: "",
    23: "",
    25: "",
    moa: "",
    request: "",
  });

  const [company_type, setCompany_type] = useState(null);
  const [personal_yn, setPersonal_yn] = useState(false);
  const [type, setType] = useState({
    11: [],
    12: [],
    14: [],
    21: [],
    22: [],
    23: [],
    24: [],
    25: [],
  });

  const [fileUpload, setFileUpload] = useState({
    "file0": "",
    "file1": "",
    "file2": "",
    "file3": "",
    "file4": "",
  });

  const infoInputData = [
    {
      labelText: "기관명",
      name: "company_title",
      value: inputs.company_title,
      placeholder: "기관명",
      errorText: errorText.company_title,
    },
    {
      labelText: "기관 주소",
      name: "company_address",
      value: inputs.company_address,
      placeholder: "주소",
      errorText: errorText.company_address,
    },
    {
      labelText: "기관 분류",
      name: "company_type",
      checkList: [
        { id: 1, labelText: "대기업" },
        { id: 2, labelText: "중견기업" },
        { id: 3, labelText: "중소기업" },
        { id: 4, labelText: "바이오벤처" },
        { id: 5, labelText: "기타" },
      ],
      errorText: errorText.company_type,
    },
    {
      labelText: "연구 책임자명",
      name: "head_name",
      value: inputs.head_name,
      placeholder: "연구 책임자명",
      errorText: errorText.head_name,
    },
    {
      labelText: "실무 담당자명",
      name: "manager_name",
      value: inputs.manager_name,
      placeholder: "실무 담당자",
      errorText: errorText.manager_name,
    },
    {
      labelText: "담당자 이메일",
      name: "manager_email",
      value: inputs.manager_email,
      placeholder: "담당자 이메일",
      errorText: errorText.manager_email,
    },
    {
      labelText: "담당자 연락처",
      name: "manager_phone",
      value: inputs.manager_phone,
      placeholder: "숫자만 입력",
      errorText: errorText.manager_phone,
    },
  ];
  const testInfoInputData = [
    {
      labelText: "시험물질",
      check: type[11],
      setCheck: (v) => setType((e) => ({ ...e, 11: v })),
      firstCheckInfo: {
        check: type[11],
        setCheck: (v) => setType((e) => ({ ...e, 11: v })),
        checkList: [
          {
            id: "Therapeutics",
            labelText: "Therapeutics",
          },
          {
            id: "Biomarker",
            labelText: "Biomarker",
          },
          {
            id: "Diagnostic panel",
            labelText: "Diagnostic panel",
          },
        ],
      },
      checkList: [
        {
          id: "Small molecules",
          labelText: "Small molecules",
        },
        {
          id: "천연물",
          labelText: "천연물",
        },
        {
          id: "유전자 치료제",
          labelText: "유전자 치료제",
        },
        {
          id: "세포 치료제",
          labelText: "세포 치료제",
        },
        {
          id: "항체 치료제",
          labelText: "항체 치료제",
        },
        {
          id: "단백질",
          labelText: "단백질",
        },
        {
          id: "의료기기",
          labelText: "의료기기",
        },
        {
          id: "testSubstance_기타",
          labelText: "기타",
        },
      ],
      inputData: {
        name: "11",
        value: inputs[11],
        disabled: !type[11].includes("testSubstance_기타"),
        placeholder: "기타",
        errorText: errorText[11],
      },
    },
    {
      labelText: "표적질환",
      check: type[12],
      setCheck: (v) => setType((e) => ({ ...e, 12: v })),
      checkList: [
        {
          id: "ASD",
          labelText: "ASD",
        },
        {
          id: "ADHD",
          labelText: "ADHD",
        },
        {
          id: "뇌전증",
          labelText: "뇌전증",
        },
        {
          id: "뇌혈관질환",
          labelText: "뇌혈관질환",
        },
        {
          id: "외상성 뇌손상",
          labelText: "외상성 뇌손상",
        },
        {
          id: "뇌염",
          labelText: "뇌염",
        },
        {
          id: "신경계 신생물",
          labelText: "신경계 신생물",
        },
        {
          id: "알츠하이머병",
          labelText: "알츠하이머병",
        },
        {
          id: "파킨슨병",
          labelText: "파킨슨병",
        },
        {
          id: "타우병증",
          labelText: "타우병증",
        },
        {
          id: "헌팅턴병",
          labelText: "헌팅턴병",
        },
        {
          id: "다발성 경화증",
          labelText: "다발성 경화증",
        },
        {
          id: "근위축성 측색경화증",
          labelText: "근위축성 측색경화증",
        },
        {
          id: "난청",
          labelText: "난청",
        },
        {
          id: "targetDisease_기타",
          labelText: "기타",
        },
      ],
      inputData: {
        name: "12",
        value: inputs[12],
        disabled: !type[12].includes("targetDisease_기타"),
        placeholder: "기타",
        errorText: errorText[12],
      },
    },
    {
      labelText: "MOA",
      inputData: {
        name: "moa",
        value: inputs.moa,
        placeholder: "MOA",
        errorText: errorText.moa,
      },
    },
    {
      labelText: "현재 개발단계",
      check: type[14],
      setCheck: (v) => setType((e) => ({ ...e, 14: v })),
      checkList: [
        {
          id: "Target discovery",
          labelText: "Target discovery",
        },
        {
          id: "Hit",
          labelText: "Hit",
        },
        {
          id: "Lead",
          labelText: "Lead",
        },
        {
          id: "Candidate",
          labelText: "Candidate",
        },
        {
          id: "Clinical trial",
          labelText: "Clinical trial",
        },
      ],
      errorText: errorText[14],
    },
  ];
  const itemInputData = [
    {
      labelText: (
        <>
          <i>In vitro</i> <br />
          평가 서비스
        </>
      ),
      check: type[21],
      setCheck: (v) => setType((e) => ({ ...e, 21: v })),
      checkList: [
        {
          id: "신경세포 독성평가",
          labelText: "신경세포 독성평가",
        },
        {
          id: "아교세포 활성평가",
          labelText: "아교세포 활성평가",
        },
        {
          id: "신경가소성 평가",
          labelText: "신경가소성 평가",
        },
        {
          id: "포식작용 평가",
          labelText: "포식작용 평가",
        },
        {
          id: "세포 소기관 기능평가",
          labelText: "세포 소기관 기능평가",
        },
      ],
      errorText: errorText[21],
    },
    {
      labelText: (
        <>
          <i>In vivo</i> <br />
          평가 서비스
        </>
      ),
      check: type[22],
      setCheck: (v) => setType((e) => ({ ...e, 22: v })),
      checkList: [
        {
          id: "행동평가",
          labelText: "행동평가",
        },
        {
          id: "약리평가1",
          labelText: "약리평가",
        },
        {
          id: "영장류 평가",
          labelText: "영장류 평가",
        },
      ],
      errorText: errorText[22],
    },
    {
      labelText: "영상평가",
      check: type[23],
      setCheck: (v) => setType((e) => ({ ...e, 23: v })),
      checkList: [
        {
          id: "RI 합성",
          labelText: "RI 합성",
        },
        {
          id: "BBB 투과도 및 뇌영역별 분포",
          labelText: "BBB 투과도 및 뇌영역별 분포",
        },
        {
          id: "바이오마커 평가",
          labelText: "바이오마커 평가",
        },
        {
          id: "video_기타",
          labelText: "기타",
        },
      ],
      inputData: {
        name: "23",
        value: inputs[23],
        disabled: !type[23].includes("video_기타"),
        placeholder: "기타",
        errorText: errorText[23],
      },
    },
    {
      labelText: (
        <>
          <i>Ex vivo</i> <br />
          평가 서비스
        </>
      ),
      check: type[24],
      setCheck: (v) => setType((e) => ({ ...e, 24: v })),
      checkList: [
        {
          id: "병리평가",
          labelText: "병리평가",
        },
        {
          id: "CSF 평가",
          labelText: "CSF 평가",
        },
        {
          id: "약리평가2",
          labelText: "약리평가",
        },
        {
          id: "전기생리 평가",
          labelText: "전기생리 평가",
        },
        {
          id: "면역 평가",
          labelText: "면역 평가",
        },
        {
          id: "오믹스 평가",
          labelText: "오믹스 평가",
        },
      ],
      errorText: errorText[24],
    },
    {
      labelText: "자문",
      check: type[25],
      setCheck: (v) => setType((e) => ({ ...e, 25: v })),
      checkList: [
        {
          id: "질환별 임상의 자문",
          labelText: "질환별 임상의 자문",
        },
        {
          id: "전임상시험 자문",
          labelText: "전임상시험 자문",
        },
        {
          id: "임상약리 자문",
          labelText: "임상약리 자문",
        },
        {
          id: "advice_기타",
          labelText: "기타",
        },
      ],
      inputData: {
        name: "25",
        value: inputs[25],
        disabled: !type[25].includes("advice_기타"),
        placeholder: "기타",
        errorText: errorText[25],
      },
    },
    {
      labelText: "의뢰사항",
      inputData: {
        name: "request",
        value: inputs.request,
        placeholder: "의뢰사항",
        errorText: errorText.request,
      },
    },
  ];
  const termsGuideList = [
    "개인정보 수집 목적 : 의뢰서 신청 확인 용도",
    "개인정보 수집 항목 : 기관, 기관 주소, 이름, 이메일, 연락처",
    "보유 및 이용기간 : 수집일부터 3년까지",
  ];

  const onChange = (e) => {
    let { name, value } = e.target;

    if (name === "manager_phone") {
      value = value.replace(/[^0-9]/gi, "");
      value = value.replace(/-/gi, "");
      value =
        value?.substr(0, 3) +
        (value?.length > 3
          ? "-" +
            value?.substr(3, 4) +
            (value?.length > 7 ? "-" + value?.substr(7, 4) : "")
          : "");
    }

    setInputs((e) => ({ ...e, [name]: value }));
  };

  // 체크박스 단일 선택
  const handleSingleCheck = (checked, id, check, setCheck) => {
    if (checked) {
      // 단일 선택 시 체크된 아이템을 배열에 추가
      setCheck(check.concat(id));
    } else {
      // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
      setCheck(check.filter(el => el !== id));
    }
  };

  const validate = () => {
    let infoError = false;
    let testInfoError = false;
    let itemError = { error: 0, request: false };

    const infoData = [
      "company_title",
      "company_address",
      "head_name",
      "manager_name",
      "manager_email",
      "manager_phone",
    ];
    const testInfoData = ["11", "12", "14"];
    const itemData = ["21", "22", "23", "24", "25"];

    // infoValidate
    infoData.forEach((i) => {
      if (inputs[i] === "") {
        setErrorText((e) => ({ ...e, [i]: "내용을 입력해 주세요." }));
        infoError = true;
      } else setErrorText((e) => ({ ...e, [i]: "" }));
    });
    if (company_type === null) {
      setErrorText((e) => ({ ...e, company_type: "항목을 선택해 주세요." }));
      infoError = true;
    } else setErrorText((e) => ({ ...e, company_type: "" }));

    // testInfoValidate
    testInfoData.forEach((i) => {
      if (type[i].length === 0) {
        setErrorText((e) => ({ ...e, [i]: "항목을 선택해 주세요." }));
        testInfoError = true;
      } else if (type[i].join(",").includes("기타") && inputs[i] === "") {
        setErrorText((e) => ({ ...e, [i]: "내용을 입력해 주세요." }));
        testInfoError = true;
      } else setErrorText((e) => ({ ...e, [i]: "" }));
    });
    if (inputs.moa === "") {
      setErrorText((e) => ({ ...e, moa: "내용을 입력해 주세요." }));
      testInfoError = true;
    } else setErrorText((e) => ({ ...e, moa: "" }));

    // testInfoValidate
    const check = itemData.map((i) => type[i].length).reduce((a, b) => a + b);
    itemData.forEach((i) => {
      if (type[i].join(",").includes("기타") && inputs[i] === "") {
        setErrorText((e) => ({ ...e, [i]: "내용을 입력해 주세요." }));
        itemError = { ...itemError, error: true };
      } else if (check === 0) {
        setErrorText((e) => ({ ...e, [i]: "항목을 선택해 주세요." }));
      } else setErrorText((e) => ({ ...e, [i]: "" }));
    });
    if (inputs.request === "") {
      setErrorText((e) => ({ ...e, request: "내용을 입력해 주세요." }));
      itemError = { ...itemError, request: true };
    } else setErrorText((e) => ({ ...e, request: "" }));

    const scrollInit = {
      block: "start",
      behavior: "smooth",
      location: "false",
    };
    if (infoError) {
      document.getElementById("infoFocus")?.scrollIntoView(scrollInit);
      return false;
    }
    if (testInfoError) {
      document.getElementById("testFocus")?.scrollIntoView(scrollInit);
      return false;
    }
    if (check === 0 || itemError.error || itemError.request) {
      document.getElementById("itemFocus")?.scrollIntoView(scrollInit);
      return false;
    }
    if (!personal_yn) {
      setErrorText((e) => ({
        ...e,
        personal: "개인정보 수집에 동의해 주세요.",
      }));
      return false;
    } else setErrorText((e) => ({ ...e, personal: "" }));

    return true;
  };

  const fileUrls = Object.values(fileUpload)
    .filter(files => typeof files === 'object' && files.file_url)
    .map(files => files.file_url);

  const onNext = () => {
    if (validate()) {
      inquiryInfo.postInquiry({
        company_title: inputs.company_title,
        company_address: inputs.company_address,
        head_name: inputs.head_name,
        manager_name: inputs.manager_name,
        manager_email: inputs.manager_email,
        manager_phone: inputs.manager_phone,
        moa: inputs.moa,
        request: inputs.request,
        file_list: fileUrls,

        company_type,
        personal_yn: personal_yn ? "Y" : "N",

        ...Object.keys(type).reduce((acc, i) => {
          acc[`type_${i}`] = [
            ...type[i]
            .map(v => v.replace(/[0-9]/g, ""))
            .filter(v => !v.includes("기타")),
            type[i].join(",").includes("기타") && inputs[i],
          ].filter(v => v);
          return acc;
        }, {}),
      });
    }
  };

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation
          category={"문의하기"}
          ImgBanner={isMobile ? ImgBannerMb : ImgBanner}
        />

        <section className="requestSection">
          <div className="inner">
            <form>
              <div className="requestWrap info">
                <div className="titleWrap">
                  <div className="circle">필수</div>
                  <p className="title" id="infoFocus">
                    신청기관 정보
                  </p>
                </div>

                <div className="infoWrap">
                  <div className="left">
                    <div className="inputList">
                      <ui.input.BasicInput
                        data={infoInputData[0]}
                        onChange={onChange}
                      />
                    </div>

                    <div className="inputList">
                      <ui.input.BasicInput
                        data={infoInputData[1]}
                        onChange={onChange}
                      />
                    </div>

                    <div className="inputList checkInput">
                      <p className="label">{infoInputData[2].labelText}</p>

                      <ul>
                        {infoInputData[2].checkList.map((item, index) => {
                          return (
                            <li className="checkList" key={"checkList" + index}>
                              <ui.input.BasicRadio
                                data={item}
                                name={infoInputData[2].name}
                                check={company_type}
                                setCheck={setCompany_type}
                              />
                            </li>
                          );
                        })}
                      </ul>
                      {infoInputData[2].errorText && (
                        <p className="errorText">
                          {infoInputData[2].errorText}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="right">
                    <div className="inputList">
                      <ui.input.BasicInput
                        data={infoInputData[3]}
                        onChange={onChange}
                      />
                    </div>

                    <div className="inputList">
                      <ui.input.BasicInput
                        data={infoInputData[4]}
                        onChange={onChange}
                      />
                    </div>

                    <div className="inputList">
                      <ui.input.BasicInput
                        data={infoInputData[5]}
                        onChange={onChange}
                      />
                    </div>

                    <div className="inputList">
                      <ui.input.BasicInput
                        data={infoInputData[6]}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="requestWrap testInfo">
                <div className="titleWrap">
                  <div className="circle">필수</div>
                  <p className="title" id="testFocus">
                    시험물질 및 시험정보
                  </p>
                  <p className="guideText">중복 선택이 가능합니다.</p>
                </div>

                {testInfoInputData.map((item, index) => {
                  return (
                    <div className="inputList" key={"itemInputList" + index}>
                      <p className="label">{item.labelText}</p>

                      <div className="inputContainer">
                        {item.firstCheckInfo && (
                          <ul>
                            {item.firstCheckInfo.checkList.map(
                              (current, idx) => {
                                return (
                                  <li className="input" key={"itemInput" + idx}>
                                    <ui.input.BasicCheckbox
                                      id={current.id}
                                      labelText={current.labelText}
                                      checked={
                                        item.firstCheckInfo.check.includes(
                                          current.id
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        handleSingleCheck(
                                          e.target.checked,
                                          e.target.id,
                                          item.firstCheckInfo.check,
                                          item.firstCheckInfo.setCheck
                                        );
                                      }}
                                    />
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        )}

                        {item.check ? (
                          <>
                            <ul>
                              {item.checkList.map((current, idx) => {
                                return (
                                  <li className="input" key={"itemInput" + idx}>
                                    <ui.input.BasicCheckbox
                                      id={current.id}
                                      labelText={current.labelText}
                                      checked={
                                        item.check.includes(current.id)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        handleSingleCheck(
                                          e.target.checked,
                                          e.target.id,
                                          item.check,
                                          item.setCheck
                                        );
                                      }}
                                    />
                                  </li>
                                );
                              })}
                              {item.inputData && (
                                <li className="input etc">
                                  <ui.input.BasicInput
                                    data={item.inputData}
                                    onChange={onChange}
                                  />
                                </li>
                              )}
                            </ul>
                            {!item.inputData && (
                              <p className="errorText">{item.errorText}</p>
                            )}
                          </>
                        ) : (
                          <div className="input textarea">
                            <ui.input.BasicTextarea
                              data={item.inputData}
                              onChange={onChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="requestWrap item">
                <div className="titleWrap">
                  <div className="circle">필수</div>
                  <p className="title" id="itemFocus">
                    의뢰 항목
                  </p>
                  <p className="guideText">중복 선택이 가능합니다.</p>
                </div>

                {itemInputData.map((item, index) => {
                  return (
                    <div className="inputList" key={"itemInputList" + index}>
                      <p className="label">{item.labelText}</p>

                      <div className="inputContainer">
                        {item.check ? (
                          <>
                            <ul>
                              {item.checkList.map((current, idx) => {
                                return (
                                  <li className="input" key={"itemInput" + idx}>
                                    <ui.input.BasicCheckbox
                                      id={current.id}
                                      labelText={current.labelText}
                                      checked={
                                        item.check.includes(current.id)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        handleSingleCheck(
                                          e.target.checked,
                                          e.target.id,
                                          item.check,
                                          item.setCheck
                                        );
                                      }}
                                    />
                                  </li>
                                );
                              })}
                              {item.inputData && (
                                <li className="input etc">
                                  <ui.input.BasicInput
                                    data={item.inputData}
                                    onChange={onChange}
                                  />
                                </li>
                              )}
                            </ul>
                            {!item.inputData && (
                              <p className="errorText">{item.errorText}</p>
                            )}
                          </>
                        ) : (
                          <div className="input textarea">
                            <ui.input.BasicTextarea
                              data={item.inputData}
                              onChange={onChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}

                <div className="inputList">
                  <p className="label">첨부파일</p>

                  <ul className="fileListWrap">
                    {Array.from({ length: 5 }).map((item, index) => {
                      return (
                        <li className="fileList" key={"fileList" + index}>
                          <UploadFileList
                            id={"file" + index}
                            file={fileUpload}
                            setFile={setFileUpload}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="requestWrap terms">
                <div className="titleWrap">
                  <div className="circle">필수</div>
                  <p className="title">개인정보 수집 동의</p>
                </div>

                <div className="termsWrap ck-content">
                  <p
                    className="termText"
                    dangerouslySetInnerHTML={{ __html: policyData }}
                  ></p>
                </div>

                <div className="termsGuideText">
                  <p>
                    의뢰서 제출을 위해서는 아래와 같이 개인정보를 수집 이용
                    합니다.
                  </p>

                  <ul>
                    {termsGuideList.map((item, index) => {
                      return (
                        <li
                          className="termsGuideList"
                          key={"termsGuideList" + index}
                        >
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="termsCheck">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={personal_yn}
                    onChange={(e) => setPersonal_yn(e.target.checked)}
                  />
                  <label htmlFor="terms">
                    <span className="checkbox"></span>

                    <span className="labelText">
                      개인정보 수집에 동의합니다.
                    </span>
                  </label>

                  {!personal_yn && (
                    <p className="errorText">{errorText.personal}</p>
                  )}
                </div>
              </div>

              <div className="requestButton">
                <ui.button.BasicButton
                  buttonText={"의뢰서 제출하기"}
                  onClick={onNext}
                />
              </div>
            </form>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default RequestPage;
