import Styles from "./styles";
import { api } from "service";
import { useBoard } from "hooks";
import { useLocation } from "react-router-dom";
import { DiseaseList } from "./components";
import { useMediaQuery } from "react-responsive";
import { common, layout } from "components";

// img
import ImgNeurodevelopmentalBanner from "resources/image/disease/img_neurodevelopmental_banner.jpg";
import ImgNeurodevelopmentalBannerMb from "resources/image/disease/img_neurodevelopmental_banner_mb.jpg";
import ImgPsychiatricBanner from "resources/image/disease/img_psychiatric_banner.jpg";
import ImgPsychiatricBannerMb from "resources/image/disease/img_psychiatric_banner_mb.jpg";
import ImgNeurodegenerativeBanner from "resources/image/disease/img_neurodegenerative_banner.jpg";
import ImgNeurodegenerativeBannerMb from "resources/image/disease/img_neurodegenerative_banner_mb.jpg";
import ImgAcuteBrainInjuryBanner from "resources/image/disease/img_acute_brain_injury_banner.jpg";
import ImgAcuteBrainInjuryBannerMb from "resources/image/disease/img_acute_brain_injury_banner_mb.jpg";
import ImgOthersBanner from "resources/image/disease/img_others_banner.jpg";
import ImgOthersBannerMb from "resources/image/disease/img_others_banner_mb.jpg";

function DiseasePage() {
  const location = useLocation();
  const pathName = location.pathname.split("/")[2];
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const dataType = {
    "neurodevelopmental": 2,
    "psychiatric": 3,
    "neurodegenerative": 4,
    "acute-brain-injury": 5,
    "others": 6,
  }

  const boardInfo = useBoard({ boardList: {
    page: 1,
    category_info_id: dataType[pathName],
    recordSize: 100,
  } });
  const listData = boardInfo.getBoardList.data || [];

  const renderImgType = (type) => {
    switch (type) {
      case "neurodevelopmental":
        return isMobile
          ? ImgNeurodevelopmentalBannerMb
          : ImgNeurodevelopmentalBanner;
      case "psychiatric":
        return isMobile ? ImgPsychiatricBannerMb : ImgPsychiatricBanner;
      case "neurodegenerative":
        return isMobile
          ? ImgNeurodegenerativeBannerMb
          : ImgNeurodegenerativeBanner;
      case "acute-brain-injury":
        return isMobile
          ? ImgAcuteBrainInjuryBannerMb
          : ImgAcuteBrainInjuryBanner;
      case "others":
        return isMobile ? ImgOthersBannerMb : ImgOthersBanner;
    }
  };

  const data = {
    neurodevelopmental: {
      title: "신경발달질환 (Neurodevelopmental Disorders)",
      description: (
        <>
          발달장애로 알려진 신경발달질환은 일반적으로 특정 기술과 정보의 획득,
          이해 및 활용에 대한 정상적인 <br className="mbOnly" />
          발달을 방해하는 신경계 상태를 의미합니다. <br />
          이러한 질환은 자폐 스펙트럼 장애(ASD), 주의력결핍 과잉행동 장애(ADHD),
          뇌전증(Epilepsy), 지적장애, 학습장애 등을 포함합니다. 중추신경계의
          이상, 뇌 손상,
          <br />
          혹은 뇌 발달 지연과 같은 다양한 원인에 의해 발생할 수 있습니다.
          발달장애는 다양한 기능적 장애를 동반하여, 주의력, 인지 능력, 언어
          습득, 문제 해결 능력, 혹은
          <br />
          사회적 상호작용 및 의사소통 등의 다양한 영역에서 어려움을 겪을 수
          있습니다. 현재 이러한 질환들은 주로 행동 및 교육적 개입을 통해
          관리되고 있으나,
          <br />
          조기 진단과 효과적인 치료를 위해서는 과학적 진단법과 질환 특이적
          치료법 개발이 필요합니다.
          <br />
          <br />
          CLEVERcns는 신경발달질환에 대한 의뢰사의 요구를 충족시키기 위해
          다양하고 전문적인 평가서비스를 제공하고 있습니다.
          <br />
          이를 통해 질환에 대한 깊은 이해와 치료법의 발전에 기여하고자 합니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
    psychiatric: {
      title: "정신질환 (Psychiatric Disorders)",
      description: (
        <>
          정신질환은 사고, 감정 및 행동의 장애로 일상생활에 고통과 방해를
          일으키는 복잡한 질환입니다. 성격 특성이나 인격 장애와의 구분이 어려워
          진단은 증상의 심각도, <br className="pcOnly" />
          지속성, 그리고 일상생활에 미치는 영향을 기준으로 이루어집니다.
          CLEVERcns는 DSM-5-TR (The Diagnostic and Statistical Manual of Mental
          Disorders) <br className="pcOnly" />
          을 기반으로 정신질환을 증상과 질환의 진행에 근거하여
          진단적 범주로 구분하고 있습니다. 유전적, 생물학적, 심리적, 환경적
          요인들의 상호작용으로 <br className="pcOnly" />
          인해 발병하는 정신질환에 대한 연구는 계속되고 있습니다. 특히, 유전적
          요인은 많은 정신건강 장애에 영향을 미치며, 일상생활에서의 스트레스와
          결합하여 정신질환의 <br className="pcOnly" />
          발병에 영향을 줄 수 있습니다. 정신질환에는 조현병(Schizophrenia),
          우울증(Depression), 수면장애(Sleep Disorder, Somnipathy),
          중독(Addiction),
          <br className="pcOnly" />
          불안장애(Anxiety Disorder), 양극성 장애(Bipolar Disorder) 등을
          포함합니다.
          <br />
          <br />
          CLEVERcns는 다양한 정신질환에 특화된 동물모델과 유효성평가 서비스를
          제공합니다. 의뢰사의 요구에 맞춰 최적의 해결책을 제공하기 위해
          <br className="pcOnly" />
          최신 기술과 임상 자문단을 활용하고 있습니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
    neurodegenerative: {
      title: "퇴행성신경질환 (Neurodegenerative Disorders)",
      description: (
        <>
          퇴행성신경질환은 신경계의 일부분 혹은 여러 부분에서 점진적으로
          진행되는 신경세포의 손상으로 인한 병들을 통칭합니다. <br />
          이러한 질환은 전형적으로 신경 기능의 저하를 유발하며, 주요 증상과
          침범된 뇌 부위에 따라 분류됩니다. 주요 질환으로는
          알츠하이머병(Alzheimer’s Disease),
          <br />
          파킨슨병(Parkinson’s Disease), 다발성경화증(Multiple Sclerosis),
          헌팅턴병(Huntington’s Disease), 근위축성 측삭경화증(Amyotrophic
          Lateral Sclerosis) <br className="pcOnly" />
          등이 있습니다. 대부분의 경우 비정상적으로 단백질이 축적되어 뇌에
          비가역적인 손상이 발생하며, 이로 인해 인지기능 저하, 감각 손실, 근육
          위축, 운동기능 저하와
          <br className="pcOnly" />
          같은 다양한 증세가 나타납니다.
          <br />
          <br />
          CLEVERcns는 퇴행성신경질환에 대한 의뢰사의 요구를 충족시키기 위해
          다양하고 전문적인 평가서비스를 제공하고 있습니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
    "acute-brain-injury": {
      title: (
        <>
          급성 세포 손상 뇌질환 <br className="mbOnly" /> (Acute Brain Injury)
        </>
      ),
      description: (
        <>
          급성 세포 손상 뇌질환은 갑작스럽게 발생한 세포 손상이 뇌 기능에 심각한
          영향을 미치는 것을 말합니다. 대표적 질환으로는 뇌졸중(Stroke),{" "}
          <br className="pcOnly" />
          외상성뇌손상(Traumatic Brain Injury, TBI), 뇌염(Encephalitis),
          통증(Chronic Pain), 뇌혈관질환(Cerebrovascular Diseases) 등을
          포함합니다.
          <br />
          이러한 질환들은 뇌 혈액 공급의 갑작스러운 이상,{" "}
          <br className="mbOnly" />
          외부 충격, 뇌 조직의 염증, 만성적인 통증, 뇌 혈관의 이상 등 다양한
          원인에 기인하며 각각의 특성에
          <br className="pcOnly" />
          따라 다양한 증상을 나타냅니다.
          <br />
           <br />
          각각의 질환에는 특화된 이해와 진단, 치료 전략이 필요합니다.
          CLEVERcns는 최신 연구와 전문가의 지식을 기반으로 이러한 급성 세포 손상
          뇌질환에 <br className="pcOnly" />
          대한 종합적이고 효과적인 유효성평가를 지원합니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
    others: {
      title: "기타 (Others)",
      description: (
        <>
          신경계 신생물과 난청은 ‘신경계 이상증상’을 나타내는게 특징입니다. 이는
          중추신경계나 감각기관에서 발생하는 이상증상을 포함하는 범주로 설명할
          수 있습니다. <br />
          ‘신경계 이상증상’은 중추신경계의 문제로 인해 발생하는 다양한 증상을
          다루며, 여기에는 신경계 신생물(Neurological Neoplasm), 난청(Hearing
          Loss) 등이
          <br />
          포함될 수 있습니다. 이러한 질환들은 각각의 특성에 따라 다양한 증상을
          유발하며, 종양의 위치, 크기, 성장 속도, 난청 정도 등이 다양하게 나타날
          수 있습니다.
          <br />
          <br />
          소개된 주요 신경-정신질환 외에도 매우 다양한 중추신경계 질환들이
          있습니다. CLEVERcns는 다양한 신경-정신질환의 동물모델을 확립하고{" "}
          <br />
          유효성을 평가할 수 있는 인프라가 구축되어 있습니다.
          <br />
          또한, 서울대병원 임상 전문 자문단을 구성하여 기타 카테고리에 속하는
          질환들의 자문 서비스와 정밀한 유효성평가를 지원하고 있습니다.
          <br />
          <br />
          평가가 필요한 질환과 유효성평가법에 대한 문의를 기다립니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
  };

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation
          category={"신경-정신질환"}
          ImgBanner={renderImgType(pathName)}
        />

        <div className="tab inner">
          <layout.Tab tabIndex={pathName} tabType={"disease"} />
        </div>

        <section className="textSection">
          <div className="inner">
            <p className="title">{data[pathName].title}</p>
            <p className="description">{data[pathName].description}</p>
          </div>
        </section>

        <section className="diseaseSection">
          <div className="inner">
            <ul>
              {listData.list?.map((item, index) => {
                return (
                  <li className="diseaseList" key={"diseaseList" + index}>
                    <DiseaseList data={item} />
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default DiseasePage;
