import Styles from "./styles";
import { common, ui } from "components";
import { useNotice } from "hooks";
import { useNavigate } from "react-router-dom";

// img
import IconDownload from "resources/image/icon/icon_download.svg";

function NoticeDetailPage() {
  const navigate = useNavigate();

  const supportInfo = useNotice({
    noticeDetail: true,
  });
  const data = supportInfo.getNoticeDetail.data || [];
  const fileData = data.file_list || {};

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="section">
          <div className="inner">
            <div className="titleWrap">
              <div className="type">공지사항</div>

              <p className="title">{data.title}</p>

              <p className="date">{data.created_format}</p>
            </div>

            {fileData.length > 0 && (
              <ul className="fileListWrap">
                {fileData.map((item, index) => {
                  return (
                    <li className="fileList" key={"fileList" + index}>
                      <a href={item.file_url} download={item.file_name}>
                        <div className="icon">
                          <img src={IconDownload} alt="IconDownload" />
                        </div>
                        <p className="fileName">{item.file_name}</p>
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}

            <div className="editorWrap">
              <div
                className="editor ck-content"
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>
            </div>

            <div className="listButton">
              <ui.button.BasicButton
                buttonText={"목록으로"}
                onClick={() => {
                  window.location.href = "/notice";
                }}
              />
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default NoticeDetailPage;
