import Styles from "./styles";

// img
import ImgVideo01 from "resources/image/service/img_validity_video01.jpg";
import ImgVideo01Mb from "resources/image/service/img_validity_video01_mb.jpg";
import ImgVideo02 from "resources/image/service/img_validity_video02.jpg";
import ImgVideo02Mb from "resources/image/service/img_validity_video02_mb.jpg";
import ImgVideo03 from "resources/image/service/img_validity_video03.jpg";
import ImgVideo04 from "resources/image/service/img_validity_video04.jpg";
import ImgVideo05 from "resources/image/service/img_validity_video05.jpg";

function Video() {
  const analyticalList = [
    {
      img: ImgVideo03,
      text: "신약후보물질의 전신 약동학 분석",
    },
    {
      img: ImgVideo04,
      text: "뇌영역별 약동학 영상화 및 분포 정량분석",
    },
    {
      img: ImgVideo05,
      text: "뇌영역별 바이오마커 평가",
    },
  ];

  return (
    <Styles.Container>
      <div className="inner">
        <div className="imgContainer">
          <div className="imgWrap">
            <p className="title">영상평가의 특징</p>

            <div className="img">
              <img className="pcOnly" src={ImgVideo01} alt="ImgVideo01" />
              <img className="mbOnly" src={ImgVideo01Mb} alt="ImgVideo01" />
            </div>
          </div>

          <div className="imgWrap">
            <p className="title">영상평가의 과정</p>

            <div className="img">
              <img className="pcOnly" src={ImgVideo02} alt="ImgVideo02" />
              <img className="mbOnly" src={ImgVideo02Mb} alt="ImgVideo02" />
            </div>
          </div>
        </div>

        <h3>영상평가 분석법</h3>

        <ul className="analyticalWrap">
          {analyticalList.map((item, index) => {
            return (
              <li className="analyticalList" key={"analyticalList" + index}>
                <div className="img">
                  <img src={item.img} alt="analyticalImg" />
                </div>
                <p className="title">{item.text}</p>
              </li>
            );
          })}
        </ul>

        <p className="bottomText">이미지 출처: 공동연구기관 몰림</p>
      </div>
    </Styles.Container>
  );
}

export default Video;
