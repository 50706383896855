import { api } from "service";
import { useQuery } from "react-query";

export default function useBoard({ boardList }) {


  const getBoardList = useQuery(["boardList", boardList], () =>
    api.board.getBoard(boardList), {
      refetchOnWindowFocus: false,
      enabled: !!boardList?.category_info_id
    }
  );

  
  return { getBoardList }
}