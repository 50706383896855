import { useQuery } from "react-query";
import { api } from "service";

export default function useSupport() {

  const getSupport = useQuery(["support"], () =>
    api.support.getSupport(), {
      refetchOnWindowFocus: false,
    }
  );

  return { getSupport }
}