import { api } from "service";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

export default function useNotice({
  noticeList,
  noticeDetail,
  noticeMain,
}) {
  const { id } = useParams();


  const getNoticeList = useQuery(["noticeList", noticeList], () =>
    api.notice.getNoticeList(noticeList), {
      refetchOnWindowFocus: false,
      enabled: !!noticeList?.page
    }
  );
  const getNoticeDetail = useQuery(["noticeDetail", id], () => 
    api.notice.getNoticeDetail(id), {
      refetchOnWindowFocus: false,
      enabled: !!noticeDetail
    }
  );
  const getNoticeMain = useQuery(["noticeMain"], () => 
    api.notice.getNoticeMain(), {
      refetchOnWindowFocus: false, 
      enabled: !!noticeMain
    }
  );

  
  return {
    getNoticeList,
    getNoticeDetail,
    getNoticeMain,
  }
}