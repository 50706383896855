import Styles from "./styles";

function BasicTextarea({ data, onChange }) {
  const { name, value, placeholder, errorText } = data;

  return (
    <Styles.Container>
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      ></textarea>

      {errorText && <p className="errorText">{errorText}</p>}
    </Styles.Container>
  );
}

export default BasicTextarea;
