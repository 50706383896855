import Styles from "./styles";
import { common, layout } from "components";
import ScrollContainer from "react-indiana-drag-scroll";
import { useMediaQuery } from "react-responsive";

// img
import ImgBanner from "resources/image/service/img_overview_banner.jpg";
import ImgBannerMb from "resources/image/service/img_overview_banner_mb.jpg";
import ImgOverview01 from "resources/image/service/img_overview01.jpg";
import ImgOverview01Mb from "resources/image/service/img_overview01_mb.jpg";
import ImgOverview02 from "resources/image/service/img_overview02.jpg";
import ImgOverview02Mb from "resources/image/service/img_overview02_mb.jpg";

function OverviewPage() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const serviceList = [
    {
      title: (
        <>
          <i>In vitro</i> 평가 서비스
        </>
      ),
      subText: (
        <>
          Primary culture <br />/ Adult brain cell isolation / Cell line
        </>
      ),
      list: [
        "- 신경세포 독성평가",
        "- 아교세포 활성 평가",
        "- 포식작용 평가",
        "- 신경가소성 평가",
        "- 세포 소기관 기능평가",
      ],
    },
    {
      title: (
        <>
          <i>In vivo</i> 평가 서비스
        </>
      ),
      list: ["- 행동 평가", "- 영상 평가", "- 약리 평가", "- 영장류 평가"],
    },
    {
      title: (
        <>
          <i>Ex vivo</i> 평가 서비스
        </>
      ),
      list: [
        "- 병리 평가 (동물모델, 환자 뇌조직)",
        "- CSF 평가",
        "- 약리 평가",
        "- 전기생리 평가",
        "- 면역 평가",
      ],
    },
    {
      title: "임상자문 서비스",
      list: ["- 질환별 임상의의 자문", "- 임상약리 임상의의 임상시험 자문"],
    },
  ];

  const circleList = [
    {
      title: "동물실",
      description: (
        <>
          AAALAC 인증 <br />
          영장류 센터
          <br />
          GLP 기관
        </>
      ),
    },
    {
      title: "동물행동분석",
      description: (
        <>
          인지 / 학습 평가
          <br />
          감각 / 운동 평가
          <br />
          감정 / 사회성 평가
        </>
      ),
    },
    {
      title: "핵의학영상",
      description: (
        <>
          RI 합성
          <br />
          PK / PD 평가
          <br />
          바이오마커 평가
        </>
      ),
    },
    {
      title: "뇌은행",
      description: (
        <>
          환자 뇌조직
          <br />
          환자 유래
          <br />
          신경전구세포
        </>
      ),
    },
    {
      title: "임상자문",
      description: (
        <>
          임상약리
          <br />
          신경-정신질환 전문의
        </>
      ),
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation
          category={"서비스"}
          ImgBanner={isMobile ? ImgBannerMb : ImgBanner}
        />

        <section className="overviewSection">
          <ScrollContainer>
            <div className="img">
              <img
                src={isMobile ? ImgOverview01Mb : ImgOverview01}
                alt="ImgOverview01"
              />
            </div>
          </ScrollContainer>
        </section>

        <section className="serviceSection">
          <div className="inner">
            <h3>유효성평가 서비스</h3>

            <ul className="serviceWrap">
              {serviceList.map((item, index) => {
                return (
                  <li className="serviceList" key={"serviceList" + index}>
                    <div className="number">{"0" + (index + 1)}</div>
                    <div className="textWrap">
                      <p className="title">{item.title}</p>
                      {item.subText && (
                        <p className="subText">{item.subText}</p>
                      )}

                      <ul className="listWrap">
                        {item.list.map((current, idx) => {
                          return (
                            <li className="list" key={"list" + idx}>
                              {current}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>

            <ul className="circleWrap">
              {circleList.map((item, index) => {
                return (
                  <li className="circleList" key={"circleList" + index}>
                    <p className="title">{item.title}</p>
                    <p className="description">{item.description}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="cooperationSection">
          <div className="inner">
            <h3>
              협력 구조 <span>신경-정신질환 유효성평가센터</span>
            </h3>

            <ScrollContainer>
              <div className="img">
                <img
                  src={isMobile ? ImgOverview02Mb : ImgOverview02}
                  alt="ImgOverview02"
                />
              </div>
            </ScrollContainer>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default OverviewPage;
