import Styles from "./styles";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";

function Navigation({ category, ImgBanner }) {
  const location = useLocation();

  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const menu = [
    {
      text: "CLEVERcns",
      twoDepth: [
        {
          text: "소개",
          url: "introduction",
        },
        {
          text: "연혁",
          url: "history",
        },
        {
          text: "조직도",
          url: "organization-chart",
        },
      ],
    },
    {
      text: "신경-정신질환",
      twoDepth: [
        {
          text: "신경발달질환",
          url: "neurodevelopmental",
        },
        {
          text: "정신질환",
          url: "psychiatric",
        },
        {
          text: "퇴행성질환",
          url: "neurodegenerative",
        },
        {
          text: "급성 세포 손상 뇌질환",
          url: "acute-brain-injury",
        },
        {
          text: "기타",
          url: "others",
        },
      ],
    },
    {
      text: "서비스",
      twoDepth: [
        {
          text: "개요",
          url: "overview",
        },
        {
          text: "유효성평가 서비스",
          url: "validity/in-vitro",
          threeDepth: [
            {
              text: (
                <>
                  <i>In vitro</i> 평가
                </>
              ),
            },
            {
              text: "행동평가",
            },
            {
              text: "영상평가",
            },
            {
              text: "조직평가",
            },
            {
              text: "바이오뱅크",
            },
            {
              text: "BBB 투과도 평가",
            },
          ],
        },
        {
          text: "임상시험 자문 서비스",
          url: "advice",
        },
        {
          text: "서비스 절차",
          url: "step",
        },
      ],
    },
    {
      text: "센터소식",
      twoDepth: [
        {
          text: "공지사항",
          url: "notice",
        },
        {
          text: "홍보자료",
          url: "promotion",
        },
      ],
    },
    {
      text: "문의하기",
      twoDepth: [
        {
          text: "의뢰서 작성",
          url: "request",
        },
        {
          text: "Contact us",
          url: "contact",
        },
      ],
    },
  ];

  const idx = menu.findIndex((v) => v.text === category);
  const pageName = menu[idx].twoDepth.find(
    (v) =>
      v.url.split("/")[0] ===
      (category === "신경-정신질환"
        ? location.pathname.split("/")[2]
        : location.pathname.split("/")[1])
  ).text;

  useEffect(() => {
    setIsOpen(false);
  }, [pageName]);

  useEffect(() => {
    // 특정 영역 외 클릭 시 발생하는 이벤트
    function handleFocus(e) {
      if (listRef.current && !listRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }

    // 이벤트 리스너에 handleFocus 함수 등록
    document.addEventListener("mouseup", handleFocus);
    return () => {
      document.removeEventListener("mouseup", handleFocus);
    };
  }, [listRef]);

  return (
    <>
      <Styles.Container>
        <div className="inner">
          <h2>{pageName}</h2>
          <ul className="navigation">
            <li>HOME</li>
            <li>{category}</li>
            <li ref={listRef}>
              <div className="pageName" onClick={() => setIsOpen(!isOpen)}>
                <p>{pageName}</p>
                <div className={"icon" + (isOpen ? " on" : "")}>
                  <img src={IconArrowBottom} alt="IconArrowBottom" />
                </div>
              </div>

              {isOpen && (
                <ul className="selectWrap">
                  {menu[idx].twoDepth.map((item, index) => {
                    return (
                      <li className={"menuList"} key={"menuList" + index}>
                        <Link
                          to={
                            category === "신경-정신질환"
                              ? `/disease/${item.url}`
                              : `/${item.url}`
                          }
                          className={pageName === item.text ? " on" : ""}
                        >
                          {item.text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          </ul>
        </div>
      </Styles.Container>

      <Styles.BannerSection className="bannerSection">
        <div className="inner">
          <div className="banner">
            <img src={ImgBanner} alt="ImgBanner" />
          </div>
        </div>
      </Styles.BannerSection>
    </>
  );
}

export default Navigation;
