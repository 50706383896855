import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 190px;

  & textarea {
    display: block;
    width: 100%;
    height: 100%;
    padding: 20px;
    font-size: 18px;
    font-weight: 400;
    background-color: var(--gray04);

    &::placeholder {
      color: var(--gray02);
    }
  }

  & .errorText {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    font-size: 18px;
    font-weight: 400;
    color: var(--red);
  }

  @media (max-width: 1600px) {
    height: calc(100vw * (190 / 1600));

    & textarea {
      padding: calc(100vw * (20 / 1600));
      font-size: calc(100vw * (18 / 1600));
    }

    & .errorText {
      top: calc(100% + calc(100vw * (10 / 1600)));
      font-size: calc(100vw * (18 / 1600));
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (200 / 375));

    & textarea {
      padding: calc(100vw * (16 / 375)) calc(100vw * (20 / 375));
      font-size: calc(100vw * (16 / 375));
    }

    & .errorText {
      top: calc(100% + calc(100vw * (5 / 375)));
      font-size: calc(100vw * (16 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
