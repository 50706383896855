import styled from "styled-components";

const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 18px;
  background-color: #fff;
  z-index: 5;

  &.on {
    position: fixed;
  }

  & .top {
    & .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 90px;

      & .logo {
        width: 210px;
        height: 50px;
      }

      & .menuListWrap {
        display: flex;
        gap: 70px;
        font-weight: 600;

        & .menuList {
          position: relative;

          &.on {
            color: var(--blue01);
            font-weight: bold;

            &::after {
              position: absolute;
              bottom: -30px;
              left: 0;
              width: 100%;
              height: 3px;
              background-color: var(--blue01);
              content: "";
              z-index: 5;
            }
          }

          & a {
            padding: 5px 0;
          }
        }
      }

      & .menuButton {
        display: none;
      }
    }
  }

  & nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-top: 1px solid var(--gray02);
    background-color: #fff;
    z-index: 4;

    & .twoDepthListWrap {
      display: flex;
      justify-content: center;
      padding: 30px 0;

      & .twoDepth {
        width: 250px;
        font-weight: bold;
        text-align: center;
      }
    }

    & .threeDepthListWrap {
      margin-top: 18px;
      padding: 0 62px;
      font-size: 16px;
      font-weight: 400;

      & .threeDepthList:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  @media (max-width: 1600px) {
    font-size: calc(100vw * (18 / 1600));

    & .top {
      & .inner {
        height: calc(100vw * (90 / 1600));

        & .logo {
          width: calc(100vw * (210 / 1600));
          height: calc(100vw * (50 / 1600));
        }

        & .menuListWrap {
          gap: calc(100vw * (70 / 1600));

          & .menuList {
            &.on {
              &::after {
                bottom: calc(100vw * (-30 / 1600));
                height: calc(100vw * (3 / 1600));
              }
            }

            & a {
              padding: calc(100vw * (5 / 1600)) 0;
            }
          }
        }
      }
    }

    & nav {
      & .twoDepthListWrap {
        padding: calc(100vw * (30 / 1600)) 0;

        & .twoDepth {
          width: calc(100vw * (250 / 1600));
        }
      }

      & .threeDepthListWrap {
        margin-top: calc(100vw * (18 / 1600));
        padding: 0 calc(100vw * (62 / 1600));
        font-size: calc(100vw * (16 / 1600));

        & .threeDepthList:not(:last-child) {
          margin-bottom: calc(100vw * (15 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    font-size: calc(100vw * (18 / 375));

    & .top {
      & .inner {
        height: calc(100vw * (80 / 375));

        & .logo {
          width: calc(100vw * (151 / 375));
          height: calc(100vw * (36 / 375));
        }

        & .menuListWrap {
          display: none;
        }

        & .menuButton {
          display: block;
          width: calc(100vw * (36 / 375));
          height: calc(100vw * (36 / 375));
        }
      }
    }
  }
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  padding: calc(100vw * (130 / 375)) 0 calc(100vw * (80 / 375));
  background-color: #fff;
  overflow: auto;
  z-index: 4;

  @media (max-width: 768px) {
    display: block;

    & .menuList {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: calc(100vw * (60 / 375));
      }

      & .depth01 {
        padding-bottom: calc(100vw * (10 / 375));
        font-size: calc(100vw * (20 / 375));
        font-weight: 600;
        color: var(--blue01);
        border-bottom: calc(100vw * (3 / 375)) solid var(--blue01);
      }

      & .depth02ListWrap {
        width: calc(100vw * (188 / 375));

        & .depth02List:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 375));
        }

        & .depth02 {
          font-weight: bold;
        }

        & .depth03ListWrap {
          margin-top: calc(100vw * (20 / 375));

          & .depth03List:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 375));
          }

          & .depth03 {
            font-weight: 400;
          }
        }
      }
    }
  }
`;

export { Container, Menu };

export default {
  Container,
  Menu,
};
