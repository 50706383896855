import Styles from "./styles";
import { useState, useEffect, useRef } from "react";

// img
import Logo from "resources/image/icon/logo_gray.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top.svg";

function Footer() {
  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // 특정 영역 외 클릭 시 발생하는 이벤트
    function handleFocus(e) {
      if (listRef.current && !listRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }

    // 이벤트 리스너에 handleFocus 함수 등록
    document.addEventListener("mouseup", handleFocus);
    return () => {
      document.removeEventListener("mouseup", handleFocus);
    };
  }, [listRef]);

  const info = [
    "서울대학교병원 신경-정신질환 유효성평가센터",
    "서울특별시 종로구 대학로 101 (연건동 28)",
    "02-740-8565",
    "clevercns@snu.ac.kr",
  ];

  const site = [
    {
      text: "서울대학교병원",
      url: "https://www.snuh.org/",
    },
    {
      text: "서울대학교병원 \n의생명연구원",
      url: "http://bri.snuh.org/pub/_/singlecont/view.do",
    },
    {
      text: "서울대학교병원 \n혁신의료기술연구소",
      url: "https://simtri.snuh.org/",
    },
    {
      text: "서울대학교병원 \n치매 뇌은행",
      url: "http://brainbank.snuh.org/",
    },
    {
      text: "서울대학교병원 의과대학",
      url: "https://medicine.snu.ac.kr/",
    },
    {
      text: "서울대학교병원 의과대학 \n중앙연구지원실",
      url: "https://medcorelab.snu.ac.kr/",
    },
  ];

  return (
    <Styles.Container>
      <div className="inner">
        <div className="top">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          <div className="familySiteWrap" ref={listRef}>
            <button
              type="button"
              className="familySiteButton"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Family site{" "}
              <span className={"icon" + (isOpen ? " on" : "")}>
                <img src={IconArrowTop} alt="IconArrowTop" />
              </span>
            </button>

            {isOpen && (
              <ul className="siteListWrap">
                {site.map((item, index) => {
                  return (
                    <li className="siteList" key={"siteList" + index}>
                      <a target="_blank" href={item.url}>
                        {item.text}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>

        <ul className="infoWrap">
          {info.map((item, index) => {
            return <li key={"list" + index}>{item}</li>;
          })}
        </ul>

        <p className="copyright">©2024. CLEVERcns. all rights reserved.</p>
      </div>
    </Styles.Container>
  );
}

export default Footer;
