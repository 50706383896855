import { useQuery } from "react-query";
import { api } from "service";

export default function usePolicy() {

  const getPolicy = useQuery(["policy"], () =>
    api.policy.getPolicy(), {
      refetchOnWindowFocus: false,
    }
  );

  return { getPolicy }
}