import product from "./product";
import dummy from "./dummy";
import notice from "./notice";
import banner from "./banner";
import promotion from "./promotion";
import history from "./history";
import policy from "./policy";
import inquiry from "./inquiry";
import support from "./support";
import board from "./board";
import popup from "./popup";
import upload from "./upload";

export default {
  product,
  dummy,
  notice,
  banner,
  promotion,
  history,
  policy,
  inquiry,
  support,
  board,
  popup,
  upload,
};

export {
  product,
  dummy,
  notice,
  banner,
  promotion,
  history,
  policy,
  inquiry,
  support,
  board,
  popup,
  upload,
};
