import styled from "styled-components";

const Container = styled.main`
  padding: 50px 0 130px;

  & .bannerSection {
    margin-bottom: 50px;
  }

  & .promotionSection {
    & .total {
      margin-bottom: 20px;
      font-weight: 600;

      & span {
        color: var(--blue01);
      }
    }

    & .emptyText {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;
      font-weight: 600;
      color: var(--gray02);
    }

    & .listWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 45px;

      & .promotionList {
        width: 470px;
      }

      & .img {
        height: 304px;
        margin-bottom: 20px;
      }

      & .title {
        display: -webkit-box;
        margin-bottom: 14px;
        line-height: 1.4;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      & .date {
        font-weight: 400;
        color: var(--gray02);
      }
    }

    & .pagination {
      margin-top: 50px;
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (130 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .promotionSection {
      & .total {
        margin-bottom: calc(100vw * (20 / 1600));
      }

      & .emptyText {
        height: calc(100vw * (300 / 1600));
      }

      & .listWrap {
        gap: calc(100vw * (45 / 1600));

        & .promotionList {
          width: calc(100vw * (470 / 1600));
        }

        & .img {
          height: calc(100vw * (304 / 1600));
          margin-bottom: calc(100vw * (20 / 1600));
        }

        & .title {
          margin-bottom: calc(100vw * (14 / 1600));
        }
      }

      & .pagination {
        margin-top: calc(100vw * (50 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (80 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .promotionSection {
      & .total {
        margin-bottom: calc(100vw * (10 / 375));
      }

      & .emptyText {
        height: calc(100vw * (270 / 375));
      }

      & .listWrap {
        flex-direction: column;
        gap: calc(100vw * (30 / 375));

        & .promotionList {
          width: 100%;
        }

        & .img {
          height: calc(100vw * (222 / 375));
          margin-bottom: calc(100vw * (10 / 375));
        }

        & .title {
          margin-bottom: calc(100vw * (10 / 375));
        }
      }

      & .pagination {
        margin-top: calc(100vw * (40 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
