import Styles from "./styles";

function BasicInput({ data, onChange }) {
  const { labelText, type, name, value, placeholder, errorText, disabled } = data;

  return (
    <Styles.Container>
      {labelText && <label htmlFor={name}>{labelText}</label>}

      <div className="inputWrap">
        <input
          type={type ? type : "text"}
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />

        {errorText && <p className="errorText">{errorText}</p>}
      </div>
    </Styles.Container>
  );
}

export default BasicInput;
