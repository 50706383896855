import styled from "styled-components";

// img
import BgLogo from "resources/image/bg_logo.svg";

const Container = styled.main`
  padding: 50px 0 270px;
  background: url(${BgLogo}) no-repeat center calc(100% - 130px) / 96px 91px;

  & .bannerSection {
    margin-bottom: 50px;
  }

  & .historySection {
    & .historyList {
      display: flex;
      align-items: center;
      gap: 80px;
      padding: 30px 0;
      border-bottom: 1px solid var(--gray02);

      &:first-child {
        border-top: 1px solid var(--gray02);
      }

      & .year {
        width: 150px;
        font-size: 56px;
        color: var(--main);
      }

      & .list {
        line-height: 1.4;
        white-space: pre-wrap;

        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (270 / 1600));
    background: url(${BgLogo}) no-repeat center
      calc(100% - calc(100vw * (130 / 1600))) / calc(100vw * (96 / 1600))
      calc(100vw * (91 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .historySection {
      & .historyList {
        gap: calc(100vw * (80 / 1600));
        padding: calc(100vw * (30 / 1600)) 0;

        & .year {
          width: calc(100vw * (150 / 1600));
          font-size: calc(100vw * (56 / 1600));
        }

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (25 / 1600));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (204 / 375));
    background: url(${BgLogo}) no-repeat center
      calc(100% - calc(100vw * (80 / 375))) / calc(100vw * (78 / 375))
      calc(100vw * (74 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .historySection {
      & .historyList {
        flex-direction: column;
        align-items: flex-start;
        gap: calc(100vw * (20 / 375));
        padding: calc(100vw * (30 / 375)) 0;

        & .year {
          width: 100%;
          font-size: calc(100vw * (34 / 375));
        }

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 1600));
          }
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
