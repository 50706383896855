import Styles from "./styles";

function Behavior() {
  const table = [
    {
      title: "기전",
      contents: ["행동평가"],
    },
    {
      title: "학습/기억 테스트",
      contents: [
        "Morris Water Maze",
        "Active / Passive Avoidance",
        "T-maze",
        "Y-maze",
        "Zero-maze",
        "Novel Objects",
        "Cued & Contextual Fear \nConditioning",
      ],
    },
    {
      title: "감각/운동 테스트",
      contents: [
        "Rotor-Rod Test",
        "Gait Analysis",
        "Treadmill",
        "Pole test",
        "Wire Hang Test",
        "Open Field Test",
        "Activity Chamber",
        "Olfactory Test",
        "Hearing Test",
      ],
    },
    {
      title: "감정/사회성 테스트",
      contents: [
        "Forced Swimming",
        "Tail Suspension",
        "Three Chamber",
        "Ultrasonic Vocalization",
        "Elevated Plus Maze",
        "Startle Response",
        "Marble burying",
        "Operant Conditioning \n(Solid/liquid)",
      ],
    },
  ];

  return (
    <Styles.Container>
      <div className="inner">
        <p className="title">질환별 행동평가 서비스</p>

        <ul className="table">
          {table.map((item, index) => {
            return (
              <li className="tableList" key={"tableList" + index}>
                <div className="th">{item.title}</div>

                <div className="td">
                  {item.contents.map((current, idx) => {
                    return (
                      <p className="contents" key={"contents" + idx}>
                        {current}
                      </p>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default Behavior;
