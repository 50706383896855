import Styles from "./styles";
import { useNavigate } from "react-router-dom";

// img
import IconWrite from "resources/image/icon/icon_write.svg";
import IconHeadphone from "resources/image/icon/icon_headphone.svg";

function TopButton() {
  const navigate = useNavigate();

  const buttonList = [
    {
      icon: IconWrite,
      text: "의뢰서 작성",
      onClick: () => {
        navigate("/request");
      },
    },
    {
      icon: IconHeadphone,
      text: "Contact us",
      onClick: () => {
        navigate("/contact");
      },
    },
    {
      text: "TOP",
      onClick: () => {
        window.scrollTo(0, 0);
      },
    },
  ];

  return (
    <Styles.Container>
      <ul>
        {buttonList.map((item, index) => {
          return (
            <li className="buttonList" key={"buttonList" + index}>
              <button type="button" onClick={item.onClick}>
                {item.icon && (
                  <span className="icon">
                    <img src={item.icon} alt="buttonIcon" />
                  </span>
                )}
                <span className="text">{item.text}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </Styles.Container>
  );
}

export default TopButton;
