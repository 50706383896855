import { api } from "service";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

export default function usePromotion({
  promotionList,
  promotionDetail,
}) {
  const { id } = useParams();


  const getPromotionList = useQuery(["promotionList", promotionList], () =>
    api.promotion.getList(promotionList), {
      refetchOnWindowFocus: false,
      enabled: !!promotionList
    }
  );
  const getPromotionDetail = useQuery(["promotionDetail", id], () => 
    api.promotion.getDetail(id), {
      refetchOnWindowFocus: false,
      enabled: !!promotionDetail
    }
  );

  
  return {
    getPromotionList,
    getPromotionDetail,
  }
}