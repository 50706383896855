import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  bottom: 60px;
  right: 50px;
  width: 100px;
  height: 330px;
  padding-top: 30px;
  background-image: linear-gradient(
    to bottom,
    var(--gray04) 230px,
    var(--main) 100px
  );

  box-shadow: 8px 8px 20px 0px rgba(36, 52, 104, 0.15);
  border-radius: 500px;
  overflow: hidden;
  z-index: 3;

  & button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    color: var(--main);

    & .icon {
      display: inline-block;
      width: 33px;
      height: 33px;
      margin-bottom: 10px;
    }

    & .text {
      font-size: 16px;
      font-weight: 600;
    }
  }

  & .buttonList:last-child button {
    color: #fff;
  }

  @media (max-width: 1600px) {
    bottom: calc(100vw * (60 / 1600));
    right: calc(100vw * (50 / 1600));
    width: calc(100vw * (100 / 1600));
    height: calc(100vw * (330 / 1600));
    padding-top: calc(100vw * (30 / 1600));
    background-image: linear-gradient(
      to bottom,
      var(--gray04) calc(100vw * (230 / 1600)),
      var(--main) calc(100vw * (100 / 1600))
    );

    box-shadow: calc(100vw * (8 / 1600)) calc(100vw * (8 / 1600))
      calc(100vw * (20 / 1600)) 0 rgba(36, 52, 104, 0.15);
    border-radius: calc(100vw * (500 / 1600));

    & button {
      width: calc(100vw * (100 / 1600));
      height: calc(100vw * (100 / 1600));

      & .icon {
        width: calc(100vw * (33 / 1600));
        height: calc(100vw * (33 / 1600));
        margin-bottom: calc(100vw * (10 / 1600));
      }

      & .text {
        font-size: calc(100vw * (16 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    bottom: calc(100vw * (29 / 375));
    right: calc(100vw * (10 / 375));
    width: calc(100vw * (66 / 375));
    height: calc(100vw * (200 / 375));
    padding-top: calc(100vw * (20 / 375));
    background-image: linear-gradient(
      to bottom,
      var(--gray04) calc(100vw * (140 / 375)),
      var(--main) calc(100vw * (60 / 375))
    );

    box-shadow: calc(100vw * (8 / 375)) calc(100vw * (8 / 375))
      calc(100vw * (20 / 375)) 0 rgba(36, 52, 104, 0.15);
    border-radius: calc(100vw * (500 / 375));

    & button {
      width: calc(100vw * (66 / 375));
      height: calc(100vw * (60 / 375));

      & .icon {
        width: calc(100vw * (22 / 375));
        height: calc(100vw * (22 / 375));
        margin-bottom: calc(100vw * (5 / 375));
      }

      & .text {
        font-size: calc(100vw * (12 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
