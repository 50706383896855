import { api } from "service"

export default function useUpload() {

  const postFile = async data => {
  	const formData = new FormData();
    formData.append("file", data);
    const result = await api.upload.postFile(formData);
    if (result.success) return result.data;
  }
  
  return { postFile }
}