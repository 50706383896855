import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 44px 16px;
    background-color: #fff;
    z-index: 1;

    & .img {
      width: 484px;
      height: 601px;
      overflow: scroll;
      height: max-content;
      max-height: 700px;
    }

    & .closeButton,
    .todayCloseButton {
      position: absolute;
    }

    & .closeButton {
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
    }

    & .todayCloseButton {
      bottom: 10px;
      right: 16px;
      gap: 8px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;

      & .icon {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.3);
    backdrop-filter: blur(25px);
  }

  @media (max-width: 1600px) {
    & .modalInner {
      padding: calc(100vw * (44 / 1600)) calc(100vw * (16 / 1600));

      & .img {
        width: calc(100vw * (484 / 1600));
        height: calc(100vw * (601 / 1600));
        overflow: scroll;
        min-height: min-content;
        height: max-content;
        max-height: calc(100vw * (630 / 1600));
      }

      & .closeButton {
        top: calc(100vw * (10 / 1600));
        right: calc(100vw * (10 / 1600));
        width: calc(100vw * (24 / 1600));
        height: calc(100vw * (24 / 1600));
      }

      & .todayCloseButton {
        bottom: calc(100vw * (10 / 1600));
        right: calc(100vw * (16 / 1600));
        gap: calc(100vw * (8 / 1600));
        font-size: calc(100vw * (16 / 1600));

        & .icon {
          width: calc(100vw * (24 / 1600));
          height: calc(100vw * (24 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .modalInner {
      padding: calc(100vw * (30 / 375)) calc(100vw * (12 / 375));

      & .img {
        width: calc(100vw * (296 / 375));
        height: calc(100vw * (367 / 375));
        overflow: scroll;
        min-height: min-content;
        height: max-content;
        max-height: calc(100vw * (407 / 375));
      }

      & .closeButton {
        top: calc(100vw * (8 / 375));
        right: calc(100vw * (10 / 375));
        width: calc(100vw * (14 / 375));
        height: calc(100vw * (14 / 375));
      }

      & .todayCloseButton {
        bottom: calc(100vw * (8 / 375));
        right: calc(100vw * (12 / 375));
        gap: calc(100vw * (8 / 375));
        font-size: calc(100vw * (12 / 375));

        & .icon {
          width: calc(100vw * (14 / 375));
          height: calc(100vw * (14 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
