import styled from "styled-components";

// img
import BgLogo from "resources/image/bg_logo.svg";

const Container = styled.main`
  padding: 50px 0 301px;
  background: url(${BgLogo}) no-repeat center calc(100% - 130px) / 96px 91px;

  & .bannerSection {
    margin-bottom: 50px;
  }

  & .stepSection {
    & h3 {
      margin-bottom: 30px;
    }

    & .img {
      height: 696px;
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (301 / 1600));
    background: url(${BgLogo}) no-repeat center
      calc(100% - calc(100vw * (130 / 1600))) / calc(100vw * (96 / 1600))
      calc(100vw * (91 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .stepSection {
      & h3 {
        margin-bottom: calc(100vw * (30 / 1600));
      }

      & .img {
        height: calc(100vw * (696 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (204 / 375));
    background: url(${BgLogo}) no-repeat center
      calc(100% - calc(100vw * (80 / 375))) / calc(100vw * (78 / 375))
      calc(100vw * (74 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .stepSection {
      & h3 {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        width: calc(100vw * (1370 / 375));
        height: calc(100vw * (594 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
