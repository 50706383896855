import styled from "styled-components";

const Container = styled.section`
  & .title {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 700;
  }

  & .table {
    display: flex;
  }

  & .th {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 60px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: var(--main);
  }

  & .td {
    height: calc(100% - 60px);
    font-size: 16px;
    line-height: 1.5;
    background-color: var(--gray04);
  }

  & .tableList {
    &:first-child {
      & .th,
      .td {
        width: 150px;
      }

      & .td {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
      }
    }

    &:not(:first-child) .td {
      padding: 20px 120px 20px 110px;

      & p {
        position: relative;
        padding-left: 10px;

        &::before {
          position: absolute;
          left: 0;
          content: "-";
        }
      }
    }
  }

  @media (max-width: 1600px) {
    & .title {
      margin-bottom: calc(100vw * (30 / 1600));
      font-size: calc(100vw * (26 / 1600));
    }

    & .th {
      width: calc(100vw * (450 / 1600));
      height: calc(100vw * (60 / 1600));
      font-size: calc(100vw * (18 / 1600));
    }

    & .td {
      height: calc(100% - calc(100vw * (60 / 1600)));
      font-size: calc(100vw * (16 / 1600));
    }

    & .tableList {
      &:first-child {
        & .th,
        .td {
          width: calc(100vw * (150 / 1600));
        }
      }

      &:not(:first-child) .td {
        padding: calc(100vw * (20 / 1600)) calc(100vw * (120 / 1600))
          calc(100vw * (20 / 1600)) calc(100vw * (110 / 1600));

        & p {
          padding-left: calc(100vw * (10 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .title {
      margin-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (20 / 375));
    }

    & .table {
      flex-direction: column;
    }

    & .th {
      width: 100%;
      height: calc(100vw * (60 / 375));
      font-size: calc(100vw * (18 / 375));
    }

    & .td {
      height: calc(100vw * (256 / 375));
      font-size: calc(100vw * (16 / 375));
    }

    & .tableList {
      &:first-child {
        display: none;
      }

      &:not(:first-child) .td {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;

        & p {
          width: fit-content;
          margin: 0 auto;
          padding-left: calc(100vw * (10 / 375));
          text-align: center;
          white-space: pre;
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
