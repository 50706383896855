import styled from "styled-components";

const Container = styled.footer`
  display: flex;
  align-items: center;
  height: 303px;
  font-size: 18px;
  color: var(--gray02);
  background-color: var(--black);

  & .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    & .logo {
      width: 275px;
      height: 60px;
    }

    & .familySiteWrap {
      position: relative;
      width: fit-content;

      & .familySiteButton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 210px;
        height: 60px;
        font-weight: 500;
        color: var(--gray02);
        border: 1px solid var(--gray02);

        & .icon {
          display: inline-block;
          width: 14px;
          height: 14px;

          &.on {
            transform: rotate(180deg);
          }
        }
      }

      & .siteListWrap {
        position: absolute;
        bottom: calc(100% - 1px);
        left: 0;
        width: 100%;
        background-color: var(--black);
        border: 1px solid var(--gray02);

        & .siteList {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          text-align: center;
          white-space: pre-wrap;
          transition: 0.3s;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  & .infoWrap {
    display: flex;
    gap: 30px;
    margin-bottom: 16px;
  }

  @media (max-width: 1600px) {
    height: calc(100vw * (303 / 1600));
    font-size: calc(100vw * (18 / 1600));

    & .top {
      margin-bottom: calc(100vw * (50 / 1600));

      & .logo {
        width: calc(100vw * (275 / 1600));
        height: calc(100vw * (60 / 1600));
      }

      & .familySiteWrap {
        & .familySiteButton {
          gap: calc(100vw * (10 / 1600));
          width: calc(100vw * (210 / 1600));
          height: calc(100vw * (60 / 1600));

          & .icon {
            width: calc(100vw * (14 / 1600));
            height: calc(100vw * (14 / 1600));
          }
        }

        & .siteListWrap {
          & .siteList {
            height: calc(100vw * (60 / 1600));
          }
        }
      }
    }

    & .infoWrap {
      gap: calc(100vw * (30 / 1600));
      margin-bottom: calc(100vw * (16 / 1600));
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (314 / 375));
    font-size: calc(100vw * (16 / 375));

    & .top {
      flex-direction: column;
      margin-bottom: calc(100vw * (30 / 375));

      & .logo {
        width: calc(100vw * (202 / 375));
        height: calc(100vw * (44 / 375));
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .familySiteWrap {
        & .familySiteButton {
          gap: calc(100vw * (10 / 375));
          width: calc(100vw * (170 / 375));
          height: calc(100vw * (50 / 375));

          & .icon {
            width: calc(100vw * (14 / 375));
            height: calc(100vw * (14 / 375));
          }
        }

        & .siteListWrap {
          & .siteList {
            height: calc(100vw * (50 / 375));
          }
        }
      }
    }

    & .infoWrap {
      flex-wrap: wrap;
      gap: calc(100vw * (5 / 375));
      margin-bottom: calc(100vw * (5 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
