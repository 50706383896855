import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { api } from "service";
import { useSupport } from "hooks";
import { common, layout } from "components";

// img
import ImgBanner from "resources/image/clevercns/img_organization_chart_banner.jpg";
import ImgChart01 from "resources/image/clevercns/img_organization_chart01.png";
import ImgChart01Mb from "resources/image/clevercns/img_organization_chart01_mb.png";
import ImgChart02 from "resources/image/clevercns/img_organization_chart02.png";
import ImgChart02Mb from "resources/image/clevercns/img_organization_chart02_mb.png";

function OrganizationChartPage() {
  const supportInfo = useSupport();
  const data = supportInfo.getSupport.data || [];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation category={"CLEVERcns"} ImgBanner={ImgBanner} />

        <section className="organizationChartSection">
          <div className="inner">
            <ScrollContainer>
              <div className="img">
                <img className="pcOnly" src={ImgChart01} alt="ImgChart01" />
                <img className="mbOnly" src={ImgChart01Mb} alt="ImgChart01" />
              </div>
            </ScrollContainer>
          </div>
        </section>

        <section className="operationChartSection">
          <div className="inner">
            <h3>운영 & 자문 위원회</h3>

            <ScrollContainer>
              <div className="img">
                <img className="pcOnly" src={ImgChart02} alt="ImgChart02" />
                <img className="mbOnly" src={ImgChart02Mb} alt="ImgChart02" />
              </div>
            </ScrollContainer>
          </div>
        </section>

        <section className="companySection">
          <div className="inner">
            <h3>협력사</h3>

            <ul>
              {data.map((item, index) => {
                return (
                  <li className="companyList" key={"companyList" + index}>
                    <div className="img">
                      <img src={item.image} alt="companyImg" />
                    </div>

                    <p className="companyName">{item.title}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default OrganizationChartPage;
