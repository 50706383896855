import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";

// img
import ImgBbb01 from "resources/image/service/img_validity_bbb01.jpg";
import ImgBbb02 from "resources/image/service/img_validity_bbb02.jpg";

function Bbb() {
  const list = [
    {
      title: (
        <>
          <i>In vitro</i> BBB 투과도 평가 : Caco-2 세포
        </>
      ),
      description: (
        <>
          <i>In vitro</i> BBB 투과도 평가는 후보물질이 BBB를 얼마나 효과적으로
          통과하는지를 평가하기 위해 Caco-2 세포와 같은{" "}
          <br className="pcOnly" />
          세포 모델을 사용하여 신약 물질의 BBB 투과도를 측정합니다. 이 방법은
          비교적 빠르고 효율적이며, <br />
          신약 물질의 초기 평가에 주로 사용됩니다.
        </>
      ),
    },
    {
      title: (
        <>
          <i>In vitro</i> BBB 투과도 평가 : 인간 유래 iPSC (induced Pluripotent
          Stem Cells)
        </>
      ),
      description: (
        <>
          인간 유래 iPSC를 이용하여 인간의 BBB 구조와 기능을 보다 정확하게
          반영할 수 있습니다. 이는 동물 모델을 <br />
          사용하는 방법에서 발생할 수 있는 종 간 차이를{" "}
          <br className="mbOnly" />
          극복하고, 신약 후보 물질의 BBB 투과도를 보다 정확하게 <br />
          평가할 수 있습니다. 또한, 세포 간 상호작용을 반영하기 위해 BBB를
          구성하는 여러 종류의 세포 <br />
          (iPSC endothelial cell, pericytes, astrocytes)를 이용하여 세포 간의
          상호작용을 반영할 수 있습니다. <br />
          이는 BBB의 복잡한 구조와 기능을 보다 정확하게 모델링
          <br className="mbOnly" />
          하고, 신약 후보물질의 BBB 투과도를 보다 정확하게{" "}
          <br className="pcOnly" />
          평가할 수 있게 합니다.
        </>
      ),
    },
    {
      title: (
        <>
          <i>In vivo</i> BBB 투과도 평가
        </>
      ),
      description: (
        <>
          <i>In vivo</i> BBB 투과도 평가는 동물 모델을 사용하여 실제 생체 내에서
          신약 물질이 BBB를 얼마나 효과적으로 <br />
          통과하는지를 평가합니다. 이 방법은 신약 물질의 BBB 투과도를 보다
          정확하게 평가할 수 있으며, 신약 <br className="mbOnly" />
          물질의 효과와 <br className="pcOnly" />
          안전성을 평가하는 데 중요한 역할을 합니다.
        </>
      ),
    },
  ];

  return (
    <Styles.Container>
      <div className="inner">
        <div className="listContainer">
          <ul>
            {list.map((item, index) => {
              return (
                <li className="list" key={"list" + index}>
                  <p className="number">0{index + 1}</p>
                  <p className="title">{item.title}</p>
                  <p className="description">{item.description}</p>
                </li>
              );
            })}
          </ul>

          <div className="img">
            <img src={ImgBbb01} alt="ImgBbb" />
          </div>
        </div>

        <ScrollContainer>
          <div className="graphImg">
            <img src={ImgBbb02} alt="ImgBbb02" />
          </div>
        </ScrollContainer>
      </div>
    </Styles.Container>
  );
}

export default Bbb;
