import styled from "styled-components";

// img
import ImgInfoSection02 from "resources/image/main/img_info_section02.jpg";
import ImgInfoSection03 from "resources/image/main/img_info_section03.png";
import ImgGridSection01 from "resources/image/main/img_grid_section01.jpg";
import ImgGridSection02 from "resources/image/main/img_grid_section02.jpg";
import ImgContactSection01 from "resources/image/main/img_contact_section01.jpg";
import Logo from "resources/image/icon/logo_gradient.svg";

const Container = styled.main`
  padding-bottom: 130px;

  & .mainBannerSection .img {
    height: 890px;
  }

  & .introSection {
    position: relative;
    padding: 130px 0 190px;

    &::after {
      position: absolute;
      top: -64px;
      left: calc(50% + 196px);
      width: 410px;
      height: 400px;
      background: url(${ImgInfoSection03}) no-repeat center / cover;
      content: "";
      z-index: -1;
    }

    & .inner {
      display: flex;
      justify-content: space-between;
    }

    & .left,
    .right {
      width: 410px;
    }

    & .colorText {
      margin-bottom: 20px;
      font-size: 42px;
      font-weight: 600;
      color: var(--gray02);
    }

    & .title {
      font-size: 26px;
      font-weight: bold;
    }

    & .description {
      margin-top: 40px;
      line-height: 1.4;
    }

    & .center {
      position: relative;
      width: 526px;

      & .img {
        height: 730px;

        &::after {
          position: absolute;
          bottom: -60px;
          left: -190px;
          width: 300px;
          height: 270px;
          background: url(${ImgInfoSection02}) no-repeat center / cover;
          filter: drop-shadow(15px 15px 30px rgba(27, 32, 51, 0.27));
          content: "";
        }
      }
    }

    & .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  & .gridSection {
    & .top,
    .bottom {
      display: flex;
      justify-content: center;
    }

    & .left,
    .right {
      width: 960px;
      height: 620px;
    }

    & .left .wrap {
      width: fit-content;
      margin-left: auto;
    }

    & .title {
      margin-bottom: 20px;
      font-size: 42px;
      font-weight: 600;
    }

    & .link {
      font-size: 26px;
      text-decoration: underline;
      text-underline-offset: 4px;
    }

    & ul {
      margin-top: 40px;
    }

    & .top {
      color: #fff;

      & .left {
        padding: 97px 120px 0 0;
        background: url(${ImgGridSection01}) no-repeat center / cover;
      }

      & .right {
        padding: 97px 0 0 120px;
        background-color: var(--black);

        & .title {
          color: var(--blue01);
        }

        & .service:not(:first-child) {
          margin-top: 30px;
        }
      }

      & ul {
        display: flex;
        gap: 65px;
        font-size: 18px;
        text-align: center;

        & .img {
          width: 56px;
          height: 56px;
          margin: 0 auto 15px;
        }
      }
    }

    & .bottom {
      & .left {
        padding: 174px 120px 0 0;
        background-color: var(--gray04);

        & .title {
          color: var(--gray02);
        }

        & .noticeList {
          &:not(:last-child) {
            margin-bottom: 30px;
          }

          & a {
            display: flex;
            align-items: center;
            gap: 35px;
          }
        }

        & .text {
          width: 503px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        & .date {
          font-weight: 400;
          color: var(--gray02);
        }
      }

      & .right {
        padding: 174px 0 0 120px;
        color: #fff;
        background: url(${ImgGridSection02}) no-repeat center / cover;
      }
    }
  }

  & .promotionSection {
    margin: 80px 0 130px;

    & ul {
      display: flex;
      gap: 45px;
    }

    & .promotionList {
      width: 470px;

      & .img {
        height: 304px;
        margin-bottom: 20px;
      }

      & .description {
        display: -webkit-box;
        margin-bottom: 14px;
        line-height: 1.4;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      & .date {
        font-weight: 400;
        color: var(--gray02);
      }
    }
  }

  & .contactSection {
    position: relative;
    padding-top: 73px;

    &::before {
      position: absolute;
      top: 0;
      right: calc(50% + 568px);
      width: 138px;
      height: 138px;
      background: url(${Logo}) no-repeat center / cover;
      content: "";
    }

    & .inner {
      display: flex;
      align-items: center;
      color: #fff;
      background-color: var(--main);
      border-radius: 50px;
      overflow: hidden;
    }

    & .left {
      width: 650px;
      height: 565px;
      margin-right: 150px;
      border-radius: 50px;
      background: url(${ImgContactSection01}) no-repeat center / cover;
    }

    & .right {
      & .title {
        margin-bottom: 20px;
        font-size: 42px;
        font-weight: 600;
      }

      & .text {
        margin-bottom: 50px;
        font-size: 26px;
        font-weight: bold;
      }

      & a {
        font-size: 26px;
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }

  @media (max-width: 1600px) {
    padding-bottom: calc(100vw * (130 / 1600));

    & .mainBannerSection .img {
      height: calc(100vw * (890 / 1600));
    }

    & .introSection {
      padding: calc(100vw * (130 / 1600)) 0 calc(100vw * (190 / 1600));

      &::after {
        top: calc(100vw * (-64 / 1600));
        left: calc(50% + calc(100vw * (196 / 1600)));
        width: calc(100vw * (410 / 1600));
        height: calc(100vw * (400 / 1600));
      }

      & .left,
      .right {
        width: calc(100vw * (410 / 1600));
      }

      & .colorText {
        margin-bottom: calc(100vw * (20 / 1600));
        font-size: calc(100vw * (42 / 1600));
      }

      & .title {
        font-size: calc(100vw * (26 / 1600));
      }

      & .description {
        margin-top: calc(100vw * (40 / 1600));
      }

      & .center {
        width: calc(100vw * (526 / 1600));

        & .img {
          height: calc(100vw * (730 / 1600));

          &::after {
            bottom: calc(100vw * (-60 / 1600));
            left: calc(100vw * (-190 / 1600));
            width: calc(100vw * (300 / 1600));
            height: calc(100vw * (270 / 1600));
            filter: drop-shadow(
              calc(100vw * (15 / 1600)) calc(100vw * (15 / 1600))
                calc(100vw * (30 / 1600)) rgba(27, 32, 51, 0.27)
            );
          }
        }
      }
    }

    & .gridSection {
      & .left,
      .right {
        width: calc(100vw * (960 / 1600));
        height: calc(100vw * (620 / 1600));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 1600));
        font-size: calc(100vw * (42 / 1600));
      }

      & .link {
        font-size: calc(100vw * (26 / 1600));
        text-underline-offset: calc(100vw * (4 / 1600));
      }

      & ul {
        margin-top: calc(100vw * (40 / 1600));
      }

      & .top {
        & .left {
          padding: calc(100vw * (97 / 1600)) calc(100vw * (120 / 1600)) 0 0;
        }

        & .right {
          padding: calc(100vw * (97 / 1600)) 0 0 calc(100vw * (120 / 1600));

          & .service:not(:first-child) {
            margin-top: calc(100vw * (30 / 1600));
          }
        }

        & ul {
          gap: calc(100vw * (65 / 1600));
          font-size: calc(100vw * (18 / 1600));

          & .img {
            width: calc(100vw * (56 / 1600));
            height: calc(100vw * (56 / 1600));
            margin: 0 auto calc(100vw * (15 / 1600));
          }
        }
      }

      & .bottom {
        & .left {
          padding: calc(100vw * (174 / 1600)) calc(100vw * (120 / 1600)) 0 0;

          & .noticeList {
            &:not(:last-child) {
              margin-bottom: calc(100vw * (30 / 1600));
            }

            & a {
              gap: calc(100vw * (35 / 1600));
            }
          }

          & .text {
            width: calc(100vw * (503 / 1600));
          }
        }

        & .right {
          padding: calc(100vw * (174 / 1600)) 0 0 calc(100vw * (120 / 1600));
        }
      }
    }

    & .promotionSection {
      margin: calc(100vw * (80 / 1600)) 0 calc(100vw * (130 / 1600));

      & ul {
        gap: calc(100vw * (45 / 1600));
      }

      & .promotionList {
        width: calc(100vw * (470 / 1600));

        & .img {
          height: calc(100vw * (304 / 1600));
          margin-bottom: calc(100vw * (20 / 1600));
        }

        & .description {
          margin-bottom: calc(100vw * (14 / 1600));
        }
      }
    }

    & .contactSection {
      padding-top: calc(100vw * (73 / 1600));

      &::before {
        right: calc(50% + calc(100vw * (568 / 1600)));
        width: calc(100vw * (138 / 1600));
        height: calc(100vw * (138 / 1600));
      }

      & .inner {
        border-radius: calc(100vw * (50 / 1600));
      }

      & .left {
        width: calc(100vw * (650 / 1600));
        height: calc(100vw * (565 / 1600));
        margin-right: calc(100vw * (150 / 1600));
        border-radius: calc(100vw * (50 / 1600));
      }

      & .right {
        & .title {
          margin-bottom: calc(100vw * (20 / 1600));
          font-size: calc(100vw * (42 / 1600));
        }

        & .text {
          margin-bottom: calc(100vw * (50 / 1600));
          font-size: calc(100vw * (26 / 1600));
        }

        & a {
          font-size: calc(100vw * (26 / 1600));
          text-underline-offset: calc(100vw * (4 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: calc(100vw * (80 / 375));

    & .mainBannerSection .img {
      height: calc(100vw * (732 / 375));
    }

    & .introSection {
      padding: calc(100vw * (80 / 375)) 0;

      &::after {
        top: calc(100vw * (-30 / 375));
        left: auto;
        right: 0;
        width: calc(100vw * (161 / 375));
        height: calc(100vw * (157 / 375));
      }

      & .inner {
        flex-direction: column;
      }

      & .left,
      .right {
        width: 100%;
      }

      & .colorText {
        margin-bottom: calc(100vw * (10 / 375));
        font-size: calc(100vw * (30 / 375));
      }

      & .title {
        font-size: calc(100vw * (24 / 375));
      }

      & .description {
        margin-top: calc(100vw * (30 / 375));
      }

      & .center {
        width: calc(100vw * (252 / 375));
        margin: calc(100vw * (30 / 375)) 0 calc(100vw * (59 / 375)) auto;

        & .img {
          height: calc(100vw * (350 / 375));

          &::after {
            bottom: calc(100vw * (-29 / 375));
            left: calc(100vw * (-91 / 375));
            width: calc(100vw * (144 / 375));
            height: calc(100vw * (130 / 375));
            filter: drop-shadow(
              calc(100vw * (15 / 375)) calc(100vw * (15 / 375))
                calc(100vw * (30 / 375)) rgba(27, 32, 51, 0.27)
            );
          }
        }
      }
    }

    & .gridSection {
      & .top,
      .bottom {
        flex-direction: column;
      }

      & .left,
      .right {
        width: 100%;
        height: auto;
      }

      & .title {
        margin-bottom: calc(100vw * (15 / 375));
        font-size: calc(100vw * (30 / 375));
      }

      & .link {
        font-size: calc(100vw * (20 / 375));
        text-underline-offset: calc(100vw * (4 / 375));
      }

      & ul {
        margin-top: calc(100vw * (30 / 375));
      }

      & .top {
        & .left {
          padding: calc(100vw * (33 / 375)) calc(100vw * (16 / 375));

          & ul {
            font-size: calc(100vw * (14 / 375));
          }
        }

        & .right {
          padding: calc(100vw * (31 / 375)) calc(100vw * (16 / 375));

          & ul {
            font-size: calc(100vw * (16 / 375));
          }

          & .service:not(:first-child) {
            margin-top: calc(100vw * (15 / 375));
          }
        }

        & ul {
          justify-content: space-between;
          gap: 0;

          & .img {
            width: calc(100vw * (34 / 375));
            height: calc(100vw * (34 / 375));
            margin: 0 auto calc(100vw * (10 / 375));
          }
        }
      }

      & .bottom {
        & .left {
          padding: calc(100vw * (27 / 375)) calc(100vw * (16 / 375));

          & .wrap {
            width: 100%;
          }

          & .noticeList {
            &:not(:last-child) {
              margin-bottom: calc(100vw * (10 / 375));
            }

            & a {
              align-items: flex-start;
              flex-direction: column;
              gap: calc(100vw * (5 / 375));
            }
          }

          & .text {
            width: 100%;
          }
        }

        & .right {
          padding: calc(100vw * (123 / 375)) calc(100vw * (16 / 375));
        }
      }
    }

    & .promotionSection {
      display: none;
    }

    & .contactSection {
      margin-top: calc(100vw * (80 / 375));
      padding-top: calc(100vw * (50 / 375));

      &::before {
        right: auto;
        left: calc(100vw * (39 / 375));
        width: calc(100vw * (100 / 375));
        height: calc(100vw * (100 / 375));
      }

      & .inner {
        flex-direction: column;
        align-items: flex-start;
        border-radius: calc(100vw * (30 / 375));
      }

      & .left {
        width: 100%;
        height: calc(100vw * (298 / 375));
        margin: 0 0 calc(100vw * (49 / 375));
        border-radius: calc(100vw * (30 / 375));
      }

      & .right {
        padding: 0 calc(100vw * (31 / 375)) calc(100vw * (44 / 375));

        & .title {
          margin-bottom: calc(100vw * (10 / 375));
          font-size: calc(100vw * (30 / 375));
        }

        & .text {
          margin-bottom: calc(100vw * (30 / 375));
          font-size: calc(100vw * (24 / 375));
        }

        & a {
          font-size: calc(100vw * (20 / 375));
          text-underline-offset: calc(100vw * (4 / 375));
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
