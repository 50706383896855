import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  & label {
    flex-shrink: 0;
    display: inline-block;
    width: 100px;
    font-size: 18px;
    font-weight: 600;
  }

  & .inputWrap {
    position: relative;
    width: 100%;

    & input {
      width: 100%;
      height: 50px;
      padding: 0 20px;
      font-size: 18px;
      font-weight: 400;
      background-color: var(--gray04);

      &::placeholder {
        color: var(--gray02);
      }

      &:disabled {
        background-color: var(--gray03);
      }
    }

    & .errorText {
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      font-size: 18px;
      font-weight: 400;
      color: var(--red);
    }
  }

  @media (max-width: 1600px) {
    gap: calc(100vw * (20 / 1600));

    & label {
      width: calc(100vw * (100 / 1600));
      font-size: calc(100vw * (18 / 1600));
    }

    & .inputWrap {
      & input {
        height: calc(100vw * (50 / 1600));
        padding: 0 calc(100vw * (20 / 1600));
        font-size: calc(100vw * (18 / 1600));
      }

      & .errorText {
        top: calc(100% + calc(100vw * (10 / 1600)));
        font-size: calc(100vw * (18 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: calc(100vw * (14 / 375));

    & label {
      width: 100%;
      font-size: calc(100vw * (16 / 375));
    }

    & .inputWrap {
      & input {
        height: calc(100vw * (49 / 375));
        padding: 0 calc(100vw * (20 / 375));
        font-size: calc(100vw * (16 / 375));
      }

      & .errorText {
        top: calc(100% + calc(100vw * (5 / 375)));
        font-size: calc(100vw * (16 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
