import Styles from "./styles";
import { useMediaQuery } from "react-responsive";
import { common, layout } from "components";
import { useEffect, useRef } from "react";

// img
import ImgBanner from "resources/image/ask/img_contact_banner.jpg";
import ImgBannerMb from "resources/image/ask/img_contact_banner_mb.jpg";
import IconMapPin from "resources/image/icon/icon_map_pin.svg";
import IconTel from "resources/image/icon/icon_tel.svg";

function ContactPage() {
  const { naver } = window;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const mapRef1 = useRef(null);
  const mapRef2 = useRef(null);
  const mapRefs = [mapRef1, mapRef2];

  const address = {
    address1: {lat: "37.5800359", lng: "126.9975853"},
    address2: {lat: "37.5811167", lng: "126.9982171"},
  }
  
  useEffect(() => {
    mapRefs.map((item, index) => {
      const currentMapRef = item;

      if (currentMapRef.current && naver) {
        const addressItem = new naver.maps.LatLng(address[`address${index + 1}`].lat, address[`address${index + 1}`].lng);
        const map = new naver.maps.Map(currentMapRef.current, { center: addressItem, zoom: 18 });
        new naver.maps.Marker({ position: addressItem, map: map });
      }
    });
  }, []);
  
  const mapList = [
    {
      title: "사무국",
      address: (
        <>
          서울 종로구 대학로 101, 서울대학교병원 <br />
          의생명연구원 6층 핵의학과 CLEVERcns 사무국
        </>
      ),
      tel: "02-2072-3341",
      map: "mapRef1",
    },
    {
      title: "유효성평가센터",
      address: (
        <>
          서울 종로구 대학로 101, 서울대학교 <br />
          의과대학 17동 401호 CLEVERcns 연구소
        </>
      ),
      tel: "02-740-8565",
      map: "mapRef2",
    },
  ];
  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation
          category={"문의하기"}
          ImgBanner={isMobile ? ImgBannerMb : ImgBanner}
        />

        <section className="mapSection">
          <div className="inner">
            {mapList.map((item, index) => {
              return (
                <div className="mapList" key={"mapList" + index}>
                  <div className="addressWrap">
                    <p className="title">{item.title}</p>

                    <div className="address">
                      <div className="icon">
                        <img src={IconMapPin} alt="IconMapPin" />
                      </div>

                      <p>{item.address}</p>
                    </div>

                    <div className="tel">
                      <div className="icon">
                        <img src={IconTel} alt="IconTel" />
                      </div>

                      <p>{item.tel}</p>
                    </div>
                  </div>

                  <div ref={mapRefs[index]} className="map"></div>
                </div>
              );
            })}
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default ContactPage;
