import Styles from "./styles";
import { common, ui } from "components";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { useBanner, useModals, useNotice, usePromotion } from "hooks";

// img
// import DummyMainBanner from "resources/image/dummy_main_banner.jpg";
// import DummyMainBannerMb from "resources/image/dummy_main_banner_mb.jpg";
import ImgInfoSection01 from "resources/image/main/img_info_section01.jpg";
import IconDisease01 from "resources/image/icon/icon_disease01.svg";
import IconDisease02 from "resources/image/icon/icon_disease02.svg";
import IconDisease03 from "resources/image/icon/icon_disease03.svg";
import IconDisease04 from "resources/image/icon/icon_disease04.svg";
import IconDisease05 from "resources/image/icon/icon_disease05.svg";
import IconService01 from "resources/image/icon/icon_service01.svg";
import IconService02 from "resources/image/icon/icon_service02.svg";
import IconService03 from "resources/image/icon/icon_service03.svg";
import IconService04 from "resources/image/icon/icon_service04.svg";
import IconService05 from "resources/image/icon/icon_service05.svg";
import IconService06 from "resources/image/icon/icon_service06.svg";
import IconService07 from "resources/image/icon/icon_service07.svg";
import { useEffect } from "react";

function MainPage() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  
  const bannerInfo = useBanner();
  const noticeInfo = useNotice({ noticeMain: true });
  const promotionInfo = usePromotion({ promotionList: {
    page: 1,
    recordSize: 3,
    main_yn: "Y",
  }});

  const bannerData = bannerInfo.getBanner.data || [];
  const noticeData = noticeInfo.getNoticeMain.data || [];
  const promotionData = promotionInfo.getPromotionList.data?.list || [];

  const diseaseList = [
    {
      img: IconDisease01,
      text: "신경발달질환",
    },
    {
      img: IconDisease02,
      text: "정신질환",
    },
    {
      img: IconDisease03,
      text: "퇴행성질환",
    },
    {
      img: IconDisease04,
      text: (
        <>
          급성 세포 <br />
          손상 뇌질환
        </>
      ),
    },
    {
      img: IconDisease05,
      text: "기타",
    },
  ];

  const seviceList = [
    [
      {
        img: IconService01,
        text: <i>In vitro</i>,
      },
      {
        img: IconService05,
        text: "바이오뱅크",
      },
    ],
    [
      {
        img: IconService02,
        text: "행동평가",
      },
      {
        img: IconService06,
        text: (
          <>
            BBB 투과도 <br className="mbOnly" /> 평가
          </>
        ),
      },
    ],
    [
      {
        img: IconService03,
        text: "영상평가",
      },
      {
        img: IconService07,
        text: (
          <>
            임상시험 <br />
            자문 서비스
          </>
        ),
      },
    ],
    [
      {
        img: IconService04,
        text: "조직평가",
      },
    ],
  ];

  useEffect(() => {
    modalOption.setMainModal((e) => {
      e.show = true;
      return { ...e };
    });
  }, []);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="mainBannerSection">
          <div className="img">
            <img
              src={isMobile ? bannerData.mb_image : bannerData.pc_image}
            />
          </div>
        </section>

        <section className="introSection">
          <div className="inner">
            <div className="left">
              <p className="colorText">CLEVERcns</p>
              <p className="title">신경-정신질환 유효성평가센터</p>
              <p className="description">
                신경질환과 정신질환의 진단 및 치료법 개발을{" "}
                <br className="mbOnly" />
                위한 <br className="pcOnly" />
                서울대학교병원과 서울대학교의 인프라 활용
              </p>
            </div>

            <div className="center">
              <div className="img">
                <img src={ImgInfoSection01} alt="ImgInfoSection" />
              </div>
            </div>

            <div className="right">
              <ui.button.BasicButton
                buttonText={"CLEVERcns 바로가기  →"}
                onClick={() => {
                  navigate("/introduction");
                }}
              />

              <p className="description">
                중추신경계 질환 표적 치료제 개발의 어려움을 <br />
                극복하기 위한 다양한 해결책 제시 <br />
                신약 후보물질들의 유효성평가를 위한 질환{" "}
                <br className="mbOnly" />
                표현형에 <br className="pcOnly" />
                따른 동물모델과 평가법 제공
              </p>
            </div>
          </div>
        </section>

        <section className="gridSection">
          <div className="top">
            <div className="left">
              <div className="wrap">
                <p className="title">
                  NEURO-PSYCHIATRIC <br />
                  DISORDER EVALUATION MODEL
                </p>

                <Link to="/disease/neurodevelopmental" className="link">
                  신경-정신질환 동물모델 소개 →
                </Link>

                <ul>
                  {diseaseList.map((item, index) => {
                    return (
                      <li className="diseaseList" key={"diseaseList" + index}>
                        <div className="img">
                          <img src={item.img} alt="diseaseImg" />
                        </div>

                        <p>{item.text}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="right">
              <p className="title">
                CLINICAL & EXPERIMENTAL <br />
                EVALUATION SERVICE
              </p>

              <Link to="/overview" className="link">
                유효성평가 서비스 →
              </Link>

              <ul>
                {seviceList.map((item, index) => {
                  return (
                    <li className="serviceList" key={"serviceList" + index}>
                      {item.map((current, idx) => {
                        return (
                          <div className="service" key={"service" + idx}>
                            <div className="img">
                              <img src={current.img} alt="serviceImg" />
                            </div>
                            <p>{current.text}</p>
                          </div>
                        );
                      })}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="bottom">
            <div className="left">
              <div className="wrap">
                <p className="title">CLEVERcns NEWS</p>

                <Link to="/notice" className="link">
                  공지사항 →
                </Link>

                <ul>
                  {noticeData.map((item, index) => {
                    return (
                      <li className="noticeList" key={"noticeList" + index}>
                        <Link to={`/notice/detail/${item.id}`}>
                          <p className="text">{item.title}</p>

                          <p className="date">{item.created_format}</p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="right">
              <p className="title">PUBLICITY RELEASE</p>

              <Link to="/promotion" className="link">
                홍보자료 →
              </Link>
            </div>
          </div>
        </section>

        <section className="promotionSection">
          <div className="inner">
            <ul>
              {promotionData.map((item, index) => {
                return (
                  <li className="promotionList" key={"promotionList" + index}>
                    <Link to={`/promotion/detail/${item.id}`}>
                      <div className="img">
                        <img src={item.thumbnail} alt="promotionImg" />
                      </div>

                      <p className="description">{item.title}</p>

                      <p className="date">{item.created_format}</p>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="contactSection">
          <div className="inner">
            <div className="left"></div>

            <div className="right">
              <p className="title">CONTACT US</p>

              <p className="text">의뢰서를 작성해 주세요</p>
              <Link to={"/request"}>의뢰서 작성 바로가기 →</Link>
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default MainPage;
