import Styles from "./styles";
import { useHistory } from "hooks";
import { common, layout } from "components";

// img
import ImgBanner from "resources/image/clevercns/img_history_banner.jpg";

function HistoryPage() {
  const historyInfo = useHistory();
  const data = historyInfo.getHistory.data || [];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation category={"CLEVERcns"} ImgBanner={ImgBanner} />

        <section className="historySection">
          <div className="inner">
            <ul className="historyWrap">
              {data.map((item, index) => {
                return (
                  <li className="historyList" key={"historyList" + index}>
                    <div className="year">{item.year}</div>

                    <ul className="listWrap">
                      <li className="list">
                        {item.description}
                      </li>
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default HistoryPage;
