import Styles from "./styles";
import { Link } from "react-router-dom";

function Tab({ tabIndex, tabType }) {
  const tabList = {
    disease: [
      {
        text: "신경발달질환",
        url: "neurodevelopmental",
      },
      {
        text: "정신질환",
        url: "psychiatric",
      },
      {
        text: "퇴행성질환",
        url: "neurodegenerative",
      },
      {
        text: "급성 세포 손상 뇌질환",
        url: "acute-brain-injury",
      },
      {
        text: "기타",
        url: "others",
      },
    ],
    validity: [
      {
        text: (
          <>
            <i>In vitro</i> 평가
          </>
        ),
        url: "in-vitro",
      },
      {
        text: "행동평가",
        url: "behavior",
      },
      {
        text: "영상평가",
        url: "video",
      },
      {
        text: "조직평가",
        url: "tissue",
      },
      {
        text: "바이오뱅크",
        url: "biobank",
      },
      {
        text: "BBB 투과도 평가",
        url: "BBB",
      },
    ],
  };

  return (
    <Styles.Container>
      {tabList[tabType]?.map((item, index) => {
        return (
          <li
            className={"tabList" + (tabIndex === item.url ? " on" : "")}
            key={"tabList" + index}
          >
            <Link to={`/${tabType}/${item.url}`}>{item.text}</Link>
          </li>
        );
      })}
    </Styles.Container>
  );
}

export default Tab;
