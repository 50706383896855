import Styles from "./styles";
import { common, ui } from "components";
import { api } from "service";
import { useNavigate } from "react-router-dom";
import { usePromotion } from "hooks";

function PromotionDetailPage() {
  const navigate = useNavigate();

  const promotionInfo = usePromotion({ promotionDetail: true });
  const data = promotionInfo.getPromotionDetail.data || []

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="section">
          <div className="inner">
            <div className="titleContainer">
              <div className="titleWrap">
                <div className="type">홍보자료</div>

                <p className="title">{data.title}</p>

                <p className="date">{data.created_format}</p>
              </div>

              <div className="thumbnailImg">
                <img src={data.image} alt="thumbnailImg" />
              </div>
            </div>

            <div className="editorWrap">
              <div
                className="editor ck-content"
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>
            </div>

            <div className="listButton">
              <ui.button.BasicButton
                buttonText={"목록으로"}
                onClick={() => navigate("/promotion")}
              />
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default PromotionDetailPage;
