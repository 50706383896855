import styled from "styled-components";

// img
import IconCheckOff from "resources/image/icon/icon_check_off_circle.svg";
import IconCheckOn from "resources/image/icon/icon_check_on_circle.svg";

const Container = styled.main`
  padding: 50px 0 130px;

  & .bannerSection {
    margin-bottom: 50px;
  }

  & .requestSection {
    & .requestWrap {
      margin-bottom: 80px;
    }

    & .titleWrap {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      padding-bottom: 20px;
      border-bottom: 3px solid var(--black);

      & .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        margin-right: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        background-color: var(--blue01);
        border-radius: 50%;
      }

      & .title {
        font-size: 26px;
        font-weight: bold;
      }

      & .guideText {
        margin-left: auto;
        font-size: 18px;
        font-weight: 600;
        color: var(--blue01);
      }
    }

    & .inputList {
      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }

    & .label {
      flex-shrink: 0;
      width: 100px;
      font-size: 18px;
      font-weight: 600;
    }

    & .errorText {
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      font-size: 18px;
      font-weight: 400;
      color: var(--red);
    }

    & .infoWrap {
      display: flex;
      gap: 40px;

      & > div {
        flex: 1 0;
      }

      & .checkInput {
        position: relative;
        display: flex;
        gap: 20px;

        & ul {
          display: flex;
          gap: 30px;
        }

        & .errorText {
          left: 120px;
        }
      }
    }

    & .testInfo,
    .item {
      & .inputList {
        display: flex;
        gap: 20px;

        & .inputContainer {
          position: relative;
          width: calc(100% - 120px);
        }

        & ul {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          width: 100%;

          &:not(:last-of-type) {
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--gray02);
          }
        }

        & .etc,
        .textarea {
          width: 100%;
        }
      }
    }

    & .item .inputList:where(:nth-child(2), :nth-child(3), :nth-child(5)) {
      align-items: center;
    }

    & .item .inputList .fileListWrap {
      display: block;

      & .fileList:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    & .termsWrap {
      margin-bottom: 30px;
      padding: 30px;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.44;
      background-color: var(--gray04);

      & .termText {
        height: 390px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--black);
          border-radius: 999px;
        }
      }
    }

    & .termsGuideText {
      margin-bottom: 30px;
      padding-bottom: 30px;
      font-weight: 600;
      color: var(--gray01);
      border-bottom: 1px solid var(--gray02);

      & p {
        margin-bottom: 15px;
      }

      & ul {
        display: flex;
        gap: 40px;
        font-size: 18px;

        & li {
          position: relative;

          &:not(:last-child)::after {
            position: absolute;
            top: 50%;
            left: calc(100% + 20px);
            transform: translateY(-50%);
            width: 1px;
            height: 14px;
            background-color: var(--gray01);
            content: "";
          }
        }
      }
    }

    & .termsCheck {
      position: relative;

      & input {
        display: none;

        &:checked + label .checkbox {
          background-image: url(${IconCheckOn});
        }
      }

      & label {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;

        & .checkbox {
          display: inline-block;
          width: 25px;
          height: 25px;
          background: url(${IconCheckOff}) no-repeat center / cover;
        }
      }
    }

    & .requestButton {
      width: fit-content;
      margin: -20px auto 0;
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (130 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .requestSection {
      & .requestWrap {
        margin-bottom: calc(100vw * (80 / 1600));
      }

      & .titleWrap {
        margin-bottom: calc(100vw * (50 / 1600));
        padding-bottom: calc(100vw * (20 / 1600));
        border-bottom: calc(100vw * (3 / 1600)) solid var(--black);

        & .circle {
          width: calc(100vw * (46 / 1600));
          height: calc(100vw * (46 / 1600));
          margin-right: calc(100vw * (10 / 1600));
          font-size: calc(100vw * (14 / 1600));
        }

        & .title {
          font-size: calc(100vw * (26 / 1600));
        }

        & .guideText {
          font-size: calc(100vw * (18 / 1600));
        }
      }

      & .inputList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (50 / 1600));
        }
      }

      & .label {
        width: calc(100vw * (100 / 1600));
        font-size: calc(100vw * (18 / 1600));
      }

      & .errorText {
        top: calc(100% + calc(100vw * (10 / 1600)));
        font-size: calc(100vw * (18 / 1600));
      }

      & .infoWrap {
        gap: calc(100vw * (40 / 1600));

        & .checkInput {
          gap: calc(100vw * (20 / 1600));

          & ul {
            gap: calc(100vw * (30 / 1600));
          }

          & .errorText {
            left: calc(100vw * (120 / 1600));
          }
        }
      }

      & .testInfo,
      .item {
        & .inputList {
          gap: calc(100vw * (20 / 1600));

          & .inputContainer {
            width: calc(100% - calc(100vw * (120 / 1600)));
          }

          & ul {
            gap: calc(100vw * (30 / 1600));

            &:not(:last-of-type) {
              margin-bottom: calc(100vw * (15 / 1600));
              padding-bottom: calc(100vw * (15 / 1600));
            }
          }
        }
      }

      & .item .inputList .fileListWrap {
        & .fileList:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 1600));
        }
      }

      & .termsWrap {
        margin-bottom: calc(100vw * (30 / 1600));
        padding: calc(100vw * (30 / 1600));
        font-size: calc(100vw * (18 / 1600));

        & .termText {
          height: calc(100vw * (390 / 1600));

          &::-webkit-scrollbar {
            width: calc(100vw * (5 / 1600));
            height: calc(100vw * (5 / 1600));
          }
        }
      }

      & .termsGuideText {
        margin-bottom: calc(100vw * (30 / 1600));
        padding-bottom: calc(100vw * (30 / 1600));

        & p {
          margin-bottom: calc(100vw * (15 / 1600));
        }

        & ul {
          gap: calc(100vw * (40 / 1600));
          font-size: calc(100vw * (18 / 1600));

          & li {
            &:not(:last-child)::after {
              left: calc(100% + calc(100vw * (20 / 1600)));
              height: calc(100vw * (14 / 1600));
            }
          }
        }
      }

      & .termsCheck {
        & label {
          gap: calc(100vw * (5 / 1600));
          font-size: calc(100vw * (18 / 1600));

          & .checkbox {
            width: calc(100vw * (25 / 1600));
            height: calc(100vw * (25 / 1600));
          }
        }
      }

      & .requestButton {
        margin: calc(100vw * (-20 / 1600)) auto 0;
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (80 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .requestSection {
      & .requestWrap {
        margin-bottom: calc(100vw * (50 / 375));
      }

      & .titleWrap {
        margin-bottom: calc(100vw * (30 / 375));
        padding-bottom: calc(100vw * (14 / 375));
        border-bottom: calc(100vw * (3 / 375)) solid var(--black);

        & .circle {
          width: calc(100vw * (36 / 375));
          height: calc(100vw * (36 / 375));
          margin-right: calc(100vw * (10 / 375));
          font-size: calc(100vw * (12 / 375));
        }

        & .title {
          font-size: calc(100vw * (20 / 375));
          word-break: keep-all;
        }

        & .guideText {
          flex-shrink: 0;
          font-size: calc(100vw * (16 / 375));
        }
      }

      & .inputList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 375));
        }
      }

      & .label {
        width: 100%;
        font-size: calc(100vw * (16 / 375));
      }

      & .errorText {
        top: calc(100% + calc(100vw * (5 / 375)));
        left: 0;
        font-size: calc(100vw * (16 / 375));
      }

      & .infoWrap {
        flex-direction: column;
        gap: calc(100vw * (30 / 375));

        & .checkInput {
          flex-direction: column;
          gap: calc(100vw * (14 / 375));

          & ul {
            flex-wrap: wrap;
            gap: calc(100vw * (20 / 375));
          }

          & .errorText {
            left: 0;
          }
        }
      }

      & .testInfo,
      .item {
        & .inputList {
          flex-direction: column;
          gap: calc(100vw * (14 / 375));

          & .inputContainer {
            width: 100%;
          }

          & ul {
            gap: calc(100vw * (20 / 375));

            &:not(:last-of-type) {
              margin-bottom: calc(100vw * (14 / 375));
              padding-bottom: calc(100vw * (14 / 375));
            }
          }
        }
      }

      & .item .inputList .fileListWrap {
        & .fileList:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 375));
        }
      }

      & .termsWrap {
        margin-bottom: calc(100vw * (20 / 375));
        padding: calc(100vw * (20 / 375));
        font-size: calc(100vw * (16 / 375));

        & .termText {
          height: calc(100vw * (390 / 375));

          &::-webkit-scrollbar {
            width: calc(100vw * (5 / 375));
            height: calc(100vw * (5 / 375));
          }
        }
      }

      & .termsGuideText {
        margin-bottom: calc(100vw * (20 / 375));
        padding-bottom: calc(100vw * (20 / 375));

        & p {
          margin-bottom: calc(100vw * (20 / 375));
          word-break: keep-all;
        }

        & ul {
          flex-direction: column;
          gap: calc(100vw * (5 / 375));
          font-size: calc(100vw * (14 / 375));

          & li {
            &:not(:last-child)::after {
              display: none;
            }
          }
        }
      }

      & .termsCheck {
        & label {
          gap: calc(100vw * (10 / 375));
          font-size: calc(100vw * (16 / 375));

          & .checkbox {
            width: calc(100vw * (21 / 375));
            height: calc(100vw * (21 / 375));
          }
        }
      }

      & .requestButton {
        margin: calc(100vw * (-20 / 375)) auto 0;
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
