import connector from "service/connector"
import utils from "utils";


const getNoticeList = async data => await
  connector.connectFetchController(`notice${data ? "?" + utils.etc.getFilterStr(data) : ""}`, "GET");

const getNoticeDetail = async data => await
  connector.connectFetchController(`notice/detail?board_info_id=${data}`, "GET");

const getNoticeMain = async () => await
  connector.connectFetchController("notice/main", "GET");


export {
  getNoticeList,
  getNoticeDetail,
  getNoticeMain,
};


export default {
  getNoticeList,
  getNoticeDetail,
  getNoticeMain,
}