import Styles from "./styles";
import utils from "utils";
import { usePromotion } from "hooks";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";

// img
import ImgBanner from "resources/image/news/img_promotion_banner.jpg";
import ImgBannerMb from "resources/image/news/img_promotion_banner_mb.jpg";

function PromotionPage() {
  const location = useLocation();
  const [filter, setFilter] = useState();
  const promotionInfo = usePromotion({ promotionList: filter })
    
  const data = promotionInfo.getPromotionList.data || [];
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page || 1,
      pageSize: 5,
      recordSize: 9,
    };
    setFilter({ ...f });
  }, [location])

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation
          category={"센터소식"}
          ImgBanner={isMobile ? ImgBannerMb : ImgBanner}
        />

        <section className="promotionSection">
          <div className="inner">
            <div className="total">
              총 <span>{data.pagination?.totalRecordCountAll}</span>건
            </div>

            {data.list?.length > 0 ? (
              <>
                <ul className="listWrap">
                  {data.list?.map((item, index) => {
                    return (
                      <li
                        className="promotionList"
                        key={"promotionList" + index}
                      >
                        <Link to={`/promotion/detail/${item.id}`}>
                          <div className="img">
                            <img src={item.thumbnail} alt="promotionImg" />
                          </div>

                          <p className="title">{item.title}</p>

                          <p className="date">{item.created_format}</p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                {data?.length > 9 && (
                  <div className="pagination">
                    <ui.Pagination 
                      page={data.pagination?.page}
                      list={data.pagination?.page_list}
                      maxPage={data.pagination?.totalPageCount}
                    />
                  </div>
                )}
              </>
            ) : (
              <p className="emptyText">등록된 게시물이 없습니다.</p>
            )}
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default PromotionPage;
