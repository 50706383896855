import React from "react";
import Pages from "pages";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";

export default function CustomRoute() {
  let location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Pages.MainPage />} />
      {/* CLEVER cns */}
      <Route path="/introduction" element={<Pages.IntroductionPage />} />
      <Route path="/history" element={<Pages.HistoryPage />} />
      <Route
        path="/organization-chart"
        element={<Pages.OrganizationChartPage />}
      />
      {/* 신경-정신 질환 */}
      <Route path="/disease/:id" element={<Pages.DiseasePage />} />
      {/* 서비스 */}
      <Route path="/overview" element={<Pages.OverviewPage />} />
      <Route path="/validity/:id" element={<Pages.ValidityPage />} />
      <Route path="/advice" element={<Pages.AdvicePage />} />
      <Route path="/step" element={<Pages.StepPage />} />
      {/* 센터 소식 */}
      <Route path="/notice" element={<Pages.NoticePage />} />
      <Route path="/notice/detail/:id" element={<Pages.NoticeDetailPage />} />
      <Route path="/promotion" element={<Pages.PromotionPage />} />
      <Route path="/promotion/detail/:id" element={<Pages.PromotionDetailPage />} />
      {/* 문의하기 */}
      <Route path="/request" element={<Pages.RequestPage />} />
      <Route path="/contact" element={<Pages.ContactPage />} />
    </Routes>
  );
}
