import Styles from "./styles";
import { common, layout } from "components";
import ScrollContainer from "react-indiana-drag-scroll";

// img
import ImgBanner from "resources/image/clevercns/img_introduction_banner.jpg";
import ImgIntroduction01 from "resources/image/clevercns/img_introduction01.jpg";
import ImgIntroduction02 from "resources/image/clevercns/img_introduction02.jpg";
import ImgGoal from "resources/image/clevercns/img_introduction_goal.png";
import ImgGoalMb from "resources/image/clevercns/img_introduction_goal_mb.png";
import ImgOvercome from "resources/image/clevercns/img_introduction_overcome.jpg";
import ImgOvercomeMb from "resources/image/clevercns/img_introduction_overcome_mb.jpg";

function IntroductionPage() {
  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation category={"CLEVERcns"} ImgBanner={ImgBanner} />

        <section className="greetingSection">
          <div className="inner">
            <div className="textWrap">
              <h3>
                서울대학교병원 <br />
                신경-정신질환 유효성평가센터
              </h3>

              <p className="colorText">인사말</p>

              <p className="description">
                서울대학교병원 신경-정신질환 유효성평가센터는 보건복지부의{" "}
                <br className="pcOnly" />
                ‘2023 성장형 질환유효성평가센터 구축사업’ 연구기관으로
                선정되었습니다. 서울대학교병원 신경-정신질환 유효성평가센터는
                신경질환과 정신질환의 진단 및 치료법 개발을 위해
                서울대학교병원과 서울대학교의 인프라를 활용합니다. 중추신경계
                질환 표적 치료제 <br className="pcOnly" />
                개발의 어려움을 극복하기 위한 다양한 해결책을 제공하고,
                <br className="pcOnly" />
                질환의 표현형에 따른 동물모델과 평가법을 확립하여 신약
                후보물질들의 유효성평가를 진행합니다. <br />
                <br />
                질환유효성평가센터 구축의 목적은 <br className="mbOnly" />{" "}
                ‘보건의료 분야의 기초 우수성과의 초기 전임상 단계부터 유효성평가
                서비스를 지원함으로써 임상시험 성공률을 제고’ 하는 것입니다.
                서울대학교병원 신경-정신질환 유효성평가센터는 신경-정신질환의
                신약후보물질 및 의료기기의 임상시험 성공률을 높이고 신약 개발을
                촉진하기 위해 노력할 것이며, 미충족 의료 수요를 해소하고,
                궁극적으로는 고통받는 환자에게 원활한 의료혜택이 제공되는 건강한
                사회를 <br className="mbOnly" /> 구현하도록 하겠습니다.
              </p>

              <p className="writer">CLEVERcns 일동</p>
            </div>

            <div className="img">
              <img src={ImgIntroduction01} alt="greetingImg" />
            </div>
          </div>
        </section>

        <section className="goalSection">
          <div className="inner">
            <h3>CLEVERcns 목표</h3>

            <div className="textContainer">
              <div className="img">
                <img src={ImgIntroduction02} alt="ImgIntroduction02" />
              </div>

              <p className="text">
                국내외 의약품 개발 기업을 대상으로 <br className="mbOnly" />
                글로벌 수준의 수요자 맞춤형 <br className="pcOnly" />
                유효성평가 <br className="mbOnly" />
                서비스를 제공함으로써 개발 중인 <br className="mbOnly" />
                신경-정신질환 의약품의 <br />
                임상시험 성공률 증진 및 상용화에 기여
              </p>
            </div>

            <div className="goalImg">
              <img className="pcOnly" src={ImgGoal} alt="goalImg" />
              <img className="mbOnly" src={ImgGoalMb} alt="goalImg" />
            </div>
          </div>
        </section>

        <section className="overcomeSection">
          <div className="inner">
            <h3>신경-정신질환 신약 개발의 어려움 극복</h3>

            <ScrollContainer>
              <div className="img">
                <img className="pcOnly" src={ImgOvercome} alt="ImgOvercome" />
                <img
                  className="mbOnly"
                  src={ImgOvercomeMb}
                  alt="ImgOvercomeMb"
                />
              </div>
            </ScrollContainer>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default IntroductionPage;
