import ModalPage from "./ModalPage";
import MainPage from "./MainPage";
import IntroductionPage from "./IntroductionPage";
import HistoryPage from "./HistoryPage";
import OrganizationChartPage from "./OrganizationChartPage";
import DiseasePage from "./DiseasePage";
import OverviewPage from "./OverviewPage";
import ValidityPage from "./ValidityPage";
import AdvicePage from "./AdvicePage";
import StepPage from "./StepPage";
import NoticePage from "./NoticePage";
import NoticeDetailPage from "./NoticeDetailPage";
import PromotionPage from "./PromotionPage";
import PromotionDetailPage from "./PromotionDetailPage";
import RequestPage from "./RequestPage";
import ContactPage from "./ContactPage";

export default {
  ModalPage,
  MainPage,
  IntroductionPage,
  HistoryPage,
  OrganizationChartPage,
  DiseasePage,
  OverviewPage,
  ValidityPage,
  AdvicePage,
  StepPage,
  NoticePage,
  NoticeDetailPage,
  PromotionPage,
  PromotionDetailPage,
  RequestPage,
  ContactPage,
};

export {
  ModalPage,
  MainPage,
  IntroductionPage,
  HistoryPage,
  OrganizationChartPage,
  DiseasePage,
  OverviewPage,
  ValidityPage,
  AdvicePage,
  StepPage,
  NoticePage,
  NoticeDetailPage,
  PromotionPage,
  PromotionDetailPage,
  RequestPage,
  ContactPage,
};
