import styled from "styled-components";

const Container = styled.section`
  & .inner {
    display: flex;
    justify-content: space-between;

    & .imgWrap {
      display: flex;
      gap: 50px;

      & .img {
        width: 350px;
        height: 230px;
        border-radius: 20px;
        overflow: hidden;
      }

      & .title {
        font-size: 26px;
        font-weight: bold;
        line-height: 1.38;
      }
    }
  }

  @media (max-width: 1600px) {
    & .inner {
      & .imgWrap {
        gap: calc(100vw * (50 / 1600));

        & .img {
          width: calc(100vw * (350 / 1600));
          height: calc(100vw * (230 / 1600));
          border-radius: calc(100vw * (20 / 1600));
        }

        & .title {
          font-size: calc(100vw * (26 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .inner {
      flex-direction: column;
      gap: calc(100vw * (50 / 375));

      & .imgWrap {
        flex-direction: column;
        gap: calc(100vw * (20 / 375));

        & .img {
          width: 100%;
          height: calc(100vw * (225 / 375));
          border-radius: calc(100vw * (20 / 375));
        }

        & .title {
          font-size: calc(100vw * (20 / 375));
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
