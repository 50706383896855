// img
import DummyImg01 from "resources/image/dummy_img01.jpg";
import DummyImg02 from "resources/image/dummy_img02.jpg";
import DummyImg03 from "resources/image/dummy_img03.jpg";

const noticeList = [
  {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2024.01.10",
  },
  {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    date: "2024.01.10",
  },
  {
    text: "Lorem ipsum dolor sit amet",
    date: "2024.01.10",
  },
];

const promotionList = [
  {
    img: DummyImg01,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    date: "2024.01.10",
  },
  {
    img: DummyImg02,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    date: "2024.01.10",
  },
  {
    img: DummyImg03,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    date: "2024.01.10",
  },
];

const historyList = [
  {
    year: 2024,
    list: [
      "Lorem ipsum dolor sit amet",
      "consectetur adipiscing elit, sed do eiusmod Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
    ],
  },
  {
    year: 2023,
    list: ["Lorem ipsum dolor sit amet", "Lorem ipsum dolor sit amet"],
  },
];

const companyList = [
  {
    img: DummyImg01,
    companyName: "(주) 몰림",
  },
  {
    img: DummyImg01,
    companyName: "주식회사 키프론바이오",
  },
  {
    img: DummyImg01,
    companyName: "주식회사 포트래이",
  },
  {
    img: DummyImg01,
    companyName: "에이페이스 (주)",
  },
  {
    img: DummyImg01,
    companyName: "주식회사 시프트바이오",
  },
];

const diseaseList = [
  {
    title: (
      <>
        조현병 <br />
        (Schizophernia)
      </>
    ),
    description: (
      <>
        조현병은 환각, 환청, 현실과의 단절, 망상, 기이한 행동, 인지 손상 등과
        같은 증상으로 일상적인 기능에 문제를 나타내는 정신 질환입니다. 이러한
        증상이 6개월 이상 지속되고, 일상생활에서의 사회적인 기능에 악영향을 미칠
        경우 조현병으로 진단됩니다 조현병의 발병 원인에는 유전적 요인, 스트레스,
        마약 등의 약물 사용과 같은 환경 요인의 조합이 관련되어 있지만, 정확한
        원인은 아직까지 밝혀지지 않았습니다. CLEVERcns는 조현병 동물모델을
        이용하여 신뢰할 수 있는 유효성평가를 제공합니다. 이를 통해 조현병의
        복잡한 특성과 다양성을 이해하고, 평가 항목 개발에 기여합니다.
      </>
    ),
  },
  {
    title: (
      <>
        우울증 <br />
        (Depression)
      </>
    ),
    description: (
      <>
        우울증은 큰 슬픔을 느끼거나 활동에 흥미나 즐거움이 감소하여 일상생활에
        심각한 지장을 주는 정신질환입니다. 이러한 증상은 주변인의 사망,
        자연재해, 심각한 질병 등과 같은 사건 이후에 비정상적으로 강한 감정을
        느끼거나, 그 기간이 초과하여 지속될 경우 발생할 수 있습니다. 또한,
        과도한 걱정, 공황 발작, 집착과 같은 증상이 우울증에서 흔히 나타납니다.
        CLEVERcns는 우울증 동물모델을 이용하여 우울증의 복잡한 특성을 이해하고,
        다양한 평가항목을 기반으로 유효성평가 서비스를 제공합니다.
      </>
    ),
  },
  {
    title: (
      <>
        수면장애 <br />
        (Sleep Disorder, Somnipathy)
      </>
    ),
    description: (
      <>
        수면장애는 수면 상태의 유지 능력 저하 또는 수면 중의 비정상적인 행동으로
        인해 수면에 방해를 받는 질환으로 정의됩니다. 이러한 상태는 불면증,
        주간의 과다 졸림증 등과 같은 다양한 증상을 보일 수 있으며, 학업/업무
        수행 능력 저하, 기억력, 정서적 문제 등과 같은 부수적인 영향을 미칠 수
        있습니다. 불규칙적인 취침 시간, 스트레스, 식사, 생활패턴, 약물 등과 같은
        여러 요인들이 수면장애를 유발할 수 있습니다. CLEVERcns는 수면부족 모델을
        활용하여 임상적으로 수면 부족이 인간의 건강에 미치는 영향을 이해하고,
        수면 장애의 기전을 바탕으로신약 후보물질의 유효성평가를 지원합니다.
      </>
    ),
  },
  {
    title: (
      <>
        중독 <br />
        (Addiction)
      </>
    ),
    description: (
      <>
        중독은 약물, 가스, 중금속, 농업용 제품 등의 독성 물질을 흡입하거나
        섭취함으로써 발생하는 유해한 효과를 의미합니다. 이러한 독성 물질에
        노출된 사람은 독의 종류, 양, 개인의 연령, 건강 상태에 따라 다양한 증상을
        경험할 수 있습니다. 이에는 의식 변화, 체온 변화와 같은 전신 증상, 구토,
        기침, 통증 등이 포함됩니다. CLEVERcns는 중독 동물모델을 활용하여 새로운
        치료법 및 예방법의 유효성을 평가합니다.
      </>
    ),
  },
];

const fileList = [
  "2024년도 항암 유효성평가 자문 서비스 지원 과제 모집 공고.zip",
  "서비스 지원 과제 모집 공고.zip",
  "2024년도 항암 유효성평가 자문 서비스 지원 과제 모집 공고.zip",
];

export {
  noticeList,
  promotionList,
  historyList,
  companyList,
  diseaseList,
  fileList,
};

export default {
  noticeList,
  promotionList,
  historyList,
  companyList,
  diseaseList,
  fileList,
};
