import Styles from "./styles";

// img
import ImgBiobank01 from "resources/image/service/img_validity_biobank01.jpg";
import ImgBiobank02 from "resources/image/service/img_validity_biobank02.jpg";

function BioBank() {
  return (
    <Styles.Container>
      <div className="inner">
        <div className="imgWrap">
          <div className="img">
            <img src={ImgBiobank01} alt="ImgBiobank01" />
          </div>

          <p className="title">
            환자 뇌조직을 이용한 <br />
            바이오마커 발현 확인
          </p>
        </div>

        <div className="imgWrap">
          <div className="img">
            <img src={ImgBiobank02} alt="ImgBiobank02" />
          </div>

          <p className="title">
            환자 뇌조직 유래 신경전구세포를 <br />
            이용한 <i>in vitro</i> 유효성평가 모델
          </p>
        </div>
      </div>
    </Styles.Container>
  );
}

export default BioBank;
