import styled from "styled-components";

const Container = styled.main`
  padding: 50px 0 130px;

  & .bannerSection {
    margin-bottom: 50px;
  }

  & .noticeSection {
    & .thead {
      display: flex;
      align-items: center;
      height: 80px;
      font-weight: 600;
      text-align: center;
      background-color: var(--gray04);
      border-top: 3px solid var(----black, #222);
    }

    & .number {
      width: 200px;
    }

    & .title {
      width: 1100px;
    }

    & .date {
      width: 200px;
    }

    & .emptyText {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;
      font-weight: 600;
      color: var(--gray02);
    }

    & .listWrap {
      margin-bottom: 50px;
      border-top: 1px solid var(--gray02);

      & .list {
        border-bottom: 1px solid var(--gray02);

        & a {
          display: flex;

          & > div {
            display: flex;
            align-items: center;
            height: 80px;
          }
        }

        & .number,
        .date {
          justify-content: center;
          font-weight: 400;
          color: var(--gray02);
        }

        & .title {
          padding: 0 40px;
          border-left: 1px solid var(--gray02);
          border-right: 1px solid var(--gray02);

          & p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (130 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .noticeSection {
      & .thead {
        height: calc(100vw * (80 / 1600));
        border-top: calc(100vw * (3 / 1600)) solid var(----black, #222);
      }

      & .number {
        width: calc(100vw * (200 / 1600));
      }

      & .title {
        width: calc(100vw * (1100 / 1600));
      }

      & .date {
        width: calc(100vw * (200 / 1600));
      }

      & .emptyText {
        height: calc(100vw * (300 / 1600));
      }

      & .listWrap {
        margin-bottom: calc(100vw * (50 / 1600));

        & .list {
          & a {
            & > div {
              height: calc(100vw * (80 / 1600));
            }
          }

          & .title {
            padding: 0 calc(100vw * (40 / 1600));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (80 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .noticeSection {
      & .inner {
        border-top: calc(100vw * (3 / 375)) solid var(----black, #222);
      }

      & .thead {
        display: none;
      }

      & .title,
      .date {
        width: 100%;
      }

      & .emptyText {
        height: calc(100vw * (270 / 375));
      }

      & .listWrap {
        margin-bottom: calc(100vw * (40 / 375));

        & .list {
          & a {
            flex-direction: column;
            justify-content: center;
            gap: calc(100vw * (5 / 375));
            height: calc(100vw * (70 / 375));

            & > div {
              height: auto;
            }
          }

          & .number {
            display: none;
          }

          & .title {
            padding: 0;
            border: 0;
          }

          & .date {
            justify-content: flex-start;
          }
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
