import Styles from "./styles";
const indent = "\n\u00A0\u00A0\u00A0"

function InVitro() {
  const serviceList = [
    {
      title: "Primary culture",
      list: [
        "- Hippocampal neuron",
        "- Cortical neuron",
        "- Microglia",
        "- Astrocytes",
      ],
    },
    {
      title: "Brain cell isolation",
      list: ["- Neuron", "- Microglia", "- Astrocytes"],
    },
    {
      title: "Cell line",
      list: [
        "- Neural progenitor cells",
        "- Glial cells",
        "- Brain cancer cells",
      ],
    },
    {
      title: "Cellular disease models",
      list: [
        "- Aβ induced toxicity",
        "- 6-OHDA induced toxicity",
        "- Tau uptake and seeding",
      ],
    },
    {
      title: "Neuroinflammation",
      list: [
        "- LPS induced glial cell reactivation",
        "- Phagocytosis",
        `- Cytokine assay${indent}(array, multiplexing, ELISA)`,
      ],
    },
    {
      title: "Neuronal Plasticity",
      list: ["- Neurogenesis", "- Neurite outgrowth", "- Synaptogenesis"],
    },
    {
      title: (
        <>
          Mitochondria / <br />
          lysosome related assays
        </>
      ),
      list: [
        `- Mitochondrial activity${indent}(MitoTracker)`,
        `- Mitochondrial membrane${indent}depolarization`,
        "- Lysosomal activity",
      ],
    },
    {
      title: (
        <>
          Biological Assays
        </>
      ),
      list: [
        "- Chemical assay",
        "- Immunoassay",
        "- Bioassay",
      ],
    },
  ];

  return (
    <Styles.Container>
      <div className="inner">
        <h3>서비스</h3>

        <ul className="serviceWrap">
          {serviceList.map((item, index) => {
            return (
              <li className="serviceList" key={"serviceList" + index}>
                <div className="title">
                  <div className="number">0{index + 1}</div>
                  {item.title}
                </div>

                <ul className="listWrap">
                  {item.list.map((current, idx) => {
                    return (
                      <li className="list" key={"list" + idx}>
                        {current}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>

        <p className="bottomText">
          그 외 분자생물 분석법들 활용한 <br className="mbOnly" />
          서비스 진행
        </p>
      </div>
    </Styles.Container>
  );
}

export default InVitro;
