import InVitro from "./InVitro";
import Behavior from "./Behavior";
import Video from "./Video";
import Tissue from "./Tissue";
import BioBank from "./BioBank";
import Bbb from "./Bbb";

export { InVitro, Behavior, Video, Tissue, BioBank, Bbb };

export default { InVitro, Behavior, Video, Tissue, BioBank, Bbb };
