import Styles from "./styles";
import { common, layout } from "components";
import ScrollContainer from "react-indiana-drag-scroll";
import { useMediaQuery } from "react-responsive";

// img
import ImgBanner from "resources/image/service/img_advice_banner.jpg";
import ImgBannerMb from "resources/image/service/img_advice_banner_mb.jpg";
import ImgAdvice01 from "resources/image/service/img_advice01.jpg";
import ImgAdvice02 from "resources/image/service/img_advice02.jpg";
import ImgAdvice03 from "resources/image/service/img_advice03.jpg";
import ImgAdviceTable from "resources/image/service/img_advice_table.jpg";

function AdvicePage() {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const list = [
    {
      img: ImgAdvice01,
      text: (
        <>
          질환 맞춤형 유효성평가 모델 개발 자문 제공 및 <br />
          비임상시험 전략 수립
        </>
      ),
    },
    {
      img: ImgAdvice02,
      text: (
        <>
          약물의 개발 단계에 맞춘 유효성평가 시험 설계 및 <br />
          임상진입 전략 구축
        </>
      ),
    },
    {
      img: ImgAdvice03,
      text: (
        <>
          임상 현장의 미충족 의료수요를 반영한 제품화 및 <br />
          임상 진입시간을 단축하기 위한 전략 수립
        </>
      ),
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation
          category={"서비스"}
          ImgBanner={isMobile ? ImgBannerMb : ImgBanner}
        />

        <section className="adviceSection">
          <div className="inner">
            <h3>임상시험 자문 서비스</h3>
            <p className="description">
              임상 전문가 자문이란 개발 중인 의약품 및 의료기기에 대해 자문을
              구함으로서 제품에 대한 의견, 방향성, <br className="mbOnly" />
              개선점 등을 확인하는 서비스입니다. <br className="pcOnly" />
              전문의 자문은 <br className="mbOnly" />
              제품 개발 전주기에 수행 가능하며, 신약개발 초기 단계부터 질환별
              임상적 유용성 평가와 임상개발 전략 수립 및 중개연구 전문가의{" "}
              <br className="pcOnly" />
              과학적 해석에 따른 신경계 질환 약물 및 의료기기의 임상현장 수요와
              간극 해소, <br className="mbOnly" />
              원내 비임상시험부터 임상시험까지 맞춤형 서비스를 위한 전략 설계를
              담당합니다. <br />
              임상 전문가 자문위원회 제공된 데이터에 대한 자체 분석과 해당
              분야에 대한 집합적인 전문 지식을 기반으로 관련 문헌 및 기타 정보를
              검토하여 정확한 최신 정보에 <br />
              기반한 권장사항을 제공하고자 합니다.
            </p>

            <ul className="listWrap">
              {list.map((item, index) => {
                return (
                  <li className="list" key={"list" + index}>
                    <div className="img">
                      <img src={item.img} alt="adviceImg" />
                    </div>

                    <p className="number">0{index + 1}</p>

                    <p className="text">{item.text}</p>
                  </li>
                );
              })}
            </ul>

            <div className="imgWrap">
              <p className="title">임상약리 자문</p>

              <ScrollContainer>
                <div className="img">
                  <img src={ImgAdviceTable} alt="ImgAdviceTable" />
                </div>
              </ScrollContainer>
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default AdvicePage;
