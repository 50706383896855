import { api } from "service"
import { useModals } from "hooks";
import { useNavigate } from "react-router-dom";

export default function useInquiry() {
  const navigate = useNavigate();
  const modalOption = useModals();

  const postInquiry = async (data) => {
    const result = await api.inquiry.postInquiry(JSON.stringify(data));
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = "의뢰서 제출이 완료되었습니다.";
        e.text =
          "의뢰서 기반으로 확인 후 담당자 이메일로 답변드리겠습니다.";
        e.buttonText = "메인으로";
        e.onClick = () => {
          navigate("/");
        };

        return { ...e };
      });
    }
  }

  
  return { postInquiry }
}