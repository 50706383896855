import Styles from "./styles";

export default function BasicButton({ buttonText, size, onClick }) {
  return (
    <div>
      <Styles.Button type="button" onClick={onClick} size={size}>
        {buttonText}
      </Styles.Button>
    </div>
  );
}
