import styled from "styled-components";

const Container = styled.ul`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: var(--gray02);
  border-bottom: 1px solid var(--gray02);

  & .tabList {
    padding: 14px 30px;

    &.on {
      color: #fff;
      background-color: var(--main);
      border-radius: 500px;
    }
  }

  @media (max-width: 1600px) {
    gap: calc(100vw * (20 / 1600));
    padding-bottom: calc(100vw * (20 / 1600));
    font-size: calc(100vw * (18 / 1600));

    & .tabList {
      padding: calc(100vw * (14 / 1600)) calc(100vw * (30 / 1600));

      &.on {
        border-radius: calc(100vw * (500 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: calc(100vw * (10 / 375));
    padding-bottom: calc(100vw * (10 / 375));
    font-size: calc(100vw * (16 / 375));

    & .tabList {
      padding: calc(100vw * (10 / 375)) calc(100vw * (15 / 375));

      &.on {
        border-radius: calc(100vw * (500 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
