import Styles from "./styles";
import { common, layout } from "components";
import ScrollContainer from "react-indiana-drag-scroll";
import { useMediaQuery } from "react-responsive";

// img
import ImgBanner from "resources/image/service/img_step_banner.jpg";
import ImgBannerMb from "resources/image/service/img_step_banner_mb.jpg";
import ImgStepTable from "resources/image/service/img_step_table.jpg";
import ImgStepTableMb from "resources/image/service/img_step_table_mb.jpg";

function StepPage() {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation
          category={"서비스"}
          ImgBanner={isMobile ? ImgBannerMb : ImgBanner}
        />

        <section className="stepSection">
          <div className="inner">
            <h3>
              QA 및 상호 검토 체계를 통한 <br />
              시험 실시 및 결과 관리
            </h3>

            <ScrollContainer>
              <div className="img">
                <img
                  src={isMobile ? ImgStepTableMb : ImgStepTable}
                  alt="ImgStepTable"
                />
              </div>
            </ScrollContainer>
          </div>
        </section>
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default StepPage;
