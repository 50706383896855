import styled from "styled-components";

// img
import ImDeco from "resources/image/service/img_validity_bbb_deco.jpg";

const Container = styled.section`
  & .listContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;

    & .list:not(:last-child) {
      margin-bottom: 40px;
    }

    & .number {
      margin-bottom: 5px;
      font-size: 42px;
      font-weight: 200;
      color: var(--gray02);
    }

    & .title {
      margin-bottom: 30px;
      font-size: 26px;
      font-weight: 700;
    }

    & .description {
      font-size: 18px;
      color: var(--gray01);
      line-height: 1.44;
    }

    & .img {
      position: relative;
      width: 480px;
      height: 890px;

      &::before {
        position: absolute;
        left: -120px;
        bottom: 59px;
        width: 210px;
        height: 189px;
        background: url(${ImDeco}) no-repeat center / cover;
        filter: drop-shadow(15px 15px 30px rgba(27, 32, 51, 0.27));
        content: "";
      }
    }
  }

  & .graphImg {
    height: 430px;
  }

  @media (max-width: 1600px) {
    & .listContainer {
      margin-bottom: calc(100vw * (100 / 1600));

      & .list:not(:last-child) {
        margin-bottom: calc(100vw * (40 / 1600));
      }

      & .number {
        margin-bottom: calc(100vw * (5 / 1600));
        font-size: calc(100vw * (42 / 1600));
      }

      & .title {
        margin-bottom: calc(100vw * (30 / 1600));
        font-size: calc(100vw * (26 / 1600));
      }

      & .description {
        font-size: calc(100vw * (18 / 1600));
      }

      & .img {
        width: calc(100vw * (480 / 1600));
        height: calc(100vw * (890 / 1600));

        &::before {
          left: calc(100vw * (-120 / 1600));
          bottom: calc(100vw * (59 / 1600));
          width: calc(100vw * (210 / 1600));
          height: calc(100vw * (189 / 1600));
          filter: drop-shadow(
            calc(100vw * (15 / 1600)) calc(100vw * (15 / 1600))
              calc(100vw * (30 / 1600)) rgba(27, 32, 51, 0.27)
          );
        }
      }
    }

    & .graphImg {
      height: calc(100vw * (430 / 1600));
    }
  }

  @media (max-width: 768px) {
    & .listContainer {
      flex-direction: column;
      gap: calc(100vw * (30 / 375));
      margin-bottom: calc(100vw * (80 / 375));

      & .list:not(:last-child) {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .number {
        margin-bottom: calc(100vw * (5 / 375));
        font-size: calc(100vw * (30 / 375));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (20 / 375));
      }

      & .description {
        font-size: calc(100vw * (16 / 375));
        word-break: keep-all;
      }

      & .img {
        width: calc(100vw * (274 / 375));
        height: calc(100vw * (508 / 375));
        margin-left: auto;

        &::before {
          left: calc(100vw * (-69 / 375));
          bottom: calc(100vw * (33 / 375));
          width: calc(100vw * (120 / 375));
          height: calc(100vw * (108 / 375));
          filter: drop-shadow(
            calc(100vw * (15 / 375)) calc(100vw * (15 / 375))
              calc(100vw * (30 / 375)) rgba(27, 32, 51, 0.27)
          );
        }
      }
    }

    & .graphImg {
      width: calc(100vw * (1161 / 375));
      height: calc(100vw * (430 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
