import styled from "styled-components";

const Container = styled.main`
  padding: 50px 0 130px;

  & .bannerSection {
    margin-bottom: 50px;
  }

  & .mapSection {
    & .mapList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 80px;
      }

      & .title {
        margin-bottom: 50px;
        font-size: 42px;
        font-weight: 600;
      }

      & .address,
      .tel {
        display: flex;
        align-items: center;
        gap: 30px;
        font-size: 26px;
        font-weight: 600;
        line-height: 1.38;

        & .icon {
          width: 40px;
          height: 40px;
        }
      }

      & .address {
        margin-bottom: 30px;
      }

      & .map {
        width: 770px;
        height: 610px;
        background-color: var(--gray03);
        border-radius: 50px;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (130 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .mapSection {
      & .mapList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (80 / 1600));
        }

        & .title {
          margin-bottom: calc(100vw * (50 / 1600));
          font-size: calc(100vw * (42 / 1600));
        }

        & .address,
        .tel {
          gap: calc(100vw * (30 / 1600));
          font-size: calc(100vw * (26 / 1600));

          & .icon {
            width: calc(100vw * (40 / 1600));
            height: calc(100vw * (40 / 1600));
          }
        }

        & .address {
          margin-bottom: calc(100vw * (30 / 1600));
        }

        & .map {
          width: calc(100vw * (770 / 1600));
          height: calc(100vw * (610 / 1600));
          border-radius: calc(100vw * (50 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (80 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .mapSection {
      & .mapList {
        flex-direction: column;
        align-items: flex-start;
        gap: calc(100vw * (30 / 375));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (80 / 375));
        }

        & .title {
          margin-bottom: calc(100vw * (30 / 375));
          font-size: calc(100vw * (30 / 375));
        }

        & .address,
        .tel {
          flex-direction: column;
          align-items: flex-start;
          gap: calc(100vw * (10 / 375));
          font-size: calc(100vw * (20 / 375));

          & .icon {
            width: calc(100vw * (30 / 375));
            height: calc(100vw * (30 / 375));
          }
        }

        & .address {
          margin-bottom: calc(100vw * (30 / 375));
          word-break: keep-all;
        }

        & .map {
          width: 100%;
          height: calc(100vw * (272 / 375));
          border-radius: calc(100vw * (30 / 375));
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
