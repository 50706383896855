import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background-color: var(--gray04);

  .fileName {
    width: calc(100% - 217px);
    font-size: 18px;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.on {
      color: var(--gray02);
    }
  }

  .deleteButton {
    margin: 0 20px 0 auto;
    font-size: 18px;
    font-weight: 600;
    color: var(--red);
  }

  & input {
    display: none;
  }

  & .uploadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 136px;
    height: 33px;
    font-size: 16px;
    color: var(--blue01);
    border: 2px solid var(--blue01);
    border-radius: 500px;
    cursor: pointer;
  }

  @media (max-width: 1600px) {
    height: calc(100vw * (50 / 1600));
    padding: 0 calc(100vw * (20 / 1600));

    .fileName {
      width: calc(100% - calc(100vw * (217 / 1600)));
      font-size: calc(100vw * (18 / 1600));
    }

    .deleteButton {
      margin: 0 calc(100vw * (20 / 1600)) 0 auto;
      font-size: calc(100vw * (18 / 1600));
    }

    & .uploadButton {
      width: calc(100vw * (136 / 1600));
      height: calc(100vw * (33 / 1600));
      font-size: calc(100vw * (16 / 1600));
      border: calc(100vw * (2 / 1600)) solid var(--blue01);
      border-radius: calc(100vw * (500 / 1600));
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (49 / 375));
    padding: 0 calc(100vw * (20 / 375));

    .fileName {
      width: calc(100% - calc(100vw * (162 / 375)));
      font-size: calc(100vw * (16 / 375));
    }

    .deleteButton {
      margin: 0 calc(100vw * (10 / 375)) 0 auto;
      font-size: calc(100vw * (16 / 375));
    }

    & .uploadButton {
      width: calc(100vw * (116 / 375));
      height: calc(100vw * (33 / 375));
      font-size: calc(100vw * (14 / 375));
      border: calc(100vw * (2 / 375)) solid var(--blue01);
      border-radius: calc(100vw * (500 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
