import Styles from "./styles";
import ui from "components/ui";

function DefaultModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div className="modalInner">
        <p className="title">{modalOption.title}</p>
        <p className="text">{modalOption.text}</p>
        <div className="buttonWrap">
          <ui.button.BasicButton
            buttonText={modalOption.buttonText}
            onClick={() => {
              {
                modalOption.onClick && modalOption.onClick();
              }
              modalClose();
            }}
          />
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { DefaultModal };

export default DefaultModal;
