import styled from "styled-components";

const Container = styled.section`
  & .serviceWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 50px 0;
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--gray02);

    & .serviceList {
      width: calc(100% / 4);

      & .title {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 10px;
        font-size: 26px;
        font-weight: bold;

        & .number {
          font-size: 42px;
          font-weight: 200;
          color: var(--gray02);
        }
      }

      & .listWrap {
        padding-left: 69px;
        color: var(--gray01);
        white-space: pre-line;
        line-height: 1.4;
      }
    }
  }

  & .bottomText {
    font-size: 26px;
    font-weight: 600;
    text-align: center;
  }

  @media (max-width: 1600px) {
    & .serviceWrap {
      gap: calc(100vw * (50 / 1600)) 0;
      margin-bottom: calc(100vw * (50 / 1600));
      padding-bottom: calc(100vw * (50 / 1600));

      & .serviceList {
        & .title {
          gap: calc(100vw * (20 / 1600));
          margin-bottom: calc(100vw * (10 / 1600));
          font-size: calc(100vw * (26 / 1600));

          & .number {
            font-size: calc(100vw * (42 / 1600));
          }
        }

        & .listWrap {
          padding-left: calc(100vw * (69 / 1600));
        }
      }
    }

    & .bottomText {
      font-size: calc(100vw * (26 / 1600));
    }
  }

  @media (max-width: 768px) {
    & .serviceWrap {
      flex-direction: column;
      gap: calc(100vw * (20 / 375));
      margin-bottom: calc(100vw * (30 / 375));
      padding-bottom: calc(100vw * (30 / 375));

      & .serviceList {
        width: 100%;

        & .title {
          gap: calc(100vw * (20 / 375));
          margin-bottom: calc(100vw * (10 / 375));
          font-size: calc(100vw * (24 / 375));

          & .number {
            font-size: calc(100vw * (30 / 375));
          }
        }

        & .listWrap {
          padding-left: calc(100vw * (49 / 375));
        }
      }
    }

    & .bottomText {
      font-size: calc(100vw * (24 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
