import { useQuery } from "react-query";
import { api } from "service";

export default function useHistory() {

  const getHistory = useQuery(["history"], () =>
    api.history.getHistory(), {
      refetchOnWindowFocus: false,
    }
  );

  return { getHistory }
}