import { useQuery } from "react-query";
import { api } from "service";

export default function useBanner() {

  const getBanner = useQuery(["banner"], () =>
    api.banner.getBanner(), {
      refetchOnWindowFocus: false,
    }
  );

  return { getBanner }
}