import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { ui } from "components";

function DiseaseList({ data }) {
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <p className="title">{data.title}</p>
      <p className="description">{data.description}</p>

      <ui.button.BasicButton
        buttonText={"의뢰서 작성하기"}
        onClick={() => {
          navigate("/request");
        }}
      />
    </Styles.Container>
  );
}

export default DiseaseList;
