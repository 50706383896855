import styled from "styled-components";

const Container = styled.main`
  padding: 50px 0 130px;

  & .bannerSection {
    margin-bottom: 20px;
  }

  & .tab {
    margin-bottom: 50px;
  }

  & h3 {
    margin-bottom: 30px;
  }

  & .textSection {
    margin-bottom: 50px;

    & .description {
      line-height: 1.44;
    }
  }

  & .marquee {
    margin-bottom: 50px;
    font-size: 100px;
    font-weight: bold;
    color: var(--gray04);
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (130 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (20 / 1600));
    }

    & .tab {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & h3 {
      margin-bottom: calc(100vw * (30 / 1600));
    }

    & .textSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .marquee {
      margin-bottom: calc(100vw * (50 / 1600));
      font-size: calc(100vw * (100 / 1600));
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (80 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (10 / 375));
    }

    & .tab {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & h3 {
      margin-bottom: calc(100vw * (20 / 375));
    }

    & .textSection {
      margin-bottom: calc(100vw * (30 / 375));
      font-size: calc(100vw * (16 / 375));
      word-break: keep-all;
    }

    & .marquee {
      margin-bottom: calc(100vw * (30 / 375));
      font-size: calc(100vw * (60 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
