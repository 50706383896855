import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 767px;
    padding: 50px 40px;
    background-color: #fff;
    border-radius: 20px;
    z-index: 10;

    & .title {
      margin-bottom: 20px;
      font-size: 26px;
      font-weight: 700;
    }

    & .text {
      margin-bottom: 49px;
      font-size: 18px;
    }

    & .buttonWrap {
      width: fit-content;
      margin-left: auto;
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.3);
    backdrop-filter: blur(25px);
  }

  @media (max-width: 1600px) {
    & .modalInner {
      width: calc(100vw * (767 / 1600));
      padding: calc(100vw * (50 / 1600)) calc(100vw * (40 / 1600));
      border-radius: calc(100vw * (20 / 1600));

      & .title {
        margin-bottom: calc(100vw * (20 / 1600));
        font-size: calc(100vw * (26 / 1600));
      }

      & .text {
        margin-bottom: calc(100vw * (49 / 1600));
        font-size: calc(100vw * (18 / 1600));
      }
    }

    & .overlay {
      backdrop-filter: blur(calc(100vw * (25 / 1600)));
    }
  }

  @media (max-width: 768px) {
    & .modalInner {
      width: calc(100vw * (343 / 375));
      padding: calc(100vw * (40 / 375)) calc(100vw * (30 / 375));
      text-align: center;
      border-radius: calc(100vw * (20 / 375));

      & .title {
        margin-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (20 / 375));
      }

      & .text {
        margin-bottom: calc(100vw * (40 / 375));
        font-size: calc(100vw * (16 / 375));
        word-break: keep-all;
      }

      & .buttonWrap {
        margin: 0 auto;
      }
    }

    & .overlay {
      backdrop-filter: blur(calc(100vw * (25 / 375)));
    }
  }
`;

export { Container };

export default { Container };
