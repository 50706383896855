import { useModals } from "hooks";
import { useEffect } from "react";
import { getCookie } from "service/connector";
import { DefaultModal, MainModal } from "components/modal";

// img
export default function ModalGeneratePage() {
  const modalOption = useModals();

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (
      modalOption.defaultModal.show ||
      (getCookie('popup') !== "hide" && modalOption.mainModal.show)
    ) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [modalOption]);

  return (
    <>
      {
        //기본 모달
        modalOption.defaultModal.show ? (
          <DefaultModal
            modalOption={modalOption.defaultModal}
            modalClose={() => {
              modalOption.setDefaultModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //메인 모달
        modalOption.mainModal.show ? (
          <MainModal
            modalOption={modalOption.mainModal}
            modalClose={() => {
              modalOption.setMainModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
    </>
  );
}
