import Styles from "./styles";
import { common, layout } from "components";
import { useLocation } from "react-router-dom";
import { api } from "service";
import Marquee from "react-fast-marquee";
import components from "./components";

// img
import ImgBanner from "resources/image/service/img_validity_banner.jpg";

function ValidityPage() {
  const location = useLocation();

  const pathName = location.pathname.split("/")[2];

  const data = {
    "in-vitro": {
      title: (
        <>
          <i>In vitro</i> 평가
        </>
      ),
      description: (
        <>
          뇌세포들을 이용한 <i>in vitro</i> 실험을 통해 다양한 유효성평가를
          진행하고 있습니다. 이러한 실험들은 Primary culture, Adult brain cell,
          Cell line 등을 이용하여 신경세포 독성평가, <br className="pcOnly" />
          아교세포 활성 평가, 포식작용 평가, 신경가소성 평가, 세포 소기관
          기능평가, BBB 투과도 평가, 면역학적 평가, 다중 오믹스
          <br className="mbOnly" />
          (유전체, 전사체, 단백체, 대사체 등) 등<br className="pcOnly" />
          분자생물학 기술 기반 평가 서비스를 제공하고 있습니다.{" "}
          <br className="mbOnly" />
          이러한 평가들은 신경-정신질환의 복잡한 병리학적 과정을 이해하고,
          약물의 작용 기전과 효능을 <br className="mbOnly" />
          평가할 수 있습니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
    behavior: {
      title: "행동평가",
      description: (
        <>
          행동평가를 통해 신경-정신질환 치료제가 뇌 기능에 미치는 영향을
          평가하며, 이는 환자의 증상 개선 가능성을 판단하는 데 중요한 정보를
          제공합니다. <br />
          특히 신경-정신질환의 명확한 생물학적 바이오마커가 부족한 경우
          행동평가는 전임상 연구와 임상 결과 사이의 연계성을 강화하는 데
          기여합니다.
          <br />
          약물의 작용 기전을 이해하고, 안전성 및 부작용을 조기에 경고할 뿐만
          아니라 생체표지자로서의 역할도 수행합니다.
          <br />
          CLEVERcns는 행동평가를 통해 신약 후보물질과 의료기기의 유용성에 대한
          결정적인 정보를 얻기 위해 노력하고 있습니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
    video: {
      title: "영상평가",
      description: (
        <>
          분자영상평가를 통해 신경-정신질환 치료제 개발은 중요한 발전을 이루고
          있습니다. 이러한 기술들은 약물의 뇌 내 도달 정도를 평가하고,{" "}
          <br className="mbOnly" />
          혈액-뇌 장벽(BBB)의 투과성을 <br className="pcOnly" />
          측정하는 데 뛰어난 정밀도를 제공하며, 신경-정신질환의 복잡한 병리학적
          과정을 이해하는 데 중요한 도구입니다. 약동학(PK)과 약력학(PD) 평가를
          통해 약물이 생체 내에서 <br className="pcOnly" />
          어떻게 분포되고, 대사되며, 배설되는지를 이해하며, 이를 통해 최적의
          용량 결정과 약물의 효과를 예측할 수 있습니다.
          <br />
          또한, 바이오마커 평가는 질병의 진단, 치료 반응의 모니터링, 그리고 개인
          맞춤형 치료의 실현을 위한 중요한 기술로, 복잡한 신경-정신질환의 치료제
          개발에 있어서 중요한 역할을 합니다.
          <br />
          <br />
          분자영상은 임상 연구로의 전환에 있어서 중요한 단계입니다. 동일한
          분자영상 바이오마커를 임상 연구의 end point로 사용함으로써, 연구
          결과의 일관성을 유지하고,
          <br className="pcOnly" />
          임상적 유익성을 예측하는 데 도움이 됩니다. 분자영상을 통해 얻은
          데이터는 임상 연구에서 바이오마커의 유효성을 입증하는 데 사용될 수
          있으며,
          <br />
          이는 임상 결과와 직접적인 상관관계가 있는지를 평가하는 데 중요합니다.
          이러한 접근 방식은 임상 연구의 설계와 실행을 개선하고,
          <br className="pcOnly" />
          치료제의 개발 속도를 가속화하는 데 기여할 수 있습니다.
          <br />
          <br />
          CLEVERcns는 합성 전문성과 영상촬영 기술을 <br className="mbOnly" />
          활용하여 신약 개발의 모든 단계에서 과학적이고 정확한 의사결정을
          지원합니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
    tissue: {
      title: "조직평가",
      description: (
        <>
          면역조직화학(Immunohistochemistry, IHC)은 <br className="mbOnly" />
          생물 조직에서 항원에 특이적으로 결합하는 항체의 원리를 이용합니다.{" "}
          <br className="pcOnly" />
          항체가 조직 절편의 단백질(항원)에 <br className="mbOnly" />
          선택적으로 결합하여 조직 또는 세포 내 특정 단백질의 존재 유무 및 존재 위치를 확인합니다.
          <br />
          <br />
          CLEVERcns는 IHC를 통해 동물 모델 또는 환자의 뇌조직에서 특정 단백질의
          분포와 위치를 확인하고,
          <br />
          이를 통해 신약 후보물질 또는 의료기기의 유효성을 평가할 수 있습니다.
          이러한 과정은 신약 개발 및 질병 진단에 중요한 역할을 합니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
    biobank: {
      title: "바이오뱅크",
      description: (
        <>
          신경-정신질환 환자 뇌조직의 병리평가는 <br className="mbOnly" />
          신경-정신질환의 유효성평가에서 중요한 역할을 합니다. <br />
          <br />

          뇌질환자의 뇌조직 병리평가를 통해 질병의 원인, {" "}
          <br className="mbOnly" />
          진행 과정 등 질병에 대한 이해를 높일 수 있습니다.
          <br />
          더불어 명확한 바이오마커가 없는 신경-정신질환에서 신규 표적을 발굴하는
          것은 질병의 조기 진단, 예후 판정, 그리고 치료 반응 예측 등에 중요한
          역할을 합니다.
          <br />
          이러한 병리평가 결과는 병변의 위치, 크기, 형태에 대한 정보를 제공해
          질환의 정확한 진단에 도움이 되고, 질환에 대한 효과적인 치료 전략을
          개발하는 데 필요한 정보를 제공합니다.
          <br />
          <br />
          신경-정신질환 환자의 뇌조직은 동물모델의 한계를 극복하고, 실제 인간의
          질병 상태를 보다 정확하게 <br className="mbOnly" />
          반영할 수 있습니다. 이를 통해 신경-정신질환을
          <br className="pcOnly" />이해하고, 보다 효과적인 치료법을
          개발하는 데 기여할 수 있습니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
    BBB: {
      title: "BBB(Blood-Brain Barrier) 투과도 평가",
      description: (
        <>
          신경-정신질환 유효성평가센터는 신약 후보물질 또는 의료기기의
          유효성을 평가하기 위해 다양한 서비스를 제공하고 있습니다. <br />
          그중 하나인 BBB투과도 평가는 신약 물질이 뇌로{" "}
          <br className="mbOnly" />
          얼마나 잘 투과하는지를 측정하는 중요한 서비스입니다. 이 서비스는{" "}
          <i>In vitro</i> 와 <i>In vivo</i> 에서 진행됩니다. <br />
          <br />
          BBB 투과도 평가는 신약 개발 과정에서 매우 <br className="mbOnly" />
          중요한 단계로, 이를 통해 신약 물질이 뇌로 효과적으로 전달될 수 있는지를
          평가할 수 있습니다. <br className="pcOnly" />더불어 인간 
          유래 iPSC를 이용한 BBB 모델링은 신약 후보물질의 BBB 투과도를 보다 정확하게 평가하고,
          신약 개발 과정을 보다 효율적으로 진행할 수 있게 합니다. <br className="pcOnly" />
          이러한 접근법은 신경-정신질환 환자들에게 보다 효과적인 치료법을 제공하는 데 중요한 역할을 합니다.
        </>
      ),
      list: api.dummy.diseaseList,
    },
  };

  const renderContents = (type) => {
    switch (type) {
      case "in-vitro":
        return <components.InVitro />;
      case "behavior":
        return <components.Behavior />;
      case "video":
        return <components.Video />;
      case "tissue":
        return <components.Tissue />;
      case "biobank":
        return <components.BioBank />;
      case "BBB":
        return <components.Bbb />;
    }
  };

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Navigation category={"서비스"} ImgBanner={ImgBanner} />

        <div className="tab inner">
          <layout.Tab tabIndex={pathName} tabType={"validity"} />
        </div>

        <section className="textSection">
          <div className="inner">
            <h3>{data[pathName].title}</h3>
            <p className="description">{data[pathName].description}</p>
          </div>
        </section>

        {/* <Marquee autoFill className="marquee">
          CLinical and Experimental eValuation of therapeutic Efficacy Research
          Center for Neuro-pSychiatric disorder
        </Marquee> */}

        {renderContents(pathName)}
      </Styles.Container>
      <common.TopButton />
      <common.Footer />
    </>
  );
}

export default ValidityPage;
