import styled from "styled-components";

const Container = styled.main`
  padding: 50px 0 130px;

  & .bannerSection {
    margin-bottom: 20px;
  }

  & .textSection {
    margin-bottom: 50px;

    & .inner {
      padding: 50px 0;
      border-bottom: 1px solid var(--gray02);
    }

    & .title {
      margin-bottom: 30px;
      font-size: 32px;
      font-weight: bold;
    }

    & .description {
      font-size: 18px;
      line-height: 1.44;
    }
  }

  & .diseaseSection {
    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 60px;

      & .diseaseList {
        width: 720px;
      }
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (130 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (20 / 1600));
    }

    & .textSection {
      margin-bottom: calc(100vw * (50 / 1600));

      & .inner {
        padding: calc(100vw * (50 / 1600)) 0;
      }

      & .title {
        margin-bottom: calc(100vw * (30 / 1600));
        font-size: calc(100vw * (32 / 1600));
      }

      & .description {
        font-size: calc(100vw * (18 / 1600));
      }
    }

    & .diseaseSection {
      & ul {
        gap: calc(100vw * (60 / 1600));

        & .diseaseList {
          width: calc(100vw * (720 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (80 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (10 / 375));
    }

    & .textSection {
      margin-bottom: calc(100vw * (30 / 375));

      & .inner {
        padding: calc(100vw * (50 / 375)) 0 calc(100vw * (30 / 375));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (24 / 375));
      }

      & .description {
        font-size: calc(100vw * (16 / 375));
        word-break: keep-all;
      }
    }

    & .diseaseSection {
      & ul {
        gap: calc(100vw * (30 / 375));

        & .diseaseList {
          width: 100%;
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
