import styled from "styled-components";

const Container = styled.div`
  padding: 50px;
  background-color: var(--gray04);
  border-radius: 20px;

  & .title {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: bold;
  }

  & .description {
    height: 155px;
    margin-bottom: 40px;
    padding-right: 10px;
    font-size: 18px;
    color: var(--gray01);
    line-height: 1.44;
    overflow: auto;
    white-space: pre-wrap;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--black);
      border-radius: 999px;
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600));
    border-radius: calc(100vw * (20 / 1600));

    & .title {
      margin-bottom: calc(100vw * (30 / 1600));
      font-size: calc(100vw * (26 / 1600));
    }

    & .description {
      height: calc(100vw * (155 / 1600));
      margin-bottom: calc(100vw * (40 / 1600));
      padding-right: calc(100vw * (10 / 1600));
      font-size: calc(100vw * (18 / 1600));

      &::-webkit-scrollbar {
        width: calc(100vw * (5 / 1600));
        height: calc(100vw * (5 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (40 / 375)) calc(100vw * (20 / 375));
    border-radius: calc(100vw * (20 / 375));

    & .title {
      margin-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (20 / 375));
    }

    & .description {
      height: calc(100vw * (155 / 375));
      margin-bottom: calc(100vw * (30 / 375));
      padding-right: calc(100vw * (10 / 375));
      font-size: calc(100vw * (16 / 375));

      &::-webkit-scrollbar {
        width: calc(100vw * (5 / 375));
        height: calc(100vw * (5 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
