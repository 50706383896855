import styled from "styled-components";

// img
import BgLogo from "resources/image/bg_logo.svg";
import BgOverview from "resources/image/service/bg_overview.jpg";
import BgOverviewMb from "resources/image/service/bg_overview_mb.jpg";

const Container = styled.main`
  padding: 50px 0 301px;
  background: url(${BgLogo}) no-repeat center calc(100% - 130px) / 96px 91px;

  & .bannerSection {
    margin-bottom: 50px;
  }

  & .overviewSection {
    margin-bottom: 100px;

    & .img {
      width: 1340px;
      height: 522px;
      margin: 0 auto;
    }
  }

  & .serviceSection {
    margin-bottom: 100px;
    padding: 80px 0;
    color: #fff;
    background: url(${BgOverview}) no-repeat center / cover;

    & h3 {
      margin-bottom: 50px;
    }

    & .serviceWrap {
      display: flex;
      margin-bottom: 30px;

      & .serviceList {
        display: flex;
        justify-content: center;
        gap: 20px;
        width: 375px;

        & .number {
          font-size: 42px;
          font-weight: 200;
        }

        & .textWrap {
          padding-top: 10px;
          font-size: 18px;

          & .title {
            margin-bottom: 10px;
            font-size: 26px;
            font-weight: 700;
          }

          & .subText {
            margin-bottom: 20px;
          }

          & .list {
            font-weight: 400;
            white-space: pre-line;

            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    & .circleWrap {
      display: flex;

      & .circleList {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 300px;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        line-height: 1.44;
        border: 2px solid rgba(133, 140, 164, 0.5);
        border-radius: 50%;

        & .title {
          margin-bottom: 20px;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }
  }

  & .cooperationSection {
    & h3 {
      display: flex;
      align-items: center;
      gap: 23px;
      margin-bottom: 30px;

      & span {
        font-size: 18px;
        font-weight: 600;
        color: var(--gray01);
      }
    }

    & .img {
      height: 856px;
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (301 / 1600));
    background: url(${BgLogo}) no-repeat center
      calc(100% - calc(100vw * (130 / 1600))) / calc(100vw * (96 / 1600))
      calc(100vw * (91 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .overviewSection {
      margin-bottom: calc(100vw * (100 / 1600));

      & .img {
        width: calc(100vw * (1340 / 1600));
        height: calc(100vw * (522 / 1600));
      }
    }

    & .serviceSection {
      margin-bottom: calc(100vw * (100 / 1600));
      padding: calc(100vw * (80 / 1600)) 0;

      & h3 {
        margin-bottom: calc(100vw * (50 / 1600));
      }

      & .serviceWrap {
        margin-bottom: calc(100vw * (30 / 1600));

        & .serviceList {
          gap: calc(100vw * (20 / 1600));
          width: calc(100vw * (375 / 1600));

          & .number {
            font-size: calc(100vw * (42 / 1600));
          }

          & .textWrap {
            padding-top: calc(100vw * (10 / 1600));
            font-size: calc(100vw * (18 / 1600));

            & .title {
              margin-bottom: calc(100vw * (10 / 1600));
              font-size: calc(100vw * (26 / 1600));
            }

            & .subText {
              margin-bottom: calc(100vw * (20 / 1600));
            }

            & .list {
              &:not(:last-child) {
                margin-bottom: calc(100vw * (10 / 1600));
              }
            }
          }
        }
      }

      & .circleWrap {
        & .circleList {
          width: calc(100vw * (300 / 1600));
          height: calc(100vw * (300 / 1600));
          font-size: calc(100vw * (18 / 1600));
          border: calc(100vw * (2 / 1600)) solid rgba(133, 140, 164, 0.5);

          & .title {
            margin-bottom: calc(100vw * (20 / 1600));
            font-size: calc(100vw * (26 / 1600));
          }
        }
      }
    }

    & .cooperationSection {
      & h3 {
        gap: calc(100vw * (23 / 1600));
        margin-bottom: calc(100vw * (30 / 1600));

        & span {
          font-size: calc(100vw * (18 / 1600));
        }
      }

      & .img {
        height: calc(100vw * (856 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (204 / 375));
    background: url(${BgLogo}) no-repeat center
      calc(100% - calc(100vw * (80 / 375))) / calc(100vw * (78 / 375))
      calc(100vw * (74 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .overviewSection {
      padding: 0 calc(100vw * (16 / 375));
      margin-bottom: calc(100vw * (80 / 375));

      & .img {
        width: calc(100vw * (1160 / 375));
        height: calc(100vw * (516 / 375));
      }
    }

    & .serviceSection {
      margin-bottom: calc(100vw * (80 / 375));
      padding: calc(100vw * (50 / 375)) 0;
      background-image: url(${BgOverviewMb});

      & h3 {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .serviceWrap {
        flex-direction: column;
        gap: calc(100vw * (30 / 375));
        margin-bottom: calc(100vw * (50 / 375));

        & .serviceList {
          justify-content: flex-start;
          gap: calc(100vw * (31 / 375));
          width: 100%;

          & .number {
            font-size: calc(100vw * (30 / 375));
          }

          & .textWrap {
            padding-top: calc(100vw * (6 / 375));
            font-size: calc(100vw * (16 / 375));

            & .title {
              margin-bottom: calc(100vw * (11 / 375));
              font-size: calc(100vw * (20 / 375));
            }

            & .subText {
              margin-bottom: calc(100vw * (10 / 375));
            }

            & .list {
              &:not(:last-child) {
                margin-bottom: calc(100vw * (10 / 375));
              }
            }
          }
        }
      }

      & .circleWrap {
        flex-wrap: wrap;
        & .circleList {
          width: calc(100vw * (170 / 375));
          height: calc(100vw * (170 / 375));
          font-size: calc(100vw * (14 / 375));
          border: calc(100vw * (2 / 375)) solid rgba(133, 140, 164, 0.5);

          & .title {
            margin-bottom: calc(100vw * (10 / 375));
            font-size: calc(100vw * (20 / 375));
          }
        }
      }
    }

    & .cooperationSection {
      & h3 {
        gap: calc(100vw * (23 / 375));
        margin-bottom: calc(100vw * (30 / 375));

        & span {
          font-size: calc(100vw * (16 / 375));
        }
      }

      & .img {
        width: calc(100vw * (1370 / 375));
        height: calc(100vw * (723 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
