import Styles from "./styles";
import { usePopup } from "hooks";

// img
import IconClose from "resources/image/icon/icon_close02.svg";
import IconCheck from "resources/image/icon/icon_check.svg";
import { getCookie } from "service/connector";
import { useEffect } from "react";

function MainModal({ modalClose }) {
  const popupInfo = usePopup({ popup: getCookie('popup') !== "hide" });
  const data = popupInfo.getPopup?.data?.data[0];

  useEffect(()=>{
    setTimeout(()=>{
      if(getCookie('popup') == "hide"){
        modalClose();
      }
    },250)

    let targetData = popupInfo.getPopup?.data?.data;
    if(targetData && !targetData[0]){
      modalClose();
    }

  },[popupInfo.getPopup?.data])

  if(!data)return <></>

  return (data?.id && 
    <Styles.Container>
      <div className="modalInner">
        <button type="button" className="closeButton" onClick={modalClose}>
          <img src={IconClose} alt="IconClose" />
        </button>

        <div className="img">
          <button onClick={() => window.location.replace(data?.url)}>
            <img src={data?.image} alt="ImgDummy" />
          </button>
        </div>

        <button
          type="button"
          className="todayCloseButton"
          onClick={() => {
            popupInfo.hidePopup();
            modalClose();
          }}
        >
          <span className="icon">
            <img src={IconCheck} alt="IconCheck" />
          </span>
          하루동안 보지않기
        </button>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { MainModal };

export default MainModal;
