import { useUpload } from "hooks";
import Styles from "./styles";
import { useState } from "react";

function UploadFileList({ id, file, setFile }) {
  const uploadInfo = useUpload();
  const fileState = typeof file?.[id]?.original_file_name === "undefined"

  return (
    <Styles.Container>
      <p className={"fileName" + (fileState ? " on" : "")}>
        {fileState ? "첨부파일(최대 10mb)" : file?.[id]?.original_file_name}
      </p>

      {!fileState && (
        <button
          type="button"
          className="deleteButton"
          onClick={() => {
            setFile(v => ({ ...v, [id]: "" }));
          }}
        >
          삭제
        </button>
      )}

      <input
        type="file"
        id={id}
        onChange={async (e) => {
          if (e.target.files[0].size <= 10485760) {
            const result = await uploadInfo.postFile(e.target.files[0])
            setFile(v => ({ ...v, [id]: result }));
          }
        }}
      />
      <label htmlFor={id} className="uploadButton">
        첨부파일 등록
      </label>
    </Styles.Container>
  );
}

export default UploadFileList;
