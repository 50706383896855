import { useAtom } from "jotai";
import { modalAtom } from "store/atoms";

export default function useModals() {
  const [defaultModal, setDefaultModal] = useAtom(modalAtom.defaultModalAtom);
  const [mainModal, setMainModal] = useAtom(modalAtom.mainModalAtom);

  const clearModals = () => {
    setDefaultModal({ show: false });
  };

  const clearSubModals = (callback) => {
    setDefaultModal((e) => !e);
    setTimeout(() => {
      callback();
    }, [0]);
  };

  return {
    defaultModal,
    setDefaultModal,
    mainModal,
    setMainModal,
  };
}
