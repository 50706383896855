import styled from "styled-components";

// img
import BgLogo from "resources/image/bg_logo.svg";

const Container = styled.main`
  padding: 50px 0 301px;
  background: url(${BgLogo}) no-repeat center calc(100% - 130px) / 96px 91px;

  & .bannerSection {
    margin-bottom: 50px;
  }

  & .adviceSection {
    & h3 {
      margin-bottom: 30px;
    }

    & .description {
      margin-bottom: 50px;
      font-size: 18px;
      line-height: 1.44;
    }

    & .listWrap {
      display: flex;
      gap: 30px;
      margin-bottom: 100px;

      & .list {
        flex: 1 0;

        & .img {
          height: 340px;
          margin-bottom: 30px;
          border-radius: 10px;
          overflow: hidden;
        }

        & .number {
          margin-bottom: 10px;
          font-size: 42px;
          font-weight: 200;
          color: var(--gray02);
        }

        & .text {
          font-weight: 600;
          line-height: 1.4;
        }
      }
    }

    & .imgWrap {
      & .title {
        margin-bottom: 30px;
        font-size: 26px;
        font-weight: bold;
      }

      & .img {
        height: 620px;
      }
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (301 / 1600));
    background: url(${BgLogo}) no-repeat center
      calc(100% - calc(100vw * (130 / 1600))) / calc(100vw * (96 / 1600))
      calc(100vw * (91 / 1600));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .adviceSection {
      & h3 {
        margin-bottom: calc(100vw * (30 / 1600));
      }

      & .description {
        margin-bottom: calc(100vw * (50 / 1600));
        font-size: calc(100vw * (18 / 1600));
      }

      & .listWrap {
        gap: calc(100vw * (30 / 1600));
        margin-bottom: calc(100vw * (100 / 1600));

        & .list {
          & .img {
            height: calc(100vw * (340 / 1600));
            margin-bottom: calc(100vw * (30 / 1600));
            border-radius: calc(100vw * (10 / 1600));
          }

          & .number {
            margin-bottom: calc(100vw * (10 / 1600));
            font-size: calc(100vw * (42 / 1600));
          }
        }
      }

      & .imgWrap {
        & .title {
          margin-bottom: calc(100vw * (30 / 1600));
          font-size: calc(100vw * (26 / 1600));
        }

        & .img {
          height: calc(100vw * (620 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 375)) 0 calc(100vw * (204 / 375));
    background: url(${BgLogo}) no-repeat center
      calc(100% - calc(100vw * (80 / 375))) / calc(100vw * (78 / 375))
      calc(100vw * (74 / 375));

    & .bannerSection {
      margin-bottom: calc(100vw * (50 / 375));
    }

    & .adviceSection {
      & h3 {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .description {
        margin-bottom: calc(100vw * (30 / 375));
        font-size: calc(100vw * (16 / 375));
        word-break: keep-all;
      }

      & .listWrap {
        flex-direction: column;
        gap: calc(100vw * (50 / 375));
        margin-bottom: calc(100vw * (80 / 375));

        & .list {
          & .img {
            height: calc(100vw * (243 / 375));
            margin-bottom: calc(100vw * (20 / 375));
            border-radius: calc(100vw * (10 / 375));
          }

          & .number {
            margin-bottom: calc(100vw * (10 / 375));
            font-size: calc(100vw * (30 / 375));
          }
        }
      }

      & .imgWrap {
        & .title {
          margin-bottom: calc(100vw * (20 / 375));
          font-size: calc(100vw * (20 / 375));
        }

        & .img {
          width: calc(100vw * (1500 / 375));
          height: calc(100vw * (620 / 375));
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
